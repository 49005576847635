import {
    Button, Flex,
    Modal,
    ModalBody,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid, Text,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {
    exportHilanPayrollFile,
    exportMalamPayrollFile,
    generateShiklulitPayrollFile
} from "../../../../functions/generatePayrollFile";
import {
    exportElectraTransfersFeesFile,
    exportHalperinTransfersFeesFile,
    exportHomeCenterTransfersFeesFile
} from "../../../../functions/generateReports";
import ReportsApi from "../../../../api/reports";


import { useToast } from '@chakra-ui/react';
import {DocumentTypesEnum} from "../../../admin/reports/components/DocumentTypeEnum";
import MockApi, {getCurrentMonthAndYear} from "../../../../api/mockApi";
import {downloadPdf} from "../../../../functions/file.utils";

export default function ReportDetailsModal(props) {

    let { isOpen, onClose, report, date, data } = props;
    const [totalTransfers, setTotalTransfers] = React.useState(0)
    const [totalEmployeesTransferFees, setTotalEmployeesTransferFees] = React.useState(0)
    const [totalCompanyServiceSubscriptionFee, setTotalCompanyServiceSubscriptionFee] = React.useState(0)
    const [totalSettlementAmount, setTotalSettlementAmount] = React.useState(0)
    const [reportCompanyId, setReportCompanyId] = React.useState("")
    const [reportStatus, setReportStatus] = React.useState("")
    const [loading, setLoading] = useState(false);
    const [selectedDocumentType, setSelectedDocumentType] = React.useState(null);

    const toast = useToast();

    useEffect(() => {
        if(isOpen && report) {
            setTotalTransfers(report.transfersAmount)
            setTotalEmployeesTransferFees(report.usersTransfersFee)
            setTotalCompanyServiceSubscriptionFee(report.totalCompanyServiceSubscriptionFee)
            setTotalSettlementAmount(report.settlementTotalAmount)
            setReportCompanyId(report.companyId)
        }
        if(!isOpen) {
            setTotalTransfers(0)
            setTotalEmployeesTransferFees(0)
            setTotalCompanyServiceSubscriptionFee(0)
            setTotalSettlementAmount(0)
            setReportCompanyId("")
        }
    }, [isOpen]);



    const handlePayrollExportClick = () => {
        ReportsApi.downloadReport(1, date, reportCompanyId).then(res => {
            if(MockApi.IsActive){
                const { currentMonth, currentYear } = getCurrentMonthAndYear();
                date = `${currentMonth}/${currentYear}`
            }
            generateShiklulitPayrollFile(date, res.data)
        }).catch(e => {
        })
    }

    const handleMalamExportClick = () => {
        ReportsApi.downloadReport(1, date, reportCompanyId).then(res => {
            exportMalamPayrollFile(date, res.data)
        }).catch(e => {
        })
    }


    const handleHilanExportClick = () => {
        ReportsApi.downloadReport(1, date, reportCompanyId).then(res => {
            exportHilanPayrollFile(date, res.data)
        }).catch(e => {
        })
    }

    const handleDownloadTransfersListWithFeeClick = () => {
        ReportsApi.getTransfersListWithFee(date, reportCompanyId)
            .then(res => {
                const jsonData = res.data;
                if(reportCompanyId == "11") {
                    exportHalperinTransfersFeesFile(jsonData, toast);
                } else if(reportCompanyId == "14") {
                    exportElectraTransfersFeesFile(jsonData, toast);
                } else if(reportCompanyId == "15") {
                    exportHomeCenterTransfersFeesFile(jsonData, toast);
                }
            })
            .catch(e => {
                console.log("error is: ", e);
                console.error('Error fetching data: ', e);
                toast({
                    title: `שגיאה בהורדת המסמך`,
                    position: 'top',
                    status: 'error',
                    isClosable: false,
                });
            });
    };



    const handleDownloadEmployeeTransfersFeeInvoice = () => {
        ReportsApi.downloadDocumentPdf(DocumentTypesEnum.EMPLOYEE_TRANSFERS_FEE_INVOICE, report.id)
            .then(res => {
            downloadPdf(res, "download.pdf");

            toast({
                position: "top",
                title: 'הורדת המסמך הצליחה',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

        }).catch(e => {
            toast({

                title: `שגיאה בהורדת המסמך`,

                position: 'top',
                status: 'error',
                isClosable: false,
            })

        })
    }

const handleDownloadEmployeeAdvanceTransferRequest = () => {
    ReportsApi.downloadDocumentPdf(DocumentTypesEnum.EMPLOYEE_ADVANCE_TRANSFER_REQUEST, report.id)
        .then(res => {
            downloadPdf(res, "download.pdf");

            toast({
                position: "top",
                title: 'הורדת המסמך הצליחה',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

        }).catch(e => {
        toast({
            title: `שגיאה בהורדת הקובץ`,
            position: 'top',
            status: 'error',
            isClosable: false,
        })

    })
}

    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               isCentered
               closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>סיכום חודשי</ModalHeader>
                <ModalBody>


                    <SimpleGrid
                        marginTop='00px'
                        columns={{sm: 2}}
                        gap='10px'
                        mb='0px'>


                        <Text marginTop='15px'>סה״כ העברות</Text>
                        <Text marginTop='15px'>₪{totalTransfers}</Text>

                        <Text>סה״כ עמלות עובדים (העברות)</Text>
                        <Text>₪{totalEmployeesTransferFees}</Text>

                        { totalCompanyServiceSubscriptionFee > 0 &&
                            <Text>סה״כ עמלת מעסיק (דמי שימוש), כולל מע״מ</Text>
                        }

                        { totalCompanyServiceSubscriptionFee > 0 &&
                            <Text >₪{totalCompanyServiceSubscriptionFee}</Text>
                        }


                    </SimpleGrid>

                    <SimpleGrid
                        marginTop='30px'
                        columns={{sm: 2}}
                        gap='10px'
                        mb='0px'>

                        <Text fontWeight='bold' fontSize='20px'>סה״כ</Text>
                        <Text fontWeight='bold' fontSize='20px'>₪{totalSettlementAmount}</Text>


                    </SimpleGrid>

                    <Text marginTop='30px' fontSize='18px' fontWeight='bold'>דו״ח ניכויים לתוכנת שכר</Text>

                    <SimpleGrid
                        marginTop='20px'
                        columns={{sm: 3}}
                        gap='10px'
                        mb='0px'>

                        <Button backgroundColor='green.200' maxW={'100px'} onClick={handlePayrollExportClick}>שקלולית</Button>
                        <Button backgroundColor='green.200' maxW={'100px'} onClick={handleMalamExportClick}>מלם</Button>
                        <Button backgroundColor='green.200' maxW={'100px'} onClick={handleHilanExportClick}>חילן</Button>
                        {reportCompanyId == "11" && <Button backgroundColor='green.200' maxW={'100px'} onClick={handleDownloadTransfersListWithFeeClick}>דו״ח עמלות</Button>}
                        {reportCompanyId == "14" && <Button backgroundColor='green.200' maxW={'100px'} onClick={handleDownloadTransfersListWithFeeClick}>מפורט</Button>}
                        {reportCompanyId == "15" && <Button backgroundColor='green.200' maxW={'100px'} onClick={handleDownloadTransfersListWithFeeClick}>דו״ח עמלות</Button>}
                    </SimpleGrid>

                        {/*<Text marginTop='30px' fontSize='18px' fontWeight='bold'>הורדת קבצים</Text>*/}

                    {/* <Stack spacing={4} style={{marginTop: "30px"}}>*/}
                    {/*    <Button*/}
                    {/*        width="auto"*/}
                    {/*        whiteSpace="normal"*/}
                    {/*        wordWrap="break-word"*/}
                    {/*        backgroundColor="green.200"*/}
                    {/*        onClick={handleDownloadEmployeeTransfersFeeInvoice}>*/}
                    {/*        חשבונית מס - עמלות משיכה עובדים*/}
                    {/*    </Button>*/}

                    {/*    <Button*/}
                    {/*        width="auto"*/}
                    {/*        whiteSpace="normal"*/}
                    {/*        wordWrap="break-word"*/}
                    {/*        backgroundColor="green.200"*/}
                    {/*        onClick={handleDownloadEmployeeAdvanceTransferRequest}>*/}
                    {/*        דרישת תשלום - העברת מקדמות עובדים*/}
                    {/*    </Button>*/}
                    {/*</Stack>*/}


                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>סגור</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
