import MockApi from "./mockApi";

export const UserStatus = {
    InActive: 100,
    Active: 200,
    Blocked: 300,
    Left: 400,
}
export class Users {
    // this should be in sync with backend status

    static statusText = (status) => {
        switch (status) {
            case UserStatus.InActive:
                return "לא פעיל"
            case UserStatus.Active:
                return "פעיל"
            case UserStatus.Blocked:
                return "חסום"
            case UserStatus.Left:
                return "עזב"
        }
    }
}
export default class User {
    static GetInfo = () => {
        if(MockApi.IsActive) {
            return MockApi.GetUserInfo();
        }
        let userInfo = JSON.parse(localStorage.getItem("user"));
        if(userInfo === null) {
            return null;
        }
        return userInfo.info
    };
}
