import {Input, InputGroup, InputLeftElement} from "@chakra-ui/react";
import {PhoneIcon} from "@chakra-ui/icons";
import React from "react";

const PhoneNumberInputs = (props)=>{
    return(
        <InputGroup>
            <InputLeftElement pointerEvents='none'>
                <PhoneIcon color='gray.300' />
            </InputLeftElement>
            <Input
                type='tel'
                placeholder='Phone number'
                value={props.recipientsByPhoneNumbers}
                onChange={props.handleInputChange}
            />
        </InputGroup>
        );
}

export default PhoneNumberInputs;