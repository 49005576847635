import MockApi from "./mockApi";
import {API} from "./env";
import {axiosInstance} from "./httpClient";

export function setAccessToken(accessToken) {
  localStorage.setItem("accessToken", accessToken);
}

class AuthApi {
  
  static sessionToken = '';

  static Auth = (aPhoneNumber) => {
      if(MockApi.IsActive) {
          return MockApi.Auth();
      }
    var data = JSON.stringify({
        "phoneNumber": aPhoneNumber
      });
      
    const config = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data : data
      };
      
    return axiosInstance.post(`${API}/auth/admin`, data, config);
  };

  static Login = (otpToken) => {

      if(MockApi.IsActive) {
          return MockApi.Login();
      }

      this.sessionToken = localStorage.getItem("tempSessionToken")

    var data = JSON.stringify({
        "otp": otpToken,
        "sessionToken": this.sessionToken
      });
      
    var config = {
        method: 'post',
        url: 'http://localhost:3000/auth/otp',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
    return axiosInstance.post(`${API}/auth/otp`, data, config);
  };

  static GetUserInfo = (accessToken) => {
      if(MockApi.IsActive) {
          return MockApi.GetUserInfo();
      }

    var config = {
        method: 'get',
        url: 'http://localhost:3000/user',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      };


    return axiosInstance.get(`${API}/user`, config);
  };
}

export default AuthApi;