import {axiosInstance} from "./httpClient";
import {API} from "./env";

export default class MessageApi {
    static sendMessage(message) {
        const messageJson = JSON.stringify(message);
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;

        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };
        return axiosInstance.post(`${API}/message/send`, messageJson, config);
    }

    static getWhatsappTemplates() {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;

        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };
        return axiosInstance.get(`${API}/whatsapp/templates`, config);
    }
}