import Axios from "axios";
import axios from "axios";
import {handleLogout} from "../functions/logout";

export const axiosInstance = axios.create()
axiosInstance.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
        const isAuth = window.location.href.includes("auth")
        if(!isAuth) {
            if(error.response.status === 401) {
                handleLogout()
            }
        }
        return Promise.reject(error);
    }
);