import {Select} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {getCompanies} from "./companiesCache";

export default function CompanyDropdownSelection(props){


    const [companiesIdCache, setCompaniesIdCache] = useState({});
    const [companiesOptions, setCompaniesOptions] = useState([]);

    useEffect(() => {
        (async () => {
            const cache = await getCompanies();
            setCompaniesIdCache(cache);
        })();
    }, []);

    useEffect(() => {
        setCompaniesOptions([
            ...Object.keys(companiesIdCache).map(key => ({
                id: key,
                name: companiesIdCache[key]
            }))
        ]);
    }, [companiesIdCache]);

    const handleCompanySelection = (event) => {
        const value = event.target.value;
        props.selectedCompany(value);
    }

    return(
    <Select width='190px' pr='12px' marginRight='50px' onChange={handleCompanySelection} defaultValue={'0'}>
        <option value='0' disabled hidden>
            בחר חברה
        </option>
        {props.showAll ? (
            <option id= "הכל">
                הכל
            </option>
            ) : null}
        {companiesOptions.map((option) => (
            <option key={option.id} value={option.name}>
                {option.name}
            </option>
        ))}
    </Select>
    )
}
