import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    SimpleGrid,
    useToast,
    Text,
    Flex,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Textarea,
    GridItem,
    Select,
} from "@chakra-ui/react";

import React, {useEffect} from "react";
import {
    exportMalamPayrollFile,
    exportRawXlsxFile, generateShiklulitPayrollFile,
} from "../../../../functions/generatePayrollFile";
import ReportsApi, {Reports, ReportState} from "../../../../api/reports";
import {DocumentTypesEnum} from "./DocumentTypeEnum";
import AdminSumitDocumentsFinderModal from "./AdminSumitDocumentsFinderModal";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {
    exportHalperinTransfersFeesFile,
    exportHomeCenterTransfersFeesFile,
} from "../../../../functions/generateReports";

export default function AdminReportDetailsModal(props) {

    let { isOpen, onClose, report } = props;
    const toast = useToast()

    const [totalTransfers, setTotalTransfers] = React.useState(0)
    const [totalEmployeesTransferFees, setTotalEmployeesTransferFees] = React.useState(0)
    const [totalCompanyServiceSubscriptionFee, setTotalCompanyServiceSubscriptionFee] = React.useState(0)
    const [totalSettlementAmount, setTotalSettlementAmount] = React.useState(0)
    const [reportState, setReportState] = React.useState("0")
    const [payPeriod, setPayPeriod] = React.useState("")
    const [companyId, setCompanyId] = React.useState("")
    const [companyName, setCompanyName] = React.useState("")
    const [reportId, setReportId] = React.useState("")
    const [notes, setNotes] = React.useState("")
    const [selectedDocumentType, setSelectedDocumentType] = React.useState(null);
    const [documents, setDocuments] = React.useState([]);
    const [sumitDocumentModalOpen, setSumitDocumentModalOpen] = React.useState(false);

    useEffect(() => {
        if(isOpen && report) {
            setDocuments([]);
            ReportsApi.getReportFullDetails(report.id)
                .then(response => {

                    const reportJson = response.data;
                    setReportId(reportJson.id)
                    setTotalTransfers(reportJson.transfersAmount)
                    setTotalEmployeesTransferFees(reportJson.usersTransfersFee)
                    setTotalCompanyServiceSubscriptionFee(reportJson.totalCompanyServiceSubscriptionFee)
                    setTotalSettlementAmount(reportJson.settlementTotalAmount)
                    setPayPeriod(reportJson.payPeriod)
                    setCompanyId(reportJson.companyId)
                    setCompanyName(reportJson.companyName)
                    setNotes(reportJson.notes)
                    setReportState(reportJson.state)
                    setDocuments(reportJson.documents);

                } ).catch(e => {
               console.log(e)
            });
        }
        if(!isOpen){
            setDocuments([]);
            setReportId("")
            setTotalTransfers(0)
            setTotalEmployeesTransferFees(0)
            setTotalCompanyServiceSubscriptionFee(0)
            setTotalSettlementAmount(0)
            setPayPeriod("")
            setCompanyId("")
            setCompanyName("")
            setNotes("")
            setReportState("0")
        }
    }, [isOpen]);


    const handleAdvancePaymentRequestClick = () => {
        setSelectedDocumentType(DocumentTypesEnum.EMPLOYEE_ADVANCE_TRANSFER_REQUEST);
    }

    const handleTransferFeeInvoiceClick = () => {
        setSelectedDocumentType(DocumentTypesEnum.EMPLOYEE_TRANSFERS_FEE_INVOICE);
    }

    useEffect(() => {
        if(selectedDocumentType){
            createDocument();
        }
    },[selectedDocumentType])



    const errorMapping = {
        1: "לא נמצא מספר מסמך בתגובה מסמיט.",
        2: "המסמך לא נשמר במסד הנתונים."
    }
    const createDocument = async () => {
        const documentDto = {
            isDraft: true,
            selectedDocumentType: selectedDocumentType,
            reportId: report.id
        };
        ReportsApi.createDocument(documentDto).then(res => {
            if (res.data.success) {
                toast({
                    title: "המסמך נוצר בהצלחה",
                    position: 'top',
                    status: 'success',
                    isClosable: false,
                });
            } else {
                const errorCode = res.data.errorCode;
                toast({
                    title: errorMapping[errorCode] || "שגיאה ביצירת המסמך",
                    position: 'top',
                    status: 'error',
                    isClosable: false,
                });
            }
            onClose();
        }).catch(e => {
            toast({
                title: "שגיאה ביצירת המסמך",
                position: 'top',
                status: 'error',
                isClosable: false,
            });
        });
    }

    const handleOnUpdate = () => {
        ReportsApi.updateReport(reportId, notes, reportState).then(res => {
            toast({
                title: "עודכן בהצלחה",
                position: 'top',
                status: 'success',
                isClosable: false,
            });
            onClose();
        }).catch(e => {
            toast({
                title: "שגיאה",
                position: 'top',
                status: 'error',
                isClosable: false,
            });
        });
    }

    const handleStatusChange = (event) => {
        const value = event.target.value;
        setReportState(value)
    }


    const handlePayrollExportClick = () => {
        ReportsApi.downloadReport(1, payPeriod, companyId).then(res => {
            exportRawXlsxFile(res.data)
        }).catch(e => { })
    }

    const handleShiklulitExportClick = () => {
        ReportsApi.downloadReport(1, payPeriod, companyId).then(res => {
            generateShiklulitPayrollFile(payPeriod, res.data)
        }).catch(e => {
        })
    }

    const handleDownloadTransfersListWithFeeHomeCenterClick = () => {
        ReportsApi.getTransfersListWithFee(payPeriod, companyId)
            .then(res => {
                const jsonData = res.data;
                exportHomeCenterTransfersFeesFile(jsonData, toast);
            })
            .catch(e => {
                toast({
                    title: `שגיאה בהורדת המסמך`,
                    position: 'top',
                    status: 'error',
                    isClosable: false,
                });
            });
    };

    const handleDownloadTransfersListWithFeeHalperinClick = () => {
        ReportsApi.getTransfersListWithFee(payPeriod, companyId)
            .then(res => {
                const jsonData = res.data;
                exportHalperinTransfersFeesFile(jsonData, toast);
            })
            .catch(e => {
                toast({
                    title: `שגיאה בהורדת המסמך`,
                    position: 'top',
                    status: 'error',
                    isClosable: false,
                });
            });
    };
    const handleMalamExportClick = () => {
        ReportsApi.downloadReport(1, payPeriod, companyId).then(res => {
            exportMalamPayrollFile(payPeriod, res.data)
        }).catch(e => {
        })
    }

    const handleApproveReportClick = () => {
        ReportsApi.approveReport(reportId).then(res => {
                toast({
                    title: `הד״וח עודכן בהצלחה`,
                    position: 'top',
                    status: 'success',
                    isClosable: false,
                })
        }).catch(e => {
                toast({
                    title: `שגיאה בעדכון הדו״ח`,
                    position: 'top',
                    status: 'error',
                    isClosable: false,
                })
        })
    }

    const handleShowSumitDocumentModalOpen = () => {
        setSumitDocumentModalOpen(true);
    }

    const handleSumitDocumentModalClose = (selectedDocumentId, selectedDocumentType) => {
        ReportsApi.addDocumentToReport(reportId, selectedDocumentId, selectedDocumentType).then(res => {
            toast({
                title: "המסמך נוסף בהצלחה",
                position: 'top',
                status: 'success',
                isClosable: false,
            });
            setSumitDocumentModalOpen(false)
        }).catch(e => {
           console.log(e)
        });
    }

    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               isCentered
               closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent maxW="500px">
                <ModalHeader>
                    פרטי דו״ח:
                    <Flex justifyContent='space-between' alignItems='center' p={2}>
                        <Text fontSize='20px'>{companyName}</Text>
                        <Text fontSize='20px'>{payPeriod}</Text>
                    </Flex>
                </ModalHeader>
                <ModalBody>


                    <SimpleGrid
                        marginTop='00px'
                        columns={{sm: 2}}
                        gap='10px'
                        mb='0px'>

                        <Menu>
                            <MenuButton backgroundColor={'green.200'} as={Button} rightIcon={<ChevronDownIcon />}>
                                הורד דו״ח ניכויים
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={handlePayrollExportClick} >Payro</MenuItem>
                                <MenuItem onClick={handleDownloadTransfersListWithFeeHalperinClick}>(הלפרין) Payro מפורט</MenuItem>
                                <MenuItem onClick={handleDownloadTransfersListWithFeeHomeCenterClick}>(הום סנטר) Payro מפורט</MenuItem>
                                <MenuItem onClick={handleShiklulitExportClick}>שקלולית</MenuItem>
                                <MenuItem onClick={handleMalamExportClick}>מלם</MenuItem>
                            </MenuList>
                        </Menu>

                        <Button backgroundColor='blue.300' onClick={handleApproveReportClick} >אשר דו״ח</Button>

                        <Button backgroundColor='green.200' onClick={handleAdvancePaymentRequestClick} >דרישת תשלום מקדמות</Button>
                        <Button backgroundColor='green.200' onClick={handleTransferFeeInvoiceClick} >חשבונית עמלות העברה</Button>

                        <GridItem colSpan={2}>
                            <Textarea
                                defaultValue={notes !== "" ? notes : undefined}
                                placeholder='הוסף הערה'
                                onChange={(event) => {
                                    setNotes(event.target.value);
                                }}
                                rows={5}
                                cols={100}
                            />
                        </GridItem>

                        <Text marginTop='15px'>סה״כ העברות</Text>
                        <Text marginTop='15px'>₪{totalTransfers}</Text>

                        <Text>סה״כ עמלות עובדים (העברות)</Text>
                        <Text>₪{totalEmployeesTransferFees}</Text>

                        <Text>סה״כ עמלת מעסיק (דמי שימוש)</Text>
                        <Text >₪{totalCompanyServiceSubscriptionFee}</Text>


                        <Select defaultValue={reportState} key={reportState} pr='11px' placeholder='בחר סטטוס' mt={4}
                                onChange={(event) => {
                                    setReportState(event.target.value);
                                }}>
                            {Object.entries(ReportState).map(([key, value]) => (
                                <option key={value} value={value}>{Reports.stateText(value)}</option>
                            ))}
                        </Select>
                    </SimpleGrid>

                    <SimpleGrid
                        marginTop='30px'
                        columns={{sm: 2}}
                        gap='10px'
                        mb='0px'>

                        <Text fontWeight='bold' fontSize='20px'>סה״כ</Text>
                        <Text fontWeight='bold' fontSize='20px'>₪{totalSettlementAmount}</Text>

                    </SimpleGrid>

                    <br/>

                    <SimpleGrid
                        marginTop='30px'
                        columns={{sm: 2}}
                        gap='10px'
                        mb='10px'>

                        <Text fontSize={"xl"} mb={"10px"}>מסמכים מקושרים</Text>
                        <Button backgroundColor='green.100' onClick={handleShowSumitDocumentModalOpen} >הוספת מסמך</Button>

                    </SimpleGrid>


                    <AdminSumitDocumentsFinderModal onClose={() => setSumitDocumentModalOpen(false)} isOpen={sumitDocumentModalOpen} onSelect={(selectedDocumentId, selectedDocumentType) =>
                        handleSumitDocumentModalClose(selectedDocumentId, selectedDocumentType)
                    }/>

                    <Table variant="simple">
                        <Thead backgroundColor="green.200">
                            <Tr>
                                <Th>מזהה</Th>
                                <Th>שם מסמך</Th>
                                <Th>ערך</Th>
                            </Tr>
                        </Thead>
                        <Tbody backgroundColor="green.100">
                            {documents && documents.map((doc) => (
                                <Tr key={doc.docId}>
                                    <Td>{doc.docId}</Td>
                                    <Td>{doc.docName}</Td>
                                    <Td>{doc.docAmount === 0 ? doc.docId : `₪${doc.docAmount}`}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>סגור</Button>
                    <Button onClick={handleOnUpdate}>עדכן</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
