import MockApi from "./mockApi";
import {axiosInstance} from "./httpClient";
import {API} from "./env";

export default class FormsApi {

    static create = (formData) => {

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
            'title': formData['title'],
            'content': formData['content'],
            'location': formData['location'],
            'contentType': formData['contentType'],
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/forms`, JSON.stringify(body), config);
    };

    static update = (formData) => {

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
            'formId': formData['id'],
            'status': formData['status'],
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.put(`${API}/forms`, JSON.stringify(body), config);
    };

    static getAll = () => {

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.get(`${API}/forms`, config);
    };


    static downloadFormReport = (formId) => {

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.get(`${API}/forms/report/`+formId, config);
    };
}

