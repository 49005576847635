import { Box, Switch, FormControl, FormLabel } from "@chakra-ui/react";

function Switcher({ isChecked, handleToggle, option1, option2 }) {
    return (
        <FormControl display="flex" alignItems="center">
            <Switch marginLeft='16px' id="option-switch" isChecked={isChecked} onChange={handleToggle} />
            <FormLabel htmlFor="option-switch" mb="0">
                {isChecked ? option1 : option2}
            </FormLabel>
        </FormControl>
    );
}

export default Switcher;
