/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";

// Custom components
import IntegrationCard from "views/admin/integrations/components/IntegrationCard";

// Assets
import TimeWatchImage from "assets/img/integrations/timewatch.png";
import realTimeImage from "assets/img/integrations/realTime.png";
import ShiftOrganizerImage from "assets/img/integrations/shiftorganizer.png";
import OketzImage from "assets/img/integrations/oketz.png";
import HilanImage from "assets/img/integrations/hilan.png";
import PrestoImage from "assets/img/integrations/presto.png";
import isufitImage from "assets/img/integrations/isufit.jpg";
import tizmunImage from "assets/img/integrations/tizmun.jpeg";
import synelImage from "assets/img/integrations/synel.png";

export default function Integrations() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        mb='20px'
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
          <Flex direction='column'>
              <SimpleGrid columns={{ base: 1, md: 3 }} gap='20px'>
              <IntegrationCard
                name='TimeWatch'
                subtitle='Time and Attendance'
                image={TimeWatchImage}
                status='1'
                type='3'
                download='#'
              />
              <IntegrationCard
                  name='Presto (YCD)'
                  subtitle='Time and Attendance'
                  image={PrestoImage}
                  status='0'
                  type='2'
                  download='#'
              />
                  <IntegrationCard
                name='ShiftOrganizer'
                subtitle='Time and Attendance'
                image={ShiftOrganizerImage}
                status='0'
                type='1'
                download='#'
              />
              <IntegrationCard
                  name='חילן'
                  subtitle='Payroll, Time and Attendance'
                  image={HilanImage}
                  currentbid=''
                  type='1'
                  status='2'
              />
              <IntegrationCard
                name='עוקץ'
                subtitle='Payroll, Time and Attendance'
                image={OketzImage}
                currentbid=''
                type='1'
                status='2'
              />
                  <IntegrationCard
                name='זמן אמת'
                subtitle='Time and Attendance'
                image={realTimeImage}
                currentbid=''
                type='1'
                status='2'
              />
                  <IntegrationCard
                name='רענדטק-איסופית'
                subtitle='Time and Attendance'
                image={isufitImage}
                currentbid=''
                type='1'
                status='2'
              />
                  <IntegrationCard
                name='תזמון'
                subtitle='Time and Attendance'
                image={tizmunImage}
                currentbid=''
                type='1'
                status='2'
              />
                  <IntegrationCard
                name='סינל'
                subtitle='Time and Attendance'
                image={synelImage}
                currentbid=''
                type='1'
                status='2'
              />
            </SimpleGrid>
          </Flex>
        </Flex>
      </Grid>
      {/* Delete Product */}
    </Box>
  );
}
