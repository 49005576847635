export const translations = {
    uploadStep: {
        title: "טעינת קובץ עובדים",
        manifestTitle: "מידע שנדרש בקובץ",
        manifestDescription: "(ניתן להתאים את העמודות המתאימות בשלבים הבאים)",
        dropzone: {
            title: "העלה קובץ מסוג xlsx בלבד",
            errorToastDescription: "טעינה נכשלה",
            activeDropzoneTitle: "גרור את הקובץ לכאן",
            buttonTitle: "בחר קובץ",
            loadingTitle: "מעבד נתונים, אנא המתן",
        },
        selectSheet: {
            title: "בחר את הדף הנכון",
            nextButtonTitle: "הבא",
        },
    },
    selectHeaderStep: {
        title: "בחר כותרת (המכילה את שמות העמודות)",
        nextButtonTitle: "הבא",
    },
    matchColumnsStep: {
        title: "התאם עמודות",
        nextButtonTitle: "הבא",
        userTableTitle: "הנתונים שלך",
        templateTitle: "איך שאנחנו רואים אותם",
        selectPlaceholder: "בחר עמודה",
        ignoredColumnText: "עמודה לא רלוונטית",
        subSelectPlaceholder: "בחר",
        matchDropdownTitle: "התאמה",
        unmatched: "אין התאמה",
        duplicateColumnWarningTitle: "עמודה אחרת נבחרה",
        duplicateColumnWarningDescription: "אין אפשרות לבחור אותה עמודה פעמיים",
    },
    validationStep: {
        title: "נתונים סופיים",
        nextButtonTitle: "אישור",
        noRowsMessage: "לא נמצאו נתונים",
        noRowsMessageWhenFiltered: "אין נתונים עם שגיאות",
        discardButtonTitle: "מחק שורות נבחרות",
        filterSwitchTitle: "הראה רק שורות עם שגיאות",
    },
    alerts: {
        confirmClose: {
            headerTitle: "יציאה",
            bodyText: "האם אתה בטוח? כל הנתונים לא ישמרו במערכת",
            cancelButtonTitle: "בטל",
            exitButtonTitle: "יציאה",
        },
        submitIncomplete: {
            headerTitle: "נמצאו שגיאות",
            bodyText: ".עדיין יש כמה שורות המכילות שגיאות. השורות עם שגיאות ימחקו בעת השליחה.",
            bodyTextSubmitForbidden: "עדיין יש כמה שורות המכילות שגיאות.",
            cancelButtonTitle: "ביטול",
            finishButtonTitle: "שלח",
        },
        unmatchedRequiredFields: {
            headerTitle: "לא לכל העמודות נמצאו התאמות",
            bodyText: "ישנן עמודות נדרשות שלא נמצא להן התאמה בנתונים. האם אתה רוצה להמשיך?",
            listTitle: "עמודות ללא התאמה:",
            cancelButtonTitle: "ביטול",
            continueButtonTitle: "המשך",
        },
        toast: {
            error: "שגיאה",
        },
    },
};