import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    FormControl,
    FormLabel,
    Input,
    ModalFooter,
    SimpleGrid,
    Select,
} from "@chakra-ui/react";
import EmployeesApi from "../../../../api/employees"
import {useEffect, useMemo, useState} from "react";
import { citiesB } from "../../../../data/citiesData";

export default function EditEmployeeModal(props) {

    let { isOpen, onClose, employee, callBack,
        ...rest } = props;

    const citiesData = useMemo(() => citiesB, [citiesB]);

    const [error, setError] = useState(undefined);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [govId, setGovId] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [dob, setDob] = useState("");
    const [gender, setGender] = useState("");
    const [cityId, setCityId] = useState("");
    const [internalId, setInternalId] = useState("");
    const [jobDepartment, setJobDepartment] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [bankId, setBankId] = useState("");
    const [isActive, setIsActive] = useState("");
    const [accountId, setAccountId] = useState("");
    const [branchId, setBranchId] = useState("");
    const [status, setStatus] = useState("");

    useEffect(() => {
            if(isOpen && employee) {
                if(employee.firstName) {
                    setFirstName(employee.firstName);
                }
                if(employee.lastName) {
                    setLastName(employee.lastName);
                }
                if(employee.govId) {
                    setGovId(employee.govId);
                }
                if(employee.phoneNumber) {
                    setPhoneNumber(employee.phoneNumber);
                }
                if(employee.details && employee.details.dob) {
                    setDob(employee.details.dob);
                }
                if(employee.details && employee.details.gender) {
                    setGender(employee.details.gender)
                }
                if(employee.status) {
                    setStatus(employee.status)
                }

            } else {
                setFirstName("");
            }
    }, [isOpen]);

    const updateEmployee = () => {
        // if(!name) {
        //     setError("אנא הכנס שם חברה")
        // }
        if(!cityId) {
            setError("אנא הכנס כתובת")
        }

        setError(undefined)
        let employeeDetailsJson = {
        }
        let bankDetailsJson = {
        }

        let employeeJson = {
            "userId": employee.id
        }
        if(firstName) {
            employeeJson["firstName"] = firstName;
        }
        if(lastName) {
            employeeJson["lastName"] = lastName;
        }
        if(phoneNumber) {
            employeeJson["phoneNumber"] = phoneNumber;
        }
        if(status) {
            employeeJson["status"] = Number(status);
        }
        if(isActive) {
            employeeJson["isActive"] = Boolean(JSON.parse(isActive));
        }
        if(govId) {
            employeeDetailsJson["govId"] = govId;
        }
        if(dob) {
            employeeDetailsJson["dob"] = dob;
        }
        if(gender) {
            employeeDetailsJson["gender"] = gender;
        }
        if(cityId){
            employeeDetailsJson["cityId"] = cityId
        }
        if(internalId) {
            employeeDetailsJson["internalId"] = internalId;
        }
        if(jobTitle) {
            employeeDetailsJson["jobTitle"] = jobTitle;
        }
        if(jobDepartment) {
            employeeDetailsJson["jobDepartment"] = jobDepartment;
        }
        if(bankId) {
            bankDetailsJson["bankId"] = bankId;
        }
        if(branchId) {
            bankDetailsJson["branchId"] = branchId;
        }
        if(accountId) {
            bankDetailsJson["accountId"] = accountId;
        }
        if(employeeDetailsJson) {
            employeeJson["details"] = employeeDetailsJson;
        }
        if(bankDetailsJson) {
            employeeJson["details"]["bankAccountDetails"] = bankDetailsJson;
        }
        EmployeesApi.update(employeeJson).then(res => {
            callBack()
            onClose()
        })
            .catch(function (error) {
                if (error.response) {
                }
                setError("שגיאה")
            });
    }

    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>עריכת פרטי עובד</ModalHeader>
                <ModalBody>
                    <h4 style={{
                        fontSize: "1.2em",
                        color: "red",
                        textAlign: "center",
                        fontWeight: 400,
                        transition: ".2s all"}}>
                        {error}
                    </h4>
                    <FormLabel fontWeight='bold'>פרטים אישיים</FormLabel>
                    <SimpleGrid
                        columns={{sm: 2}}
                        gap='10px'
                        mb='0px'>
                        <FormControl mt={4}>
                            <Input defaultValue={firstName} onChange={(event) => {
                                setFirstName(event.target.value);
                            }} />
                        </FormControl>

                        <FormControl mt={4}>
                            <Input defaultValue={lastName} placeholder='שם משפחה' onChange={(event) => {
                                setLastName(event.target.value);
                            }} />
                        </FormControl>

                        {/*<FormControl mt={4}>*/}
                        {/*    <Input defaultValue={govId} placeholder='תעודת זהות' onChange={(event) => {*/}
                        {/*        setGovId(event.target.value);*/}
                        {/*    }} />*/}
                        {/*</FormControl>*/}

                        <FormControl mt={4}>
                            <Input defaultValue={phoneNumber} placeholder='טלפון' onChange={(event) => {
                                setPhoneNumber(event.target.value);
                            }} />

                        </FormControl>

                        <FormControl mt={4}>
                            <Input defaultValue={dob} type='date' placeholder='תאריך לידה' onChange={(event) => {
                                setDob(event.target.value);
                            }} />
                        </FormControl>

                        <Select defaultValue={gender} pr='11px' placeholder='בחר מגדר' mt={4} name="gender" onChange={(event) => { setGender(event.target.value); }}  >
                            <option value='male'>גבר</option>
                            <option value='female'>אישה</option>
                            <option value='other'>אחר</option>
                        </Select>

                        {/*<AutoComplete>*/}
                        {/*    <AutoCompleteInput  mt={4}  variant="filled" placeholder={(employee && employee.details && employee.details.cityId) ? citiesData.find(i => i.id === employee.details.cityId).name : "בחר עיר"} />*/}
                        {/*    <AutoCompleteList>*/}
                        {/*        {citiesData.map((city) => (*/}
                        {/*            <AutoCompleteItem*/}
                        {/*                key={city.id}*/}
                        {/*                value={city.name}*/}
                        {/*                name="cityId">*/}
                        {/*                <h1 key={city.id} onClick={(e) => {*/}
                        {/*                    setCityId(city.id)*/}
                        {/*                }}>{city.name}</h1>*/}
                        {/*            </AutoCompleteItem>*/}
                        {/*        ))}*/}
                        {/*    </AutoCompleteList>*/}
                        {/*</AutoComplete>*/}

                        <Select defaultValue={status} pr='11px'  placeholder='בחר סטטוס' mt={4} name="status" onChange={(event) => { setStatus(event.target.value); }}  >
                            <option value='100'>לא פעיל</option>
                            <option value='200'>פעיל</option>
                            <option value='300'>חסום</option>
                            <option value='400'>עזב</option>
                        </Select>

                    </SimpleGrid>

                    <br/>


                    {/*<FormLabel fontWeight='bold'>פרטי מעסיק</FormLabel>*/}

                    {/*<SimpleGrid*/}
                    {/*    columns={{  lg: 2 }}*/}
                    {/*    gap='15px'*/}
                    {/*    mb='0px'>*/}

                    {/*    <Select defaultValue={employee && employee.isActive === true ? 'true' : 'false'}  pr='10px' placeholder='בחר סטטוס' mt={4} onChange={(event) => {*/}
                    {/*        setIsActive(event.target.value);*/}
                    {/*    }}>*/}
                    {/*        <option value='true'>פעיל</option>*/}
                    {/*        <option value='false'>לא פעיל</option>*/}
                    {/*    </Select>*/}

                    {/*    <FormControl mt={4}>*/}
                    {/*        <Input defaultValue={employee && employee.details && employee.details.internalEmployeeId ? employee.details.internalEmployeeId : ""} placeholder='מס עובד פנימי' onChange={(event) => {*/}
                    {/*            setInternalId(event.target.value);*/}
                    {/*        }}/>*/}
                    {/*    </FormControl>*/}

                    {/*    <FormControl mt={4}>*/}
                    {/*        <Input defaultValue={employee && employee.details && employee.details.jobDepartment ? employee.details.jobDepartment : ""} placeholder='מחלקה' onChange={(event) => {*/}
                    {/*            setJobDepartment(event.target.value);*/}
                    {/*        }}/>*/}
                    {/*    </FormControl>*/}

                    {/*    <FormControl mt={4}>*/}
                    {/*        <Input defaultValue={employee && employee.details && employee.details.jobTitle ? employee.details.jobTitle : ""} placeholder='תפקיד' onChange={(event) => {*/}
                    {/*            setJobTitle(event.target.value);*/}
                    {/*        }}/>*/}
                    {/*    </FormControl>*/}
                    {/*</SimpleGrid>*/}

                    <br/>

                    {/*<FormLabel fontWeight='bold'>פרטי חשבון בנק</FormLabel>*/}
                    {/*<SimpleGrid*/}
                    {/*    columns={{  lg: 3 }}*/}
                    {/*    gap='15px'*/}
                    {/*    mb='0px'>*/}

                    {/*    <Select defaultValue={employee && employee.details && employee.details.bankId ? employee.details.bankId : ""}  pr='10px' placeholder='בחר בנק' mt={4} onChange={(event) => {*/}
                    {/*        setBankId(event.target.value);*/}
                    {/*    }}>*/}
                    {/*        <option value='12'>הפועלים</option>*/}
                    {/*        <option value='14'>אוצר החייל</option>*/}
                    {/*        <option value='11'>דיסקונט</option>*/}
                    {/*        <option value='10'>לאומי</option>*/}
                    {/*        <option value='20'>מזרחי טפחות</option>*/}
                    {/*        <option value='31'>הבינלאומי</option>*/}
                    {/*        <option value='13'>אגוד</option>*/}
                    {/*        <option value='04'>יהב לעובדי מדינה</option>*/}
                    {/*        <option value='54'>ירושלים</option>*/}
                    {/*        <option value='46'>מסד</option>*/}
                    {/*        <option value='17'>מרכנתיל דיסקונט</option>*/}
                    {/*        <option value='34'>ערבי ישראלי</option>*/}
                    {/*        <option value='26'>יובנק</option>*/}
                    {/*    </Select>*/}

                    {/*    <FormControl mt={4}>*/}
                    {/*        <Input defaultValue={employee && employee.details && employee.details.bankBranchId ? employee.details.bankBranchId : ""} placeholder='סניף' onChange={(event) => {*/}
                    {/*            setBranchId(event.target.value);*/}
                    {/*        }} />*/}
                    {/*    </FormControl>*/}

                    {/*    <FormControl mt={4}>*/}
                    {/*        <Input defaultValue={employee && employee.details && employee.details.bankAccountId ? employee.details.bankAccountId : ""} placeholder='חשבון' onChange={(event) => {*/}
                    {/*            setAccountId(event.target.value);*/}
                    {/*        }}/>*/}
                    {/*    </FormControl>*/}
                    {/*</SimpleGrid>*/}
                </ModalBody>
                <ModalFooter>
                    <Button variant={'brand'} onClick={updateEmployee}>אישור</Button>
                    <Button onClick={onClose}>סגור</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
