import * as XLSX from "xlsx";

export const generateTimeReportingFile = (data) => {

    if(data) {
        data.sort(function(a, b) {
            return a['userId'] - b['userId'];
        });

        let shiftsFinal = []
        data.forEach(shift => {
            let element = {};
            element['שם'] = shift['name']
            element['מספר עובד פיירו'] = shift['userId']
            element['תאריך'] = shift['shiftDate']
            element['התחלת עבודה'] = shift['startTime']
            element['סוף עבודה'] = shift['endTime']
            element['שעות עבודה'] = shift['workingHours']
            shiftsFinal.push(element)
        });
        const worksheet = XLSX.utils.json_to_sheet(shiftsFinal);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

        /* fix headers */
        XLSX.utils.sheet_add_aoa(worksheet, [["שם", "מספר עובד פיירו", "תאריך", "התחלת עבודה", "סוף עבודה", "שעות עבודה"]], { origin: "A1" });

        /* calculate column width */
        // const max_width = employees.reduce((w, r) => Math.max(w, r.length), 10);
        worksheet["!cols"] = [ { wch: 30 }, { wch: 15 }, { wch: 30 }, { wch: 30 }, { wch: 20 } ];

        /* create an XLSX file and try to save to Presidents.xlsx */
        XLSX.writeFile(workbook, "TimeReporting.xlsx");
    }
}
