// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { pieChartData, pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";
import React, {useEffect} from "react";
import apexchart from "apexcharts";

function percentage(partialValue, totalValue) {
  return (100 * partialValue) / totalValue;
}

export default function Conversion(props) {
  const {activeUsers, nonActiveUsers, ...rest } = props;


  const [activeUsersCount, setActiveUsersCount] = React.useState(0)
  const [nonActiveUsersCount, setNonActiveUsersCount] = React.useState(0)
  const [data, updateData] = React.useState([0,0]);

  // if(activeUsers != null && activeUsers > 0) {
  //     setActiveUsersCount(activeUsers)
  // }
  // if(nonActiveUsers != null  && nonActiveUsers > 0) {
  //   setNonActiveUsersCount(nonActiveUsers)
  // }

  //const [pieChartData, setPieChartData] = React.useState([75, 25])

  useEffect(() => {
    updateData([activeUsers, nonActiveUsers])
  }, [activeUsers, nonActiveUsers]);

  const pieChartData = [activeUsers, nonActiveUsers]
  // alert(pieChartData)
  const totalUsers = activeUsers + nonActiveUsers;
  const activeUsersPercentage = Math.round(percentage(activeUsers, totalUsers))
  const nonActiveUsersPercentage = Math.round(percentage(nonActiveUsers, totalUsers))

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card p='20px' align='center' direction='column' w='100%' {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent='space-between'
        alignItems='center'
        w='100%'
        mb='20px'>
        <Text
            color={textColor}
            fontSize='22px'
            fontWeight='700'
            lineHeight='100%'>
          עובדים רשומים
        </Text>
      </Flex>

      <PieChart
        h='100%'
        w='100%'
        chartData={data}
        chartOptions={pieChartOptions}
      />
      <Flex
        // bg={cardColor}
        flexDirection='row'
        // boxShadow={cardShadow}
          justifyContent='center'
        w='100%'
        p='15px'
        px='20px'
        mt='15px'
        mx='auto'
        >
        <Flex direction='column' py='5px'  >
          <Flex align='center'>
            {/*registered bg*/}
            <Box h='8px' w='8px' bg='brand.500' borderRadius='50%' me='4px'  />
            <Text
              fontSize='s'
              color='secondaryGray.600'
              fontWeight='700'
              mb='0px'>
              רשומים
            </Text>
          </Flex>
          <Text fontSize='30px' color={textColor} fontWeight='700'>
            {activeUsers}
          </Text>
        </Flex>
        <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "50px" }} />
        <Flex direction='column' py='5px' align='center'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='#d0dcfb' borderRadius='50%' me='4px' />
            <Text
                fontSize='s'
                color='secondaryGray.600'
                fontWeight='700'
                mb='0px'>
              טרם נרשמו
            </Text>
          </Flex>
          <Text fontSize='30px' color={textColor} fontWeight='700'>
            {nonActiveUsers}
          </Text>
        </Flex>
        {/*<Flex direction='column' py='5px' me='10px'>*/}
        {/*  <Flex align='center'  width='80px'>*/}
        {/*    <Box h='8px' w='8px' bg='#6AD2FF' borderRadius='50%' me='4px' />*/}
        {/*    <Text*/}
        {/*      fontSize='xs'*/}
        {/*      color='secondaryGray.600'*/}
        {/*      fontWeight='700'*/}
        {/*      mb='0px'>*/}
        {/*      טרם התחברו*/}
        {/*    </Text>*/}
        {/*  </Flex>*/}
        {/*  <Text fontSize='lg' color={textColor} fontWeight='700'>*/}
        {/*    {nonActiveUsersPercentage}%*/}
        {/*  </Text>*/}
        {/*</Flex>*/}
      </Flex>
    </Card>
  );
}
