export const cities = [
  {
    "id": 2640,
    "name": "ראש העין",
    "name_eng": "ROSH HAAYIN"
  }
]
export const citiesB = [
    {
      "id": 1331,
      "name": "כמאנה",
      "name_eng": ""
    },
    {
      "id": 3826,
      "name": "שער שומרון",
      "name_eng": ""
    },
    {
      "id": 1347,
      "name": "קצר א-סר",
      "name_eng": ""
    },
    {
      "id": 3400,
      "name": "חברון",
      "name_eng": ""
    },
    {
      "id": 3777,
      "name": "סנסנה",
      "name_eng": ""
    },
    {
      "id": 963,
      "name": "אעצם (שבט)",
      "name_eng": "A'SAM"
    },
    {
      "id": 1220,
      "name": "אבירים",
      "name_eng": "ABBIRIM"
    },
    {
      "id": 958,
      "name": "אבו עבדון (שבט)",
      "name_eng": "ABU ABDUN"
    },
    {
      "id": 1042,
      "name": "אבו עמאר (שבט)",
      "name_eng": "ABU AMMAR"
    },
    {
      "id": 932,
      "name": "אבו עמרה (שבט)",
      "name_eng": "ABU AMRE"
    },
    {
      "id": 472,
      "name": "אבו גוש",
      "name_eng": "ABU GHOSH"
    },
    {
      "id": 967,
      "name": "אבו ג'ווייעד (שבט)",
      "name_eng": "ABU JUWEI'ID"
    },
    {
      "id": 968,
      "name": "אבו קורינאת (שבט)",
      "name_eng": "ABU QUREINAT"
    },
    {
      "id": 1342,
      "name": "אבו קרינאת (יישוב)",
      "name_eng": "ABU QUREINAT"
    },
    {
      "id": 966,
      "name": "אבו רובייעה (שבט)",
      "name_eng": "ABU RUBEI'A"
    },
    {
      "id": 961,
      "name": "אבו רוקייק (שבט)",
      "name_eng": "ABU RUQAYYEQ"
    },
    {
      "id": 473,
      "name": "אבו סנאן",
      "name_eng": "ABU SINAN"
    },
    {
      "id": 935,
      "name": "אבו סריחאן (שבט)",
      "name_eng": "ABU SUREIHAN"
    },
    {
      "id": 1375,
      "name": "אבו תלול",
      "name_eng": "ABU TULUL"
    },
    {
      "id": 1068,
      "name": "אדמית",
      "name_eng": "ADAMIT"
    },
    {
      "id": 2035,
      "name": "עדנים",
      "name_eng": "ADANIM"
    },
    {
      "id": 1123,
      "name": "אדרת",
      "name_eng": "ADDERET"
    },
    {
      "id": 113,
      "name": "אדירים",
      "name_eng": "ADDIRIM"
    },
    {
      "id": 1199,
      "name": "עדי",
      "name_eng": "ADI"
    },
    {
      "id": 3759,
      "name": "אדורה",
      "name_eng": "ADORA"
    },
    {
      "id": 959,
      "name": "אפיניש (שבט)",
      "name_eng": "AFEINISH"
    },
    {
      "id": 313,
      "name": "אפק",
      "name_eng": "AFEQ"
    },
    {
      "id": 4301,
      "name": "אפיק",
      "name_eng": "AFIQ"
    },
    {
      "id": 176,
      "name": "אפיקים",
      "name_eng": "AFIQIM"
    },
    {
      "id": 7700,
      "name": "עפולה",
      "name_eng": "AFULA"
    },
    {
      "id": 794,
      "name": "עגור",
      "name_eng": "AGUR"
    },
    {
      "id": 1157,
      "name": "אחווה",
      "name_eng": "AHAWA"
    },
    {
      "id": 797,
      "name": "אחיעזר",
      "name_eng": "AHI'EZER"
    },
    {
      "id": 785,
      "name": "אחיהוד",
      "name_eng": "AHIHUD"
    },
    {
      "id": 804,
      "name": "אחיסמך",
      "name_eng": "AHISAMAKH"
    },
    {
      "id": 850,
      "name": "אחיטוב",
      "name_eng": "AHITUV"
    },
    {
      "id": 821,
      "name": "אחוזם",
      "name_eng": "AHUZZAM"
    },
    {
      "id": 1330,
      "name": "אחוזת ברק",
      "name_eng": "AHUZZAT BARAQ"
    },
    {
      "id": 7600,
      "name": "עכו",
      "name_eng": "AKKO"
    },
    {
      "id": 1359,
      "name": "אל סייד",
      "name_eng": "AL SAYYID"
    },
    {
      "id": 1316,
      "name": "אל-עריאן",
      "name_eng": "AL-ARYAN"
    },
    {
      "id": 1339,
      "name": "אל-עזי",
      "name_eng": "AL-AZY"
    },
    {
      "id": 3727,
      "name": "עלי זהב",
      "name_eng": "ALE ZAHAV"
    },
    {
      "id": 3750,
      "name": "אלפי מנשה",
      "name_eng": "ALFE MENASHE"
    },
    {
      "id": 1182,
      "name": "אלון הגליל",
      "name_eng": "ALLON HAGALIL"
    },
    {
      "id": 3604,
      "name": "אלון שבות",
      "name_eng": "ALLON SHEVUT"
    },
    {
      "id": 429,
      "name": "אלוני אבא",
      "name_eng": "ALLONE ABBA"
    },
    {
      "id": 4017,
      "name": "אלוני הבשן",
      "name_eng": "ALLONE HABASHAN"
    },
    {
      "id": 868,
      "name": "אלוני יצחק",
      "name_eng": "ALLONE YIZHAQ"
    },
    {
      "id": 285,
      "name": "אלונים",
      "name_eng": "ALLONIM"
    },
    {
      "id": 688,
      "name": "עלמה",
      "name_eng": "ALMA"
    },
    {
      "id": 1125,
      "name": "אלמגור",
      "name_eng": "ALMAGOR"
    },
    {
      "id": 3556,
      "name": "אלמוג",
      "name_eng": "ALMOG"
    },
    {
      "id": 3715,
      "name": "עלמון",
      "name_eng": "ALMON"
    },
    {
      "id": 1146,
      "name": "עלומים",
      "name_eng": "ALUMIM"
    },
    {
      "id": 1145,
      "name": "אלומה",
      "name_eng": "ALUMMA"
    },
    {
      "id": 330,
      "name": "אלומות",
      "name_eng": "ALUMMOT"
    },
    {
      "id": 23,
      "name": "אמציה",
      "name_eng": "AMAZYA"
    },
    {
      "id": 319,
      "name": "עמיר",
      "name_eng": "AMIR"
    },
    {
      "id": 1064,
      "name": "אמירים",
      "name_eng": "AMIRIM"
    },
    {
      "id": 385,
      "name": "עמיעד",
      "name_eng": "AMMI'AD"
    },
    {
      "id": 318,
      "name": "עמיעוז",
      "name_eng": "AMMI'OZ"
    },
    {
      "id": 3824,
      "name": "עמיחי",
      "name_eng": "AMMIHAY"
    },
    {
      "id": 779,
      "name": "עמינדב",
      "name_eng": "AMMINADAV"
    },
    {
      "id": 773,
      "name": "עמיקם",
      "name_eng": "AMMIQAM"
    },
    {
      "id": 1253,
      "name": "אמנון",
      "name_eng": "AMNUN"
    },
    {
      "id": 708,
      "name": "עמקה",
      "name_eng": "AMQA"
    },
    {
      "id": 1212,
      "name": "עמוקה",
      "name_eng": "AMUQQA"
    },
    {
      "id": 4012,
      "name": "אניעם",
      "name_eng": "ANI'AM"
    },
    {
      "id": 637,
      "name": "ערערה",
      "name_eng": "AR'ARA"
    },
    {
      "id": 1192,
      "name": "ערערה-בנגב",
      "name_eng": "AR'ARA-BANEGEV"
    },
    {
      "id": 2560,
      "name": "ערד",
      "name_eng": "ARAD"
    },
    {
      "id": 1246,
      "name": "עראמשה",
      "name_eng": "ARAMSHA"
    },
    {
      "id": 701,
      "name": "ארבל",
      "name_eng": "ARBEL"
    },
    {
      "id": 3598,
      "name": "ארגמן",
      "name_eng": "ARGAMAN"
    },
    {
      "id": 3570,
      "name": "אריאל",
      "name_eng": "ARI'EL"
    },
    {
      "id": 1335,
      "name": "ערב אל נעים",
      "name_eng": "ARRAB AL NAIM"
    },
    {
      "id": 531,
      "name": "עראבה",
      "name_eng": "ARRABE"
    },
    {
      "id": 1324,
      "name": "ארסוף",
      "name_eng": "ARSUF"
    },
    {
      "id": 593,
      "name": "ערוגות",
      "name_eng": "ARUGOT"
    },
    {
      "id": 960,
      "name": "אסד (שבט)",
      "name_eng": "ASAD"
    },
    {
      "id": 3754,
      "name": "אספר",
      "name_eng": "ASEFAR"
    },
    {
      "id": 591,
      "name": "עשרת",
      "name_eng": "ASERET"
    },
    {
      "id": 1152,
      "name": "אשלים",
      "name_eng": "ASHALIM"
    },
    {
      "id": 70,
      "name": "אשדוד",
      "name_eng": "ASHDOD"
    },
    {
      "id": 199,
      "name": "אשדות יעקב (איחוד)",
      "name_eng": "ASHDOT YA'AQOV(IHUD)"
    },
    {
      "id": 188,
      "name": "אשדות יעקב (מאוחד)",
      "name_eng": "ASHDOT YA'AQOV(ME'UH"
    },
    {
      "id": 1256,
      "name": "אשרת",
      "name_eng": "ASHERAT"
    },
    {
      "id": 7100,
      "name": "אשקלון",
      "name_eng": "ASHQELON"
    },
    {
      "id": 969,
      "name": "עטאוונה (שבט)",
      "name_eng": "ATAWNE"
    },
    {
      "id": 3658,
      "name": "עטרת",
      "name_eng": "ATERET"
    },
    {
      "id": 53,
      "name": "עתלית",
      "name_eng": "ATLIT"
    },
    {
      "id": 965,
      "name": "אטרש (שבט)",
      "name_eng": "ATRASH"
    },
    {
      "id": 917,
      "name": "עצמון שגב",
      "name_eng": "ATSMON-SEGEV"
    },
    {
      "id": 892,
      "name": "עבדון",
      "name_eng": "AVDON"
    },
    {
      "id": 3786,
      "name": "אבנת",
      "name_eng": "AVENAT"
    },
    {
      "id": 679,
      "name": "אביאל",
      "name_eng": "AVI'EL"
    },
    {
      "id": 1070,
      "name": "אביעזר",
      "name_eng": "AVI'EZER"
    },
    {
      "id": 819,
      "name": "אביגדור",
      "name_eng": "AVIGEDOR"
    },
    {
      "id": 175,
      "name": "אביחיל",
      "name_eng": "AVIHAYIL"
    },
    {
      "id": 2052,
      "name": "אביטל",
      "name_eng": "AVITAL"
    },
    {
      "id": 1115,
      "name": "אביבים",
      "name_eng": "AVIVIM"
    },
    {
      "id": 4011,
      "name": "אבני איתן",
      "name_eng": "AVNE ETAN"
    },
    {
      "id": 3793,
      "name": "אבני חפץ",
      "name_eng": "AVNE HEFEZ"
    },
    {
      "id": 1311,
      "name": "אבשלום",
      "name_eng": "AVSHALOM"
    },
    {
      "id": 1275,
      "name": "אבטליון",
      "name_eng": "AVTALYON"
    },
    {
      "id": 156,
      "name": "עיינות",
      "name_eng": "AYANOT"
    },
    {
      "id": 77,
      "name": "איילת השחר",
      "name_eng": "AYYELET HASHAHAR"
    },
    {
      "id": 711,
      "name": "עזריה",
      "name_eng": "AZARYA"
    },
    {
      "id": 565,
      "name": "אזור",
      "name_eng": "AZOR"
    },
    {
      "id": 837,
      "name": "עזריאל",
      "name_eng": "AZRI'EL"
    },
    {
      "id": 817,
      "name": "עזריקם",
      "name_eng": "AZRIQAM"
    },
    {
      "id": 2043,
      "name": "בחן",
      "name_eng": "BAHAN"
    },
    {
      "id": 94,
      "name": "בלפוריה",
      "name_eng": "BALFURIYYA"
    },
    {
      "id": 6000,
      "name": "באקה אל-גרביה",
      "name_eng": "BAQA AL-GHARBIYYE"
    },
    {
      "id": 823,
      "name": "בר גיורא",
      "name_eng": "BAR GIYYORA"
    },
    {
      "id": 1191,
      "name": "בר יוחאי",
      "name_eng": "BAR YOHAY"
    },
    {
      "id": 667,
      "name": "ברעם",
      "name_eng": "BAR'AM"
    },
    {
      "id": 141,
      "name": "ברק",
      "name_eng": "BARAQ"
    },
    {
      "id": 2038,
      "name": "ברקת",
      "name_eng": "BAREQET"
    },
    {
      "id": 3654,
      "name": "ברקן",
      "name_eng": "BARQAN"
    },
    {
      "id": 617,
      "name": "ברקאי",
      "name_eng": "BARQAY"
    },
    {
      "id": 1326,
      "name": "בסמ\"ה",
      "name_eng": "BASMA"
    },
    {
      "id": 944,
      "name": "בסמת טבעון",
      "name_eng": "BASMAT TAB'UN"
    },
    {
      "id": 3794,
      "name": "בת עין",
      "name_eng": "BAT AYIN"
    },
    {
      "id": 1323,
      "name": "בת הדר",
      "name_eng": "BAT HADAR"
    },
    {
      "id": 1319,
      "name": "בת חפר",
      "name_eng": "BAT HEFER"
    },
    {
      "id": 1361,
      "name": "בת חן",
      "name_eng": "BAT HEN"
    },
    {
      "id": 33,
      "name": "בת שלמה",
      "name_eng": "BAT SHELOMO"
    },
    {
      "id": 6200,
      "name": "בת ים",
      "name_eng": "BAT YAM"
    },
    {
      "id": 389,
      "name": "בצרה",
      "name_eng": "BAZRA"
    },
    {
      "id": 1278,
      "name": "באר מילכה",
      "name_eng": "BE'ER MILKA"
    },
    {
      "id": 21,
      "name": "באר אורה",
      "name_eng": "BE'ER ORA"
    },
    {
      "id": 9000,
      "name": "באר שבע",
      "name_eng": "BE'ER SHEVA"
    },
    {
      "id": 155,
      "name": "באר טוביה",
      "name_eng": "BE'ER TUVEYA"
    },
    {
      "id": 2530,
      "name": "באר יעקב",
      "name_eng": "BE'ER YA'AQOV"
    },
    {
      "id": 399,
      "name": "בארי",
      "name_eng": "BE'ERI"
    },
    {
      "id": 450,
      "name": "בארות יצחק",
      "name_eng": "BE'EROT YIZHAQ"
    },
    {
      "id": 697,
      "name": "בארותיים",
      "name_eng": "BE'EROTAYIM"
    },
    {
      "id": 1376,
      "name": "באר גנים",
      "name_eng": "BEER GANNIM"
    },
    {
      "id": 480,
      "name": "בית ג'ן",
      "name_eng": "BEIT JANN"
    },
    {
      "id": 712,
      "name": "בן עמי",
      "name_eng": "BEN AMMI"
    },
    {
      "id": 2013,
      "name": "בן שמן (מושב)",
      "name_eng": "BEN SHEMEN (MOSHAV)"
    },
    {
      "id": 1084,
      "name": "בן שמן (כפר נוער)",
      "name_eng": "BEN SHEMEN(K.NO'AR)"
    },
    {
      "id": 760,
      "name": "בן זכאי",
      "name_eng": "BEN ZAKKAY"
    },
    {
      "id": 685,
      "name": "בניה",
      "name_eng": "BENAYA"
    },
    {
      "id": 448,
      "name": "בני עטרות",
      "name_eng": "BENE ATAROT"
    },
    {
      "id": 1066,
      "name": "בני עי\"ש",
      "name_eng": "BENE AYISH"
    },
    {
      "id": 6100,
      "name": "בני ברק",
      "name_eng": "BENE BERAQ"
    },
    {
      "id": 592,
      "name": "בני דרום",
      "name_eng": "BENE DAROM"
    },
    {
      "id": 386,
      "name": "בני דרור",
      "name_eng": "BENE DEROR"
    },
    {
      "id": 1363,
      "name": "בני נצרים",
      "name_eng": "BENE NEZARIM"
    },
    {
      "id": 588,
      "name": "בני ראם",
      "name_eng": "BENE RE'EM"
    },
    {
      "id": 4015,
      "name": "בני יהודה",
      "name_eng": "BENE YEHUDA"
    },
    {
      "id": 418,
      "name": "בני ציון",
      "name_eng": "BENE ZIYYON"
    },
    {
      "id": 3612,
      "name": "בקעות",
      "name_eng": "BEQA'OT"
    },
    {
      "id": 864,
      "name": "בקוע",
      "name_eng": "BEQOA"
    },
    {
      "id": 3710,
      "name": "ברכה",
      "name_eng": "BERAKHA"
    },
    {
      "id": 746,
      "name": "ברכיה",
      "name_eng": "BEREKHYA"
    },
    {
      "id": 428,
      "name": "ברור חיל",
      "name_eng": "BEROR HAYIL"
    },
    {
      "id": 2060,
      "name": "ברוש",
      "name_eng": "BEROSH"
    },
    {
      "id": 95,
      "name": "בית אלפא",
      "name_eng": "BET ALFA"
    },
    {
      "id": 604,
      "name": "בית עריף",
      "name_eng": "BET ARIF"
    },
    {
      "id": 3652,
      "name": "בית אריה",
      "name_eng": "BET ARYE"
    },
    {
      "id": 1076,
      "name": "בית ברל",
      "name_eng": "BET BERL"
    },
    {
      "id": 466,
      "name": "בית דגן",
      "name_eng": "BET DAGAN"
    },
    {
      "id": 3574,
      "name": "בית אל",
      "name_eng": "BET EL"
    },
    {
      "id": 562,
      "name": "בית אלעזרי",
      "name_eng": "BET EL'AZARI"
    },
    {
      "id": 756,
      "name": "בית עזרא",
      "name_eng": "BET EZRA"
    },
    {
      "id": 571,
      "name": "בית גמליאל",
      "name_eng": "BET GAMLI'EL"
    },
    {
      "id": 619,
      "name": "בית גוברין",
      "name_eng": "BET GUVRIN"
    },
    {
      "id": 3645,
      "name": "בית הערבה",
      "name_eng": "BET HAARAVA"
    },
    {
      "id": 572,
      "name": "בית העמק",
      "name_eng": "BET HAEMEQ"
    },
    {
      "id": 723,
      "name": "בית הגדי",
      "name_eng": "BET HAGADDI"
    },
    {
      "id": 373,
      "name": "בית הלוי",
      "name_eng": "BET HALEVI"
    },
    {
      "id": 159,
      "name": "בית חנן",
      "name_eng": "BET HANAN"
    },
    {
      "id": 800,
      "name": "בית חנניה",
      "name_eng": "BET HANANYA"
    },
    {
      "id": 242,
      "name": "בית השיטה",
      "name_eng": "BET HASHITTA"
    },
    {
      "id": 1050,
      "name": "בית חשמונאי",
      "name_eng": "BET HASHMONAY"
    },
    {
      "id": 877,
      "name": "בית חירות",
      "name_eng": "BET HERUT"
    },
    {
      "id": 322,
      "name": "בית הלל",
      "name_eng": "BET HILLEL"
    },
    {
      "id": 2033,
      "name": "בית חלקיה",
      "name_eng": "BET HILQIYYA"
    },
    {
      "id": 3575,
      "name": "בית חורון",
      "name_eng": "BET HORON"
    },
    {
      "id": 430,
      "name": "בית לחם הגלילית",
      "name_eng": "BET LEHEM HAGELILIT"
    },
    {
      "id": 751,
      "name": "בית מאיר",
      "name_eng": "BET ME'IR"
    },
    {
      "id": 784,
      "name": "בית נחמיה",
      "name_eng": "BET NEHEMYA"
    },
    {
      "id": 672,
      "name": "בית נקופה",
      "name_eng": "BET NEQOFA"
    },
    {
      "id": 16,
      "name": "בית ניר",
      "name_eng": "BET NIR"
    },
    {
      "id": 317,
      "name": "בית אורן",
      "name_eng": "BET OREN"
    },
    {
      "id": 202,
      "name": "בית עובד",
      "name_eng": "BET OVED"
    },
    {
      "id": 598,
      "name": "בית קמה",
      "name_eng": "BET QAMA"
    },
    {
      "id": 365,
      "name": "בית קשת",
      "name_eng": "BET QESHET"
    },
    {
      "id": 848,
      "name": "בית רבן",
      "name_eng": "BET RABBAN"
    },
    {
      "id": 1162,
      "name": "בית רימון",
      "name_eng": "BET RIMMON"
    },
    {
      "id": 9200,
      "name": "בית שאן",
      "name_eng": "BET SHE'AN"
    },
    {
      "id": 248,
      "name": "בית שערים",
      "name_eng": "BET SHE'ARIM"
    },
    {
      "id": 2610,
      "name": "בית שמש",
      "name_eng": "BET SHEMESH"
    },
    {
      "id": 747,
      "name": "בית שקמה",
      "name_eng": "BET SHIQMA"
    },
    {
      "id": 301,
      "name": "בית עוזיאל",
      "name_eng": "BET UZZI'EL"
    },
    {
      "id": 200,
      "name": "בית ינאי",
      "name_eng": "BET YANNAY"
    },
    {
      "id": 288,
      "name": "בית יהושע",
      "name_eng": "BET YEHOSHUA"
    },
    {
      "id": 326,
      "name": "בית יצחק-שער חפר",
      "name_eng": "BET YIZHAQ-SH. HEFER"
    },
    {
      "id": 265,
      "name": "בית יוסף",
      "name_eng": "BET YOSEF"
    },
    {
      "id": 710,
      "name": "בית זית",
      "name_eng": "BET ZAYIT"
    },
    {
      "id": 353,
      "name": "בית זיד",
      "name_eng": "BET ZEID"
    },
    {
      "id": 143,
      "name": "בית זרע",
      "name_eng": "BET ZERA"
    },
    {
      "id": 212,
      "name": "בית צבי",
      "name_eng": "BET ZEVI"
    },
    {
      "id": 3780,
      "name": "ביתר עילית",
      "name_eng": "BETAR ILLIT"
    },
    {
      "id": 589,
      "name": "בצת",
      "name_eng": "BEZET"
    },
    {
      "id": 483,
      "name": "בענה",
      "name_eng": "BI NE"
    },
    {
      "id": 9800,
      "name": "בנימינה-גבעת עדה",
      "name_eng": "BINYAMINA"
    },
    {
      "id": 998,
      "name": "ביר אל-מכסור",
      "name_eng": "BIR EL-MAKSUR"
    },
    {
      "id": 1348,
      "name": "ביר הדאג'",
      "name_eng": "BIR HADAGE"
    },
    {
      "id": 368,
      "name": "ביריה",
      "name_eng": "BIRIYYA"
    },
    {
      "id": 252,
      "name": "ביתן אהרן",
      "name_eng": "BITAN AHARON"
    },
    {
      "id": 762,
      "name": "בטחה",
      "name_eng": "BITHA"
    },
    {
      "id": 234,
      "name": "ביצרון",
      "name_eng": "BIZZARON"
    },
    {
      "id": 1368,
      "name": "בני דקלים",
      "name_eng": "BNE DKALIM"
    },
    {
      "id": 3744,
      "name": "ברוכין",
      "name_eng": "BRUKHIN"
    },
    {
      "id": 482,
      "name": "בועיינה-נוג'ידאת",
      "name_eng": "BU'EINE-NUJEIDAT"
    },
    {
      "id": 4001,
      "name": "בוקעאתא",
      "name_eng": "BUQ'ATA"
    },
    {
      "id": 698,
      "name": "בורגתה",
      "name_eng": "BURGETA"
    },
    {
      "id": 559,
      "name": "בוסתן הגליל",
      "name_eng": "BUSTAN HAGALIL"
    },
    {
      "id": 489,
      "name": "דבוריה",
      "name_eng": "DABBURYE"
    },
    {
      "id": 302,
      "name": "דפנה",
      "name_eng": "DAFNA"
    },
    {
      "id": 475,
      "name": "דחי",
      "name_eng": "DAHI"
    },
    {
      "id": 494,
      "name": "דאלית אל-כרמל",
      "name_eng": "DALIYAT AL-KARMEL"
    },
    {
      "id": 300,
      "name": "דליה",
      "name_eng": "DALIYYA"
    },
    {
      "id": 431,
      "name": "דלתון",
      "name_eng": "DALTON"
    },
    {
      "id": 303,
      "name": "דן",
      "name_eng": "DAN"
    },
    {
      "id": 407,
      "name": "דברת",
      "name_eng": "DAVERAT"
    },
    {
      "id": 62,
      "name": "דגניה א'",
      "name_eng": "DEGANYA ALEF"
    },
    {
      "id": 79,
      "name": "דגניה ב'",
      "name_eng": "DEGANYA BET"
    },
    {
      "id": 490,
      "name": "דייר אל-אסד",
      "name_eng": "DEIR AL-ASAD"
    },
    {
      "id": 492,
      "name": "דייר חנא",
      "name_eng": "DEIR HANNA"
    },
    {
      "id": 493,
      "name": "דייר ראפאת",
      "name_eng": "DEIR RAFAT"
    },
    {
      "id": 1317,
      "name": "דמיידה",
      "name_eng": "DEMEIDE"
    },
    {
      "id": 1241,
      "name": "דקל",
      "name_eng": "DEQEL"
    },
    {
      "id": 1349,
      "name": "דריג'את",
      "name_eng": "DERIG'AT"
    },
    {
      "id": 849,
      "name": "דבירה",
      "name_eng": "DEVIRA"
    },
    {
      "id": 146,
      "name": "דבורה",
      "name_eng": "DEVORA"
    },
    {
      "id": 2200,
      "name": "דימונה",
      "name_eng": "DIMONA"
    },
    {
      "id": 2063,
      "name": "דישון",
      "name_eng": "DISHON"
    },
    {
      "id": 3747,
      "name": "דולב",
      "name_eng": "DOLEV"
    },
    {
      "id": 738,
      "name": "דור",
      "name_eng": "DOR"
    },
    {
      "id": 336,
      "name": "דורות",
      "name_eng": "DOROT"
    },
    {
      "id": 1067,
      "name": "דוב\"ב",
      "name_eng": "DOVEV"
    },
    {
      "id": 3650,
      "name": "אפרת",
      "name_eng": "EFRAT"
    },
    {
      "id": 530,
      "name": "עיילבון",
      "name_eng": "EILABUN"
    },
    {
      "id": 546,
      "name": "עין אל-אסד",
      "name_eng": "EIN AL-ASAD"
    },
    {
      "id": 1320,
      "name": "עין חוד",
      "name_eng": "EIN HOD"
    },
    {
      "id": 532,
      "name": "עין מאהל",
      "name_eng": "EIN MAHEL"
    },
    {
      "id": 521,
      "name": "עין נקובא",
      "name_eng": "EIN NAQQUBA"
    },
    {
      "id": 4502,
      "name": "עין קנייא",
      "name_eng": "EIN QINIYYE"
    },
    {
      "id": 514,
      "name": "עין ראפה",
      "name_eng": "EIN RAFA"
    },
    {
      "id": 1309,
      "name": "אלעד",
      "name_eng": "EL'AD"
    },
    {
      "id": 3618,
      "name": "אלעזר",
      "name_eng": "EL'AZAR"
    },
    {
      "id": 4003,
      "name": "אל-רום",
      "name_eng": "EL-ROM"
    },
    {
      "id": 2600,
      "name": "אילת",
      "name_eng": "ELAT"
    },
    {
      "id": 3765,
      "name": "עלי",
      "name_eng": "ELI"
    },
    {
      "id": 4002,
      "name": "אלי-עד",
      "name_eng": "ELI AL"
    },
    {
      "id": 1365,
      "name": "אליאב",
      "name_eng": "ELIAV"
    },
    {
      "id": 1248,
      "name": "אליפז",
      "name_eng": "ELIFAZ"
    },
    {
      "id": 730,
      "name": "אליפלט",
      "name_eng": "ELIFELET"
    },
    {
      "id": 841,
      "name": "אלישמע",
      "name_eng": "ELISHAMA"
    },
    {
      "id": 294,
      "name": "אילון",
      "name_eng": "ELON"
    },
    {
      "id": 3579,
      "name": "אלון מורה",
      "name_eng": "ELON MORE"
    },
    {
      "id": 1126,
      "name": "אילות",
      "name_eng": "ELOT"
    },
    {
      "id": 3560,
      "name": "אלקנה",
      "name_eng": "ELQANA"
    },
    {
      "id": 603,
      "name": "אלקוש",
      "name_eng": "ELQOSH"
    },
    {
      "id": 41,
      "name": "אליכין",
      "name_eng": "ELYAKHIN"
    },
    {
      "id": 682,
      "name": "אליקים",
      "name_eng": "ELYAQIM"
    },
    {
      "id": 204,
      "name": "אלישיב",
      "name_eng": "ELYASHIV"
    },
    {
      "id": 772,
      "name": "אמונים",
      "name_eng": "EMUNIM"
    },
    {
      "id": 687,
      "name": "עין איילה",
      "name_eng": "EN AYYALA"
    },
    {
      "id": 436,
      "name": "עין דור",
      "name_eng": "EN DOR"
    },
    {
      "id": 2042,
      "name": "עין גדי",
      "name_eng": "EN GEDI"
    },
    {
      "id": 273,
      "name": "עין גב",
      "name_eng": "EN GEV"
    },
    {
      "id": 1240,
      "name": "עין הבשור",
      "name_eng": "EN HABESOR"
    },
    {
      "id": 367,
      "name": "עין העמק",
      "name_eng": "EN HAEMEQ"
    },
    {
      "id": 167,
      "name": "עין החורש",
      "name_eng": "EN HAHORESH"
    },
    {
      "id": 289,
      "name": "עין המפרץ",
      "name_eng": "EN HAMIFRAZ"
    },
    {
      "id": 383,
      "name": "עין הנצי\"ב",
      "name_eng": "EN HANAZIV"
    },
    {
      "id": 89,
      "name": "עין חרוד (איחוד)",
      "name_eng": "EN HAROD (IHUD)"
    },
    {
      "id": 82,
      "name": "עין חרוד (מאוחד)",
      "name_eng": "EN HAROD(ME'UHAD)"
    },
    {
      "id": 676,
      "name": "עין השלושה",
      "name_eng": "EN HASHELOSHA"
    },
    {
      "id": 270,
      "name": "עין השופט",
      "name_eng": "EN HASHOFET"
    },
    {
      "id": 1053,
      "name": "עין חצבה",
      "name_eng": "EN HAZEVA"
    },
    {
      "id": 74,
      "name": "עין הוד",
      "name_eng": "EN HOD"
    },
    {
      "id": 223,
      "name": "עין עירון",
      "name_eng": "EN IRON"
    },
    {
      "id": 1056,
      "name": "עין כרם-בי\"ס חקלאי",
      "name_eng": "EN KAREM-B.S.HAQLA'I"
    },
    {
      "id": 426,
      "name": "עין כרמל",
      "name_eng": "EN KARMEL"
    },
    {
      "id": 880,
      "name": "עין שריד",
      "name_eng": "EN SARID"
    },
    {
      "id": 139,
      "name": "עין שמר",
      "name_eng": "EN SHEMER"
    },
    {
      "id": 1251,
      "name": "עין תמר",
      "name_eng": "EN TAMAR"
    },
    {
      "id": 157,
      "name": "עין ורד",
      "name_eng": "EN WERED"
    },
    {
      "id": 813,
      "name": "עין יעקב",
      "name_eng": "EN YA'AQOV"
    },
    {
      "id": 806,
      "name": "עין יהב",
      "name_eng": "EN YAHAV"
    },
    {
      "id": 4503,
      "name": "עין זיוון",
      "name_eng": "EN ZIWAN"
    },
    {
      "id": 622,
      "name": "עין צורים",
      "name_eng": "EN ZURIM"
    },
    {
      "id": 871,
      "name": "עינת",
      "name_eng": "ENAT"
    },
    {
      "id": 3712,
      "name": "ענב",
      "name_eng": "ENAV"
    },
    {
      "id": 714,
      "name": "ארז",
      "name_eng": "EREZ"
    },
    {
      "id": 71,
      "name": "אשבול",
      "name_eng": "ESHBOL"
    },
    {
      "id": 2021,
      "name": "אשל הנשיא",
      "name_eng": "ESHEL HANASI"
    },
    {
      "id": 1188,
      "name": "אשחר",
      "name_eng": "ESHHAR"
    },
    {
      "id": 3722,
      "name": "אשכולות",
      "name_eng": "ESHKOLOT"
    },
    {
      "id": 740,
      "name": "אשתאול",
      "name_eng": "ESHTA'OL"
    },
    {
      "id": 37,
      "name": "איתן",
      "name_eng": "ETAN"
    },
    {
      "id": 886,
      "name": "איתנים",
      "name_eng": "ETANIM"
    },
    {
      "id": 1298,
      "name": "אתגר",
      "name_eng": "ETGAR"
    },
    {
      "id": 1081,
      "name": "אבן מנחם",
      "name_eng": "EVEN MENAHEM"
    },
    {
      "id": 783,
      "name": "אבן ספיר",
      "name_eng": "EVEN SAPPIR"
    },
    {
      "id": 400,
      "name": "אבן שמואל",
      "name_eng": "EVEN SHEMU'EL"
    },
    {
      "id": 182,
      "name": "אבן יהודה",
      "name_eng": "EVEN YEHUDA"
    },
    {
      "id": 369,
      "name": "גלעד (אבן יצחק)",
      "name_eng": "EVEN YIZHAQ(GAL'ED)"
    },
    {
      "id": 376,
      "name": "עברון",
      "name_eng": "EVRON"
    },
    {
      "id": 716,
      "name": "אייל",
      "name_eng": "EYAL"
    },
    {
      "id": 1149,
      "name": "עזר",
      "name_eng": "EZER"
    },
    {
      "id": 328,
      "name": "עזוז",
      "name_eng": "EZUZ"
    },
    {
      "id": 535,
      "name": "פסוטה",
      "name_eng": "FASSUTA"
    },
    {
      "id": 537,
      "name": "פוריידיס",
      "name_eng": "FUREIDIS"
    },
    {
      "id": 842,
      "name": "געש",
      "name_eng": "GA'ASH"
    },
    {
      "id": 463,
      "name": "געתון",
      "name_eng": "GA'TON"
    },
    {
      "id": 145,
      "name": "גדיש",
      "name_eng": "GADISH"
    },
    {
      "id": 35,
      "name": "גדות",
      "name_eng": "GADOT"
    },
    {
      "id": 393,
      "name": "גלאון",
      "name_eng": "GAL'ON"
    },
    {
      "id": 1072,
      "name": "גן הדרום",
      "name_eng": "GAN HADAROM"
    },
    {
      "id": 225,
      "name": "גן השומרון",
      "name_eng": "GAN HASHOMERON"
    },
    {
      "id": 239,
      "name": "גן חיים",
      "name_eng": "GAN HAYYIM"
    },
    {
      "id": 1274,
      "name": "גן נר",
      "name_eng": "GAN NER"
    },
    {
      "id": 144,
      "name": "גן שלמה",
      "name_eng": "GAN SHELOMO"
    },
    {
      "id": 72,
      "name": "גן שמואל",
      "name_eng": "GAN SHEMU'EL"
    },
    {
      "id": 311,
      "name": "גן שורק",
      "name_eng": "GAN SOREQ"
    },
    {
      "id": 166,
      "name": "גן יבנה",
      "name_eng": "GAN YAVNE"
    },
    {
      "id": 734,
      "name": "גן יאשיה",
      "name_eng": "GAN YOSHIYYA"
    },
    {
      "id": 218,
      "name": "גני עם",
      "name_eng": "GANNE AM"
    },
    {
      "id": 1103,
      "name": "גני הדר",
      "name_eng": "GANNE HADAR"
    },
    {
      "id": 3823,
      "name": "גני מודיעין",
      "name_eng": "GANNE MODIIN"
    },
    {
      "id": 1371,
      "name": "גני טל",
      "name_eng": "GANNE TAL"
    },
    {
      "id": 229,
      "name": "גני תקווה",
      "name_eng": "GANNE TIQWA"
    },
    {
      "id": 862,
      "name": "גני יוחנן",
      "name_eng": "GANNE YOHANAN"
    },
    {
      "id": 836,
      "name": "גנות",
      "name_eng": "GANNOT"
    },
    {
      "id": 549,
      "name": "גנות הדר",
      "name_eng": "GANNOT HADAR"
    },
    {
      "id": 128,
      "name": "גת רימון",
      "name_eng": "GAT RIMMON"
    },
    {
      "id": 340,
      "name": "גת (קיבוץ)",
      "name_eng": "GAT(QIBBUZ)"
    },
    {
      "id": 457,
      "name": "גזית",
      "name_eng": "GAZIT"
    },
    {
      "id": 706,
      "name": "גיאה",
      "name_eng": "GE'A"
    },
    {
      "id": 853,
      "name": "גאליה",
      "name_eng": "GE'ALYA"
    },
    {
      "id": 872,
      "name": "גאולי תימן",
      "name_eng": "GE'ULE TEMAN"
    },
    {
      "id": 379,
      "name": "גאולים",
      "name_eng": "GE'ULIM"
    },
    {
      "id": 2550,
      "name": "גדרה",
      "name_eng": "GEDERA"
    },
    {
      "id": 39,
      "name": "גפן",
      "name_eng": "GEFEN"
    },
    {
      "id": 346,
      "name": "גליל ים",
      "name_eng": "GELIL YAM"
    },
    {
      "id": 1129,
      "name": "גרופית",
      "name_eng": "GEROFIT"
    },
    {
      "id": 305,
      "name": "גשר",
      "name_eng": "GESHER"
    },
    {
      "id": 574,
      "name": "גשר הזיו",
      "name_eng": "GESHER HAZIW"
    },
    {
      "id": 4022,
      "name": "גשור",
      "name_eng": "GESHUR"
    },
    {
      "id": 86,
      "name": "גבע",
      "name_eng": "GEVA"
    },
    {
      "id": 683,
      "name": "גבע כרמל",
      "name_eng": "GEVA KARMEL"
    },
    {
      "id": 3763,
      "name": "גבע בנימין",
      "name_eng": "GEVA BINYAMIN"
    },
    {
      "id": 1344,
      "name": "גבעות בר",
      "name_eng": "GEVA'OT BAR"
    },
    {
      "id": 342,
      "name": "גברעם",
      "name_eng": "GEVAR'AM"
    },
    {
      "id": 133,
      "name": "גבת",
      "name_eng": "GEVAT"
    },
    {
      "id": 424,
      "name": "גבים",
      "name_eng": "GEVIM"
    },
    {
      "id": 352,
      "name": "גבולות",
      "name_eng": "GEVULOT"
    },
    {
      "id": 370,
      "name": "גזר",
      "name_eng": "GEZER"
    },
    {
      "id": 4501,
      "name": "ע'ג'ר",
      "name_eng": "GHAJAR"
    },
    {
      "id": 196,
      "name": "גיבתון",
      "name_eng": "GIBBETON"
    },
    {
      "id": 442,
      "name": "גדעונה",
      "name_eng": "GID'ONA"
    },
    {
      "id": 736,
      "name": "גילת",
      "name_eng": "GILAT"
    },
    {
      "id": 3606,
      "name": "גלגל",
      "name_eng": "GILGAL"
    },
    {
      "id": 1204,
      "name": "גילון",
      "name_eng": "GILON"
    },
    {
      "id": 745,
      "name": "גמזו",
      "name_eng": "GIMZO"
    },
    {
      "id": 863,
      "name": "גינתון",
      "name_eng": "GINNATON"
    },
    {
      "id": 92,
      "name": "גיניגר",
      "name_eng": "GINNEGAR"
    },
    {
      "id": 262,
      "name": "גינוסר",
      "name_eng": "GINNOSAR"
    },
    {
      "id": 1206,
      "name": "גיתה",
      "name_eng": "GITTA"
    },
    {
      "id": 3613,
      "name": "גיתית",
      "name_eng": "GITTIT"
    },
    {
      "id": 1293,
      "name": "גבעת אבני",
      "name_eng": "GIV'AT AVNI"
    },
    {
      "id": 147,
      "name": "גבעת ברנר",
      "name_eng": "GIV'AT BRENNER"
    },
    {
      "id": 1288,
      "name": "גבעת אלה",
      "name_eng": "GIV'AT ELA"
    },
    {
      "id": 870,
      "name": "גבעת השלושה",
      "name_eng": "GIV'AT HASHELOSHA"
    },
    {
      "id": 2018,
      "name": "גבעת חיים (איחוד)",
      "name_eng": "GIV'AT HAYYIM (IHUD)"
    },
    {
      "id": 173,
      "name": "גבעת חיים (מאוחד)",
      "name_eng": "GIV'AT HAYYIM(ME'UHA"
    },
    {
      "id": 207,
      "name": "גבעת ח\"ן",
      "name_eng": "GIV'AT HEN"
    },
    {
      "id": 802,
      "name": "גבעת כ\"ח",
      "name_eng": "GIV'AT KOAH"
    },
    {
      "id": 360,
      "name": "גבעת ניל\"י",
      "name_eng": "GIV'AT NILI"
    },
    {
      "id": 703,
      "name": "גבעת עוז",
      "name_eng": "GIV'AT OZ"
    },
    {
      "id": 1077,
      "name": "גבעת שפירא",
      "name_eng": "GIV'AT SHAPPIRA"
    },
    {
      "id": 566,
      "name": "גבעת שמש",
      "name_eng": "GIV'AT SHEMESH"
    },
    {
      "id": 681,
      "name": "גבעת שמואל",
      "name_eng": "GIV'AT SHEMU'EL"
    },
    {
      "id": 787,
      "name": "גבעת יערים",
      "name_eng": "GIV'AT YE'ARIM"
    },
    {
      "id": 919,
      "name": "גבעת ישעיהו",
      "name_eng": "GIV'AT YESHA'YAHU"
    },
    {
      "id": 4021,
      "name": "גבעת יואב",
      "name_eng": "GIV'AT YO'AV"
    },
    {
      "id": 3730,
      "name": "גבעת זאב",
      "name_eng": "GIV'AT ZE'EV"
    },
    {
      "id": 6300,
      "name": "גבעתיים",
      "name_eng": "GIV'ATAYIM"
    },
    {
      "id": 793,
      "name": "גבעתי",
      "name_eng": "GIV'ATI"
    },
    {
      "id": 2014,
      "name": "גבעולים",
      "name_eng": "GIV'OLIM"
    },
    {
      "id": 3644,
      "name": "גבעון החדשה",
      "name_eng": "GIV'ON HAHADASHA"
    },
    {
      "id": 1362,
      "name": "גבעות עדן",
      "name_eng": "GIV'OT EDEN"
    },
    {
      "id": 1043,
      "name": "גיזו",
      "name_eng": "GIZO"
    },
    {
      "id": 852,
      "name": "גונן",
      "name_eng": "GONEN"
    },
    {
      "id": 755,
      "name": "גורן",
      "name_eng": "GOREN"
    },
    {
      "id": 1219,
      "name": "גורנות הגליל",
      "name_eng": "GORNOT HAGALIL"
    },
    {
      "id": 675,
      "name": "הבונים",
      "name_eng": "HABONIM"
    },
    {
      "id": 4026,
      "name": "חד-נס",
      "name_eng": "HAD-NES"
    },
    {
      "id": 191,
      "name": "הדר עם",
      "name_eng": "HADAR AM"
    },
    {
      "id": 6500,
      "name": "חדרה",
      "name_eng": "HADERA"
    },
    {
      "id": 618,
      "name": "חדיד",
      "name_eng": "HADID"
    },
    {
      "id": 363,
      "name": "חפץ חיים",
      "name_eng": "HAFEZ HAYYIM"
    },
    {
      "id": 3764,
      "name": "חגי",
      "name_eng": "HAGGAI"
    },
    {
      "id": 717,
      "name": "חגור",
      "name_eng": "HAGOR"
    },
    {
      "id": 356,
      "name": "הגושרים",
      "name_eng": "HAGOSHERIM"
    },
    {
      "id": 434,
      "name": "החותרים",
      "name_eng": "HAHOTERIM"
    },
    {
      "id": 4000,
      "name": "חיפה",
      "name_eng": "HAIFA"
    },
    {
      "id": 1272,
      "name": "חלוץ",
      "name_eng": "HALUZ"
    },
    {
      "id": 377,
      "name": "המעפיל",
      "name_eng": "HAMA'PIL"
    },
    {
      "id": 343,
      "name": "חמדיה",
      "name_eng": "HAMADYA"
    },
    {
      "id": 993,
      "name": "חמאם",
      "name_eng": "HAMAM"
    },
    {
      "id": 3609,
      "name": "חמרה",
      "name_eng": "HAMRA"
    },
    {
      "id": 280,
      "name": "חניתה",
      "name_eng": "HANITA"
    },
    {
      "id": 1257,
      "name": "חנתון",
      "name_eng": "HANNATON"
    },
    {
      "id": 807,
      "name": "חניאל",
      "name_eng": "HANNI'EL"
    },
    {
      "id": 423,
      "name": "העוגן",
      "name_eng": "HAOGEN"
    },
    {
      "id": 702,
      "name": "האון",
      "name_eng": "HAON"
    },
    {
      "id": 3769,
      "name": "הר אדר",
      "name_eng": "HAR ADAR"
    },
    {
      "id": 1261,
      "name": "הר עמשא",
      "name_eng": "HAR AMASA"
    },
    {
      "id": 3603,
      "name": "הר גילה",
      "name_eng": "HAR GILLO"
    },
    {
      "id": 464,
      "name": "הראל",
      "name_eng": "HAR'EL"
    },
    {
      "id": 1203,
      "name": "הררית",
      "name_eng": "HARARIT"
    },
    {
      "id": 1209,
      "name": "חרשים",
      "name_eng": "HARASHIM"
    },
    {
      "id": 1249,
      "name": "הרדוף",
      "name_eng": "HARDUF"
    },
    {
      "id": 1247,
      "name": "חריש",
      "name_eng": "HARISH"
    },
    {
      "id": 1024,
      "name": "חרוצים",
      "name_eng": "HARUZIM"
    },
    {
      "id": 3770,
      "name": "חשמונאים",
      "name_eng": "HASHMONA'IM"
    },
    {
      "id": 677,
      "name": "הסוללים",
      "name_eng": "HASOLELIM"
    },
    {
      "id": 4005,
      "name": "חספין",
      "name_eng": "HASPIN"
    },
    {
      "id": 235,
      "name": "חבצלת השרון",
      "name_eng": "HAVAZZELET HASHARON"
    },
    {
      "id": 1169,
      "name": "הוואשלה (שבט)",
      "name_eng": "HAWASHLA"
    },
    {
      "id": 684,
      "name": "היוגב",
      "name_eng": "HAYOGEV"
    },
    {
      "id": 700,
      "name": "חצב",
      "name_eng": "HAZAV"
    },
    {
      "id": 397,
      "name": "חצרים",
      "name_eng": "HAZERIM"
    },
    {
      "id": 13,
      "name": "חצבה",
      "name_eng": "HAZEVA"
    },
    {
      "id": 1047,
      "name": "חזון",
      "name_eng": "HAZON"
    },
    {
      "id": 2034,
      "name": "חצור הגלילית",
      "name_eng": "HAZOR HAGELILIT"
    },
    {
      "id": 406,
      "name": "חצור-אשדוד",
      "name_eng": "HAZOR-ASHDOD"
    },
    {
      "id": 307,
      "name": "הזורעים",
      "name_eng": "HAZORE'IM"
    },
    {
      "id": 250,
      "name": "הזורע",
      "name_eng": "HAZOREA"
    },
    {
      "id": 90,
      "name": "חפצי-בה",
      "name_eng": "HEFZI-BAH"
    },
    {
      "id": 820,
      "name": "חלץ",
      "name_eng": "HELEZ"
    },
    {
      "id": 801,
      "name": "חמד",
      "name_eng": "HEMED"
    },
    {
      "id": 422,
      "name": "חרב לאת",
      "name_eng": "HEREV LE'ET"
    },
    {
      "id": 3717,
      "name": "חרמש",
      "name_eng": "HERMESH"
    },
    {
      "id": 162,
      "name": "חירות",
      "name_eng": "HERUT"
    },
    {
      "id": 6400,
      "name": "הרצליה",
      "name_eng": "HERZELIYYA"
    },
    {
      "id": 1110,
      "name": "חבר",
      "name_eng": "HEVER"
    },
    {
      "id": 219,
      "name": "חיבת ציון",
      "name_eng": "HIBBAT ZIYYON"
    },
    {
      "id": 1208,
      "name": "הילה",
      "name_eng": "HILLA"
    },
    {
      "id": 3643,
      "name": "חיננית",
      "name_eng": "HINNANIT"
    },
    {
      "id": 9700,
      "name": "הוד השרון",
      "name_eng": "HOD HASHARON"
    },
    {
      "id": 1322,
      "name": "הודיות",
      "name_eng": "HODAYOT"
    },
    {
      "id": 726,
      "name": "הודיה",
      "name_eng": "HODIYYA"
    },
    {
      "id": 115,
      "name": "חופית",
      "name_eng": "HOFIT"
    },
    {
      "id": 205,
      "name": "חגלה",
      "name_eng": "HOGLA"
    },
    {
      "id": 1239,
      "name": "חולית",
      "name_eng": "HOLIT"
    },
    {
      "id": 6600,
      "name": "חולון",
      "name_eng": "HOLON"
    },
    {
      "id": 355,
      "name": "חורשים",
      "name_eng": "HORESHIM"
    },
    {
      "id": 662,
      "name": "חוסן",
      "name_eng": "HOSEN"
    },
    {
      "id": 1186,
      "name": "הושעיה",
      "name_eng": "HOSHA'AYA"
    },
    {
      "id": 948,
      "name": "חוג'ייראת (ד'הרה)",
      "name_eng": "HUJEIRAT (DAHRA)"
    },
    {
      "id": 253,
      "name": "חולתה",
      "name_eng": "HULATA"
    },
    {
      "id": 160,
      "name": "חולדה",
      "name_eng": "HULDA"
    },
    {
      "id": 374,
      "name": "חוקוק",
      "name_eng": "HUQOQ"
    },
    {
      "id": 1303,
      "name": "חורה",
      "name_eng": "HURA"
    },
    {
      "id": 496,
      "name": "חורפיש",
      "name_eng": "HURFEISH"
    },
    {
      "id": 1332,
      "name": "חוסנייה",
      "name_eng": "HUSSNIYYA"
    },
    {
      "id": 956,
      "name": "הוזייל (שבט)",
      "name_eng": "HUZAYYEL"
    },
    {
      "id": 529,
      "name": "אעבלין",
      "name_eng": "I'BILLIN"
    },
    {
      "id": 338,
      "name": "איבים",
      "name_eng": "IBBIM"
    },
    {
      "id": 652,
      "name": "אבטין",
      "name_eng": "IBTIN"
    },
    {
      "id": 1175,
      "name": "עידן",
      "name_eng": "IDDAN"
    },
    {
      "id": 478,
      "name": "אכסאל",
      "name_eng": "IKSAL"
    },
    {
      "id": 49,
      "name": "אילניה",
      "name_eng": "ILANIYYA"
    },
    {
      "id": 511,
      "name": "עילוט",
      "name_eng": "ILUT"
    },
    {
      "id": 3660,
      "name": "עמנואל",
      "name_eng": "IMMANU'EL"
    },
    {
      "id": 1187,
      "name": "עיר אובות",
      "name_eng": "IR OVOT"
    },
    {
      "id": 1336,
      "name": "אירוס",
      "name_eng": "IRUS"
    },
    {
      "id": 534,
      "name": "עספיא",
      "name_eng": "ISIFYA"
    },
    {
      "id": 3762,
      "name": "איתמר",
      "name_eng": "ITAMAR"
    },
    {
      "id": 628,
      "name": "ג'ת",
      "name_eng": "JAAT"
    },
    {
      "id": 627,
      "name": "ג'לג'וליה",
      "name_eng": "JALJULYE"
    },
    {
      "id": 3000,
      "name": "ירושלים",
      "name_eng": "JERUSALEM"
    },
    {
      "id": 487,
      "name": "ג'ש (גוש חלב)",
      "name_eng": "JISH(GUSH HALAV)"
    },
    {
      "id": 541,
      "name": "ג'סר א-זרקא",
      "name_eng": "JISR AZ-ZARQA"
    },
    {
      "id": 1292,
      "name": "ג'דיידה-מכר",
      "name_eng": "JUDEIDE-MAKER"
    },
    {
      "id": 485,
      "name": "ג'ולס",
      "name_eng": "JULIS"
    },
    {
      "id": 976,
      "name": "ג'נאביב (שבט)",
      "name_eng": "JUNNABIB"
    },
    {
      "id": 978,
      "name": "כעביה-טבאש-חג'אג'רה",
      "name_eng": "KA'ABIYYE-TABBASH-HA"
    },
    {
      "id": 576,
      "name": "כברי",
      "name_eng": "KABRI"
    },
    {
      "id": 504,
      "name": "כאבול",
      "name_eng": "KABUL"
    },
    {
      "id": 1338,
      "name": "כדיתה",
      "name_eng": "KADDITA"
    },
    {
      "id": 371,
      "name": "כדורי",
      "name_eng": "KADOORIE"
    },
    {
      "id": 633,
      "name": "כפר ברא",
      "name_eng": "KAFAR BARA"
    },
    {
      "id": 508,
      "name": "כפר כמא",
      "name_eng": "KAFAR KAMA"
    },
    {
      "id": 509,
      "name": "כפר כנא",
      "name_eng": "KAFAR KANNA"
    },
    {
      "id": 510,
      "name": "כפר מנדא",
      "name_eng": "KAFAR MANDA"
    },
    {
      "id": 512,
      "name": "כפר מצר",
      "name_eng": "KAFAR MISR"
    },
    {
      "id": 654,
      "name": "כפר קרע",
      "name_eng": "KAFAR QARA"
    },
    {
      "id": 634,
      "name": "כפר קאסם",
      "name_eng": "KAFAR QASEM"
    },
    {
      "id": 507,
      "name": "כפר יאסיף",
      "name_eng": "KAFAR YASIF"
    },
    {
      "id": 1210,
      "name": "כחל",
      "name_eng": "KAHAL"
    },
    {
      "id": 1229,
      "name": "כלנית",
      "name_eng": "KALLANIT"
    },
    {
      "id": 1201,
      "name": "כמון",
      "name_eng": "KAMMON"
    },
    {
      "id": 4028,
      "name": "כנף",
      "name_eng": "KANAF"
    },
    {
      "id": 2006,
      "name": "כנות",
      "name_eng": "KANNOT"
    },
    {
      "id": 505,
      "name": "כאוכב אבו אל-היג'א",
      "name_eng": "KAOKAB ABU AL-HIJA"
    },
    {
      "id": 38,
      "name": "כרי דשא",
      "name_eng": "KARE DESHE"
    },
    {
      "id": 1285,
      "name": "כרכום",
      "name_eng": "KARKOM"
    },
    {
      "id": 1374,
      "name": "כרמי קטיף",
      "name_eng": "KARME QATIF"
    },
    {
      "id": 1264,
      "name": "כרמי יוסף",
      "name_eng": "KARME YOSEF"
    },
    {
      "id": 3766,
      "name": "כרמי צור",
      "name_eng": "KARME ZUR"
    },
    {
      "id": 3656,
      "name": "כרמל",
      "name_eng": "KARMEL"
    },
    {
      "id": 1139,
      "name": "כרמיאל",
      "name_eng": "KARMI'EL"
    },
    {
      "id": 768,
      "name": "כרמיה",
      "name_eng": "KARMIYYA"
    },
    {
      "id": 3638,
      "name": "כפר אדומים",
      "name_eng": "KEFAR ADUMMIM"
    },
    {
      "id": 690,
      "name": "כפר אחים",
      "name_eng": "KEFAR AHIM"
    },
    {
      "id": 857,
      "name": "כפר אביב",
      "name_eng": "KEFAR AVIV"
    },
    {
      "id": 875,
      "name": "כפר עבודה",
      "name_eng": "KEFAR AVODA"
    },
    {
      "id": 845,
      "name": "כפר עזה",
      "name_eng": "KEFAR AZZA"
    },
    {
      "id": 132,
      "name": "כפר ברוך",
      "name_eng": "KEFAR BARUKH"
    },
    {
      "id": 220,
      "name": "כפר ביאליק",
      "name_eng": "KEFAR BIALIK"
    },
    {
      "id": 177,
      "name": "כפר ביל\"ו",
      "name_eng": "KEFAR BILU"
    },
    {
      "id": 2010,
      "name": "כפר בן נון",
      "name_eng": "KEFAR BIN NUN"
    },
    {
      "id": 357,
      "name": "כפר בלום",
      "name_eng": "KEFAR BLUM"
    },
    {
      "id": 707,
      "name": "כפר דניאל",
      "name_eng": "KEFAR DANIYYEL"
    },
    {
      "id": 3488,
      "name": "כפר עציון",
      "name_eng": "KEFAR EZYON"
    },
    {
      "id": 427,
      "name": "כפר גלים",
      "name_eng": "KEFAR GALLIM"
    },
    {
      "id": 106,
      "name": "כפר גדעון",
      "name_eng": "KEFAR GID'ON"
    },
    {
      "id": 76,
      "name": "כפר גלעדי",
      "name_eng": "KEFAR GIL'ADI"
    },
    {
      "id": 310,
      "name": "כפר גליקסון",
      "name_eng": "KEFAR GLIKSON"
    },
    {
      "id": 696,
      "name": "כפר חב\"ד",
      "name_eng": "KEFAR HABAD"
    },
    {
      "id": 192,
      "name": "כפר החורש",
      "name_eng": "KEFAR HAHORESH"
    },
    {
      "id": 254,
      "name": "כפר המכבי",
      "name_eng": "KEFAR HAMAKKABI"
    },
    {
      "id": 582,
      "name": "כפר הנגיד",
      "name_eng": "KEFAR HANAGID"
    },
    {
      "id": 1297,
      "name": "כפר חנניה",
      "name_eng": "KEFAR HANANYA"
    },
    {
      "id": 443,
      "name": "כפר הנשיא",
      "name_eng": "KEFAR HANASI"
    },
    {
      "id": 890,
      "name": "כפר הנוער הדתי",
      "name_eng": "KEFAR HANO'AR HADATI"
    },
    {
      "id": 3796,
      "name": "כפר האורנים",
      "name_eng": "KEFAR HAORANIM"
    },
    {
      "id": 888,
      "name": "כפר הרי\"ף",
      "name_eng": "KEFAR HARIF"
    },
    {
      "id": 217,
      "name": "כפר הרא\"ה",
      "name_eng": "KEFAR HARO'E"
    },
    {
      "id": 4004,
      "name": "כפר חרוב",
      "name_eng": "KEFAR HARUV"
    },
    {
      "id": 112,
      "name": "כפר חסידים א'",
      "name_eng": "KEFAR HASIDIM ALEF"
    },
    {
      "id": 889,
      "name": "כפר חסידים ב'",
      "name_eng": "KEFAR HASIDIM BET"
    },
    {
      "id": 193,
      "name": "כפר חיים",
      "name_eng": "KEFAR HAYYIM"
    },
    {
      "id": 187,
      "name": "כפר הס",
      "name_eng": "KEFAR HESS"
    },
    {
      "id": 255,
      "name": "כפר חיטים",
      "name_eng": "KEFAR HITTIM"
    },
    {
      "id": 609,
      "name": "כפר חושן",
      "name_eng": "KEFAR HOSHEN"
    },
    {
      "id": 388,
      "name": "כפר קיש",
      "name_eng": "KEFAR KISH"
    },
    {
      "id": 98,
      "name": "כפר מל\"ל",
      "name_eng": "KEFAR MALAL"
    },
    {
      "id": 297,
      "name": "כפר מסריק",
      "name_eng": "KEFAR MASARYK"
    },
    {
      "id": 1095,
      "name": "כפר מימון",
      "name_eng": "KEFAR MAYMON"
    },
    {
      "id": 274,
      "name": "כפר מנחם",
      "name_eng": "KEFAR MENAHEM"
    },
    {
      "id": 387,
      "name": "כפר מונש",
      "name_eng": "KEFAR MONASH"
    },
    {
      "id": 764,
      "name": "כפר מרדכי",
      "name_eng": "KEFAR MORDEKHAY"
    },
    {
      "id": 316,
      "name": "כפר נטר",
      "name_eng": "KEFAR NETTER"
    },
    {
      "id": 189,
      "name": "כפר פינס",
      "name_eng": "KEFAR PINES"
    },
    {
      "id": 579,
      "name": "כפר ראש הנקרה",
      "name_eng": "KEFAR ROSH HANIQRA"
    },
    {
      "id": 1130,
      "name": "כפר רוזנואלד (זרעית)",
      "name_eng": "KEFAR ROZENWALD(ZAR."
    },
    {
      "id": 295,
      "name": "כפר רופין",
      "name_eng": "KEFAR RUPPIN"
    },
    {
      "id": 1166,
      "name": "כפר רות",
      "name_eng": "KEFAR RUT"
    },
    {
      "id": 6900,
      "name": "כפר סבא",
      "name_eng": "KEFAR SAVA"
    },
    {
      "id": 605,
      "name": "כפר שמאי",
      "name_eng": "KEFAR SHAMMAY"
    },
    {
      "id": 267,
      "name": "כפר שמריהו",
      "name_eng": "KEFAR SHEMARYAHU"
    },
    {
      "id": 743,
      "name": "כפר שמואל",
      "name_eng": "KEFAR SHEMU'EL"
    },
    {
      "id": 107,
      "name": "כפר סילבר",
      "name_eng": "KEFAR SILVER"
    },
    {
      "id": 249,
      "name": "כפר סירקין",
      "name_eng": "KEFAR SIRKIN"
    },
    {
      "id": 345,
      "name": "כפר סאלד",
      "name_eng": "KEFAR SZOLD"
    },
    {
      "id": 3572,
      "name": "כפר תפוח",
      "name_eng": "KEFAR TAPPUAH"
    },
    {
      "id": 47,
      "name": "כפר תבור",
      "name_eng": "KEFAR TAVOR"
    },
    {
      "id": 673,
      "name": "כפר טרומן",
      "name_eng": "KEFAR TRUMAN"
    },
    {
      "id": 364,
      "name": "כפר אוריה",
      "name_eng": "KEFAR URIYYA"
    },
    {
      "id": 190,
      "name": "כפר ויתקין",
      "name_eng": "KEFAR VITKIN"
    },
    {
      "id": 320,
      "name": "כפר ורבורג",
      "name_eng": "KEFAR WARBURG"
    },
    {
      "id": 1263,
      "name": "כפר ורדים",
      "name_eng": "KEFAR WERADIM"
    },
    {
      "id": 170,
      "name": "כפר יעבץ",
      "name_eng": "KEFAR YA'BEZ"
    },
    {
      "id": 85,
      "name": "כפר יחזקאל",
      "name_eng": "KEFAR YEHEZQEL"
    },
    {
      "id": 140,
      "name": "כפר יהושע",
      "name_eng": "KEFAR YEHOSHUA"
    },
    {
      "id": 168,
      "name": "כפר יונה",
      "name_eng": "KEFAR YONA"
    },
    {
      "id": 786,
      "name": "כפר זיתים",
      "name_eng": "KEFAR ZETIM"
    },
    {
      "id": 1325,
      "name": "כפר זוהרים",
      "name_eng": "KEFAR ZOHARIM"
    },
    {
      "id": 1183,
      "name": "כליל",
      "name_eng": "KELIL"
    },
    {
      "id": 1291,
      "name": "כמהין",
      "name_eng": "KEMEHIN"
    },
    {
      "id": 1198,
      "name": "כרמים",
      "name_eng": "KERAMIM"
    },
    {
      "id": 88,
      "name": "כרם בן שמן",
      "name_eng": "KEREM BEN SHEMEN"
    },
    {
      "id": 664,
      "name": "כרם בן זמרה",
      "name_eng": "KEREM BEN ZIMRA"
    },
    {
      "id": 580,
      "name": "כרם מהר\"ל",
      "name_eng": "KEREM MAHARAL"
    },
    {
      "id": 1085,
      "name": "כרם שלום",
      "name_eng": "KEREM SHALOM"
    },
    {
      "id": 1094,
      "name": "כרם יבנה (ישיבה)",
      "name_eng": "KEREM YAVNE(YESHIVA)"
    },
    {
      "id": 859,
      "name": "כסלון",
      "name_eng": "KESALON"
    },
    {
      "id": 1321,
      "name": "ח'ואלד",
      "name_eng": "KHAWALED"
    },
    {
      "id": 986,
      "name": "ח'ואלד (שבט)",
      "name_eng": "KHAWALED"
    },
    {
      "id": 63,
      "name": "כנרת (מושבה)",
      "name_eng": "KINNERET(MOSHAVA)"
    },
    {
      "id": 57,
      "name": "כנרת (קבוצה)",
      "name_eng": "KINNERET(QEVUZA)"
    },
    {
      "id": 1153,
      "name": "כישור",
      "name_eng": "KISHOR"
    },
    {
      "id": 1296,
      "name": "כסרא-סמיע",
      "name_eng": "KISRA-SUMEI"
    },
    {
      "id": 840,
      "name": "כיסופים",
      "name_eng": "KISSUFIM"
    },
    {
      "id": 1367,
      "name": "כחלה",
      "name_eng": "KOCHLEA"
    },
    {
      "id": 3564,
      "name": "כוכב השחר",
      "name_eng": "KOKHAV HASHAHAR"
    },
    {
      "id": 824,
      "name": "כוכב מיכאל",
      "name_eng": "KOKHAV MIKHA'EL"
    },
    {
      "id": 3779,
      "name": "כוכב יעקב",
      "name_eng": "KOKHAV YA'AQOV"
    },
    {
      "id": 1224,
      "name": "כוכב יאיר",
      "name_eng": "KOKHAV YA'IR"
    },
    {
      "id": 1252,
      "name": "כורזים",
      "name_eng": "KORAZIM"
    },
    {
      "id": 1059,
      "name": "כסיפה",
      "name_eng": "KUSEIFE"
    },
    {
      "id": 2023,
      "name": "להב",
      "name_eng": "LAHAV"
    },
    {
      "id": 380,
      "name": "להבות הבשן",
      "name_eng": "LAHAVOT HABASHAN"
    },
    {
      "id": 715,
      "name": "להבות חביבה",
      "name_eng": "LAHAVOT HAVIVA"
    },
    {
      "id": 24,
      "name": "לכיש",
      "name_eng": "LAKHISH"
    },
    {
      "id": 1310,
      "name": "לפיד",
      "name_eng": "LAPPID"
    },
    {
      "id": 1173,
      "name": "לפידות",
      "name_eng": "LAPPIDOT"
    },
    {
      "id": 1060,
      "name": "לקיה",
      "name_eng": "LAQYE"
    },
    {
      "id": 585,
      "name": "לביא",
      "name_eng": "LAVI"
    },
    {
      "id": 1207,
      "name": "לבון",
      "name_eng": "LAVON"
    },
    {
      "id": 1271,
      "name": "להבים",
      "name_eng": "LEHAVIM"
    },
    {
      "id": 1114,
      "name": "שריגים (לי-און)",
      "name_eng": "LI-ON"
    },
    {
      "id": 674,
      "name": "לימן",
      "name_eng": "LIMAN"
    },
    {
      "id": 1230,
      "name": "לבנים",
      "name_eng": "LIVNIM"
    },
    {
      "id": 7000,
      "name": "לוד",
      "name_eng": "LOD"
    },
    {
      "id": 595,
      "name": "לוחמי הגיטאות",
      "name_eng": "LOHAME HAGETA'OT"
    },
    {
      "id": 1255,
      "name": "לוטן",
      "name_eng": "LOTAN"
    },
    {
      "id": 1171,
      "name": "לוטם",
      "name_eng": "LOTEM"
    },
    {
      "id": 52,
      "name": "לוזית",
      "name_eng": "LUZIT"
    },
    {
      "id": 678,
      "name": "מעגן",
      "name_eng": "MA'AGAN"
    },
    {
      "id": 694,
      "name": "מעגן מיכאל",
      "name_eng": "MA'AGAN MIKHA'EL"
    },
    {
      "id": 3616,
      "name": "מעלה אדומים",
      "name_eng": "MA'ALE ADUMMIM"
    },
    {
      "id": 3653,
      "name": "מעלה עמוס",
      "name_eng": "MA'ALE AMOS"
    },
    {
      "id": 3608,
      "name": "מעלה אפרים",
      "name_eng": "MA'ALE EFRAYIM"
    },
    {
      "id": 4008,
      "name": "מעלה גמלא",
      "name_eng": "MA'ALE GAMLA"
    },
    {
      "id": 1127,
      "name": "מעלה גלבוע",
      "name_eng": "MA'ALE GILBOA"
    },
    {
      "id": 286,
      "name": "מעלה החמישה",
      "name_eng": "MA'ALE HAHAMISHA"
    },
    {
      "id": 1327,
      "name": "מעלה עירון",
      "name_eng": "MA'ALE IRON"
    },
    {
      "id": 3752,
      "name": "מעלה לבונה",
      "name_eng": "MA'ALE LEVONA"
    },
    {
      "id": 3651,
      "name": "מעלה מכמש",
      "name_eng": "MA'ALE MIKHMAS"
    },
    {
      "id": 1063,
      "name": "מעלות-תרשיחא",
      "name_eng": "MA'ALOT-TARSHIHA"
    },
    {
      "id": 344,
      "name": "מענית",
      "name_eng": "MA'ANIT"
    },
    {
      "id": 230,
      "name": "מעש",
      "name_eng": "MA'AS"
    },
    {
      "id": 197,
      "name": "מעברות",
      "name_eng": "MA'BAROT"
    },
    {
      "id": 1082,
      "name": "מעגלים",
      "name_eng": "MA'GALIM"
    },
    {
      "id": 3657,
      "name": "מעון",
      "name_eng": "MA'ON"
    },
    {
      "id": 2055,
      "name": "מאור",
      "name_eng": "MA'OR"
    },
    {
      "id": 272,
      "name": "מעוז חיים",
      "name_eng": "MA'OZ HAYYIM"
    },
    {
      "id": 416,
      "name": "מעין ברוך",
      "name_eng": "MA'YAN BARUKH"
    },
    {
      "id": 290,
      "name": "מעין צבי",
      "name_eng": "MA'YAN ZEVI"
    },
    {
      "id": 1080,
      "name": "מבועים",
      "name_eng": "MABBU'IM"
    },
    {
      "id": 695,
      "name": "מגן",
      "name_eng": "MAGEN"
    },
    {
      "id": 1155,
      "name": "מגן שאול",
      "name_eng": "MAGEN SHA'UL"
    },
    {
      "id": 375,
      "name": "מגל",
      "name_eng": "MAGGAL"
    },
    {
      "id": 722,
      "name": "מגשימים",
      "name_eng": "MAGSHIMIM"
    },
    {
      "id": 308,
      "name": "מחניים",
      "name_eng": "MAHANAYIM"
    },
    {
      "id": 1262,
      "name": "צוקים",
      "name_eng": "MAHANE BILDAD"
    },
    {
      "id": 1411,
      "name": "מחנה הילה",
      "name_eng": "MAHANE HILLA"
    },
    {
      "id": 1414,
      "name": "מחנה מרים",
      "name_eng": "MAHANE MIRYAM"
    },
    {
      "id": 1418,
      "name": "מחנה טלי",
      "name_eng": "MAHANE TALI"
    },
    {
      "id": 1412,
      "name": "מחנה תל נוף",
      "name_eng": "MAHANE TEL NOF"
    },
    {
      "id": 1415,
      "name": "מחנה יפה",
      "name_eng": "MAHANE YAFA"
    },
    {
      "id": 1196,
      "name": "מחנה יתיר",
      "name_eng": "MAHANE YATTIR"
    },
    {
      "id": 1413,
      "name": "מחנה יהודית",
      "name_eng": "MAHANE YEHUDIT"
    },
    {
      "id": 1416,
      "name": "מחנה יוכבד",
      "name_eng": "MAHANE YOKHVED"
    },
    {
      "id": 776,
      "name": "מחסיה",
      "name_eng": "MAHSEYA"
    },
    {
      "id": 516,
      "name": "מג'ד אל-כרום",
      "name_eng": "MAJD AL-KURUM"
    },
    {
      "id": 4201,
      "name": "מג'דל שמס",
      "name_eng": "MAJDAL SHAMS"
    },
    {
      "id": 1343,
      "name": "מכחול",
      "name_eng": "MAKCHUL"
    },
    {
      "id": 1154,
      "name": "מלכישוע",
      "name_eng": "MALKISHUA"
    },
    {
      "id": 596,
      "name": "מלכיה",
      "name_eng": "MALKIYYA"
    },
    {
      "id": 1174,
      "name": "מנוף",
      "name_eng": "MANOF"
    },
    {
      "id": 1205,
      "name": "מנות",
      "name_eng": "MANOT"
    },
    {
      "id": 994,
      "name": "מנשית זבדה",
      "name_eng": "MANSHIYYET ZABDA"
    },
    {
      "id": 843,
      "name": "מרגליות",
      "name_eng": "MARGALIYYOT"
    },
    {
      "id": 4203,
      "name": "מסעדה",
      "name_eng": "MAS'ADE"
    },
    {
      "id": 939,
      "name": "מסעודין אל-עזאזמה",
      "name_eng": "MAS'UDIN AL-'AZAZME"
    },
    {
      "id": 421,
      "name": "משאבי שדה",
      "name_eng": "MASH'ABBE SADE"
    },
    {
      "id": 791,
      "name": "משען",
      "name_eng": "MASH'EN"
    },
    {
      "id": 3785,
      "name": "משכיות",
      "name_eng": "MASKIYYOT"
    },
    {
      "id": 748,
      "name": "מסלול",
      "name_eng": "MASLUL"
    },
    {
      "id": 1258,
      "name": "מסד",
      "name_eng": "MASSAD"
    },
    {
      "id": 263,
      "name": "מסדה",
      "name_eng": "MASSADA"
    },
    {
      "id": 3605,
      "name": "משואה",
      "name_eng": "MASSU'A"
    },
    {
      "id": 620,
      "name": "משואות יצחק",
      "name_eng": "MASSUOT YIZHAQ"
    },
    {
      "id": 822,
      "name": "מטע",
      "name_eng": "MATTA"
    },
    {
      "id": 1315,
      "name": "מתן",
      "name_eng": "MATTAN"
    },
    {
      "id": 1184,
      "name": "מתת",
      "name_eng": "MATTAT"
    },
    {
      "id": 3648,
      "name": "מתתיהו",
      "name_eng": "MATTITYAHU"
    },
    {
      "id": 573,
      "name": "מבקיעים",
      "name_eng": "MAVQI'IM"
    },
    {
      "id": 28,
      "name": "מזכרת בתיה",
      "name_eng": "MAZKERET BATYA"
    },
    {
      "id": 757,
      "name": "מצליח",
      "name_eng": "MAZLIAH"
    },
    {
      "id": 606,
      "name": "מזור",
      "name_eng": "MAZOR"
    },
    {
      "id": 517,
      "name": "מזרעה",
      "name_eng": "MAZRA'A"
    },
    {
      "id": 325,
      "name": "מצובה",
      "name_eng": "MAZZUVA"
    },
    {
      "id": 1128,
      "name": "מי עמי",
      "name_eng": "ME AMMI"
    },
    {
      "id": 102,
      "name": "מאיר שפיה",
      "name_eng": "ME'IR SHEFEYA"
    },
    {
      "id": 570,
      "name": "מעונה",
      "name_eng": "ME'ONA"
    },
    {
      "id": 668,
      "name": "מפלסים",
      "name_eng": "MEFALLESIM"
    },
    {
      "id": 689,
      "name": "מגדים",
      "name_eng": "MEGADIM"
    },
    {
      "id": 586,
      "name": "מגידו",
      "name_eng": "MEGIDDO"
    },
    {
      "id": 3599,
      "name": "מחולה",
      "name_eng": "MEHOLA"
    },
    {
      "id": 649,
      "name": "מייסר",
      "name_eng": "MEISER"
    },
    {
      "id": 3614,
      "name": "מכורה",
      "name_eng": "MEKHORA"
    },
    {
      "id": 164,
      "name": "מלאה",
      "name_eng": "MELE'A"
    },
    {
      "id": 2044,
      "name": "מלילות",
      "name_eng": "MELILOT"
    },
    {
      "id": 48,
      "name": "מנחמיה",
      "name_eng": "MENAHEMYA"
    },
    {
      "id": 347,
      "name": "מנרה",
      "name_eng": "MENNARA"
    },
    {
      "id": 2030,
      "name": "מנוחה",
      "name_eng": "MENUHA"
    },
    {
      "id": 1282,
      "name": "מירב",
      "name_eng": "MERAV"
    },
    {
      "id": 1340,
      "name": "מרחב עם",
      "name_eng": "MERHAV AM"
    },
    {
      "id": 97,
      "name": "מרחביה (מושב)",
      "name_eng": "MERHAVYA(MOSHAV)"
    },
    {
      "id": 66,
      "name": "מרחביה (קיבוץ)",
      "name_eng": "MERHAVYA(QIBBUZ)"
    },
    {
      "id": 1098,
      "name": "מרכז שפירא",
      "name_eng": "MERKAZ SHAPPIRA"
    },
    {
      "id": 4101,
      "name": "מרום גולן",
      "name_eng": "MEROM GOLAN"
    },
    {
      "id": 607,
      "name": "מירון",
      "name_eng": "MERON"
    },
    {
      "id": 731,
      "name": "מישר",
      "name_eng": "MESHAR"
    },
    {
      "id": 520,
      "name": "משהד",
      "name_eng": "MESHHED"
    },
    {
      "id": 742,
      "name": "מסילת ציון",
      "name_eng": "MESILLAT ZIYYON"
    },
    {
      "id": 298,
      "name": "מסילות",
      "name_eng": "MESILLOT"
    },
    {
      "id": 1268,
      "name": "מיתר",
      "name_eng": "METAR"
    },
    {
      "id": 2054,
      "name": "מיטב",
      "name_eng": "METAV"
    },
    {
      "id": 43,
      "name": "מטולה",
      "name_eng": "METULA"
    },
    {
      "id": 1015,
      "name": "מבשרת ציון",
      "name_eng": "MEVASSERET ZIYYON"
    },
    {
      "id": 771,
      "name": "מבוא ביתר",
      "name_eng": "MEVO BETAR"
    },
    {
      "id": 3569,
      "name": "מבוא דותן",
      "name_eng": "MEVO DOTAN"
    },
    {
      "id": 4204,
      "name": "מבוא חמה",
      "name_eng": "MEVO HAMMA"
    },
    {
      "id": 3709,
      "name": "מבוא חורון",
      "name_eng": "MEVO HORON"
    },
    {
      "id": 1141,
      "name": "מבוא מודיעים",
      "name_eng": "MEVO MODI'IM"
    },
    {
      "id": 1318,
      "name": "מבואות ים",
      "name_eng": "MEVO'OT YAM"
    },
    {
      "id": 3825,
      "name": "מבואות יריחו",
      "name_eng": "MEVO'OT YERIHO"
    },
    {
      "id": 3745,
      "name": "מצדות יהודה",
      "name_eng": "MEZADOT YEHUDA"
    },
    {
      "id": 4019,
      "name": "מיצר",
      "name_eng": "MEZAR"
    },
    {
      "id": 648,
      "name": "מצר",
      "name_eng": "MEZER"
    },
    {
      "id": 518,
      "name": "מעיליא",
      "name_eng": "MI'ELYA"
    },
    {
      "id": 2029,
      "name": "מדרך עוז",
      "name_eng": "MIDRAKH OZ"
    },
    {
      "id": 1140,
      "name": "מדרשת בן גוריון",
      "name_eng": "MIDRESHET BEN GURION"
    },
    {
      "id": 897,
      "name": "מדרשת רופין",
      "name_eng": "MIDRESHET RUPPIN"
    },
    {
      "id": 65,
      "name": "מגדל",
      "name_eng": "MIGDAL"
    },
    {
      "id": 874,
      "name": "מגדל העמק",
      "name_eng": "MIGDAL HAEMEQ"
    },
    {
      "id": 3561,
      "name": "מגדל עוז",
      "name_eng": "MIGDAL OZ"
    },
    {
      "id": 3751,
      "name": "מגדלים",
      "name_eng": "MIGDALIM"
    },
    {
      "id": 1202,
      "name": "מכמנים",
      "name_eng": "MIKHMANNIM"
    },
    {
      "id": 382,
      "name": "מכמורת",
      "name_eng": "MIKHMORET"
    },
    {
      "id": 22,
      "name": "מקווה ישראל",
      "name_eng": "MIQWE YISRA'EL"
    },
    {
      "id": 378,
      "name": "משגב עם",
      "name_eng": "MISGAV AM"
    },
    {
      "id": 765,
      "name": "משגב דב",
      "name_eng": "MISGAV DOV"
    },
    {
      "id": 670,
      "name": "משמר איילון",
      "name_eng": "MISHMAR AYYALON"
    },
    {
      "id": 563,
      "name": "משמר דוד",
      "name_eng": "MISHMAR DAWID"
    },
    {
      "id": 130,
      "name": "משמר העמק",
      "name_eng": "MISHMAR HAEMEQ"
    },
    {
      "id": 395,
      "name": "משמר הנגב",
      "name_eng": "MISHMAR HANEGEV"
    },
    {
      "id": 194,
      "name": "משמר השרון",
      "name_eng": "MISHMAR HASHARON"
    },
    {
      "id": 729,
      "name": "משמר השבעה",
      "name_eng": "MISHMAR HASHIV'A"
    },
    {
      "id": 732,
      "name": "משמר הירדן",
      "name_eng": "MISHMAR HAYARDEN"
    },
    {
      "id": 213,
      "name": "משמרות",
      "name_eng": "MISHMAROT"
    },
    {
      "id": 425,
      "name": "משמרת",
      "name_eng": "MISHMERET"
    },
    {
      "id": 1370,
      "name": "מצפה אילן",
      "name_eng": "MITSPE ILAN"
    },
    {
      "id": 829,
      "name": "מבטחים",
      "name_eng": "MIVTAHIM"
    },
    {
      "id": 58,
      "name": "מצפה",
      "name_eng": "MIZPA"
    },
    {
      "id": 1222,
      "name": "מצפה אבי\"ב",
      "name_eng": "MIZPE AVIV"
    },
    {
      "id": 1190,
      "name": "מצפה נטופה",
      "name_eng": "MIZPE NETOFA"
    },
    {
      "id": 99,
      "name": "מצפה רמון",
      "name_eng": "MIZPE RAMON"
    },
    {
      "id": 3610,
      "name": "מצפה שלם",
      "name_eng": "MIZPE SHALEM"
    },
    {
      "id": 3576,
      "name": "מצפה יריחו",
      "name_eng": "MIZPE YERIHO"
    },
    {
      "id": 104,
      "name": "מזרע",
      "name_eng": "MIZRA"
    },
    {
      "id": 3797,
      "name": "מודיעין עילית",
      "name_eng": "MODI'IN ILLIT"
    },
    {
      "id": 1200,
      "name": "מודיעין-מכבים-רעות",
      "name_eng": "MODI'IN-MAKKABBIM-RE"
    },
    {
      "id": 269,
      "name": "מולדת",
      "name_eng": "MOLEDET"
    },
    {
      "id": 1163,
      "name": "מורן",
      "name_eng": "MORAN"
    },
    {
      "id": 1178,
      "name": "מורשת",
      "name_eng": "MORESHET"
    },
    {
      "id": 208,
      "name": "מוצא עילית",
      "name_eng": "MOZA ILLIT"
    },
    {
      "id": 481,
      "name": "מגאר",
      "name_eng": "MUGHAR"
    },
    {
      "id": 635,
      "name": "מוקייבלה",
      "name_eng": "MUQEIBLE"
    },
    {
      "id": 3787,
      "name": "נעלה",
      "name_eng": "NA'ALE"
    },
    {
      "id": 158,
      "name": "נען",
      "name_eng": "NA'AN"
    },
    {
      "id": 3620,
      "name": "נערן",
      "name_eng": "NA'ARAN"
    },
    {
      "id": 524,
      "name": "נאעורה",
      "name_eng": "NA'URA"
    },
    {
      "id": 3713,
      "name": "נעמ\"ה",
      "name_eng": "NAAMA"
    },
    {
      "id": 1276,
      "name": "אשבל",
      "name_eng": "NAHAL ESHBAL"
    },
    {
      "id": 3646,
      "name": "חמדת",
      "name_eng": "NAHAL HEMDAT"
    },
    {
      "id": 844,
      "name": "נחל עוז",
      "name_eng": "NAHAL OZ"
    },
    {
      "id": 1267,
      "name": "שיטים",
      "name_eng": "NAHAL SHITTIM"
    },
    {
      "id": 2045,
      "name": "נחלה",
      "name_eng": "NAHALA"
    },
    {
      "id": 80,
      "name": "נהלל",
      "name_eng": "NAHALAL"
    },
    {
      "id": 3767,
      "name": "נחליאל",
      "name_eng": "NAHALI'EL"
    },
    {
      "id": 809,
      "name": "נחם",
      "name_eng": "NAHAM"
    },
    {
      "id": 9100,
      "name": "נהריה",
      "name_eng": "NAHARIYYA"
    },
    {
      "id": 522,
      "name": "נחף",
      "name_eng": "NAHEF"
    },
    {
      "id": 433,
      "name": "נחשולים",
      "name_eng": "NAHSHOLIM"
    },
    {
      "id": 777,
      "name": "נחשון",
      "name_eng": "NAHSHON"
    },
    {
      "id": 705,
      "name": "נחשונים",
      "name_eng": "NAHSHONIM"
    },
    {
      "id": 1041,
      "name": "נצאצרה (שבט)",
      "name_eng": "NASASRA"
    },
    {
      "id": 1254,
      "name": "נטף",
      "name_eng": "NATAF"
    },
    {
      "id": 4014,
      "name": "נטור",
      "name_eng": "NATUR"
    },
    {
      "id": 1366,
      "name": "נווה",
      "name_eng": "NAVE"
    },
    {
      "id": 7300,
      "name": "נצרת",
      "name_eng": "NAZARETH"
    },
    {
      "id": 4551,
      "name": "נאות גולן",
      "name_eng": "NE'OT GOLAN"
    },
    {
      "id": 1124,
      "name": "נאות הכיכר",
      "name_eng": "NE'OT HAKIKKAR"
    },
    {
      "id": 408,
      "name": "נאות מרדכי",
      "name_eng": "NE'OT MORDEKHAY"
    },
    {
      "id": 186,
      "name": "נעורים",
      "name_eng": "NE'URIM"
    },
    {
      "id": 315,
      "name": "נגבה",
      "name_eng": "NEGBA"
    },
    {
      "id": 3724,
      "name": "נגוהות",
      "name_eng": "NEGOHOT"
    },
    {
      "id": 449,
      "name": "נחלים",
      "name_eng": "NEHALIM"
    },
    {
      "id": 309,
      "name": "נהורה",
      "name_eng": "NEHORA"
    },
    {
      "id": 59,
      "name": "נחושה",
      "name_eng": "NEHUSHA"
    },
    {
      "id": 523,
      "name": "ניין",
      "name_eng": "NEIN"
    },
    {
      "id": 1143,
      "name": "נס עמים",
      "name_eng": "NES AMMIM"
    },
    {
      "id": 825,
      "name": "נס הרים",
      "name_eng": "NES HARIM"
    },
    {
      "id": 7200,
      "name": "נס ציונה",
      "name_eng": "NES ZIYYONA"
    },
    {
      "id": 2500,
      "name": "נשר",
      "name_eng": "NESHER"
    },
    {
      "id": 1369,
      "name": "נטע",
      "name_eng": "NETA"
    },
    {
      "id": 174,
      "name": "נטעים",
      "name_eng": "NETA'IM"
    },
    {
      "id": 7400,
      "name": "נתניה",
      "name_eng": "NETANYA"
    },
    {
      "id": 1242,
      "name": "נתיב העשרה",
      "name_eng": "NETIV HAASARA"
    },
    {
      "id": 3555,
      "name": "נתיב הגדוד",
      "name_eng": "NETIV HAGEDUD"
    },
    {
      "id": 693,
      "name": "נתיב הל\"ה",
      "name_eng": "NETIV HALAMED-HE"
    },
    {
      "id": 792,
      "name": "נתיב השיירה",
      "name_eng": "NETIV HASHAYYARA"
    },
    {
      "id": 246,
      "name": "נתיבות",
      "name_eng": "NETIVOT"
    },
    {
      "id": 1147,
      "name": "נטועה",
      "name_eng": "NETU'A"
    },
    {
      "id": 396,
      "name": "נבטים",
      "name_eng": "NEVATIM"
    },
    {
      "id": 3573,
      "name": "נוה צוף",
      "name_eng": "NEVE TSUF"
    },
    {
      "id": 4303,
      "name": "נווה אטי\"ב",
      "name_eng": "NEWE ATIV"
    },
    {
      "id": 926,
      "name": "נווה אבות",
      "name_eng": "NEWE AVOT"
    },
    {
      "id": 3725,
      "name": "נווה דניאל",
      "name_eng": "NEWE DANIYYEL"
    },
    {
      "id": 296,
      "name": "נווה איתן",
      "name_eng": "NEWE ETAN"
    },
    {
      "id": 1279,
      "name": "נווה חריף",
      "name_eng": "NEWE HARIF"
    },
    {
      "id": 405,
      "name": "נווה אילן",
      "name_eng": "NEWE ILAN"
    },
    {
      "id": 1071,
      "name": "נווה מיכאל",
      "name_eng": "NEWE MIKHA'EL"
    },
    {
      "id": 827,
      "name": "נווה מבטח",
      "name_eng": "NEWE MIVTAH"
    },
    {
      "id": 1259,
      "name": "נווה שלום",
      "name_eng": "NEWE SHALOM"
    },
    {
      "id": 590,
      "name": "נווה אור",
      "name_eng": "NEWE UR"
    },
    {
      "id": 312,
      "name": "נווה ים",
      "name_eng": "NEWE YAM"
    },
    {
      "id": 686,
      "name": "נווה ימין",
      "name_eng": "NEWE YAMIN"
    },
    {
      "id": 858,
      "name": "נווה ירק",
      "name_eng": "NEWE YARAQ"
    },
    {
      "id": 1314,
      "name": "נווה זיו",
      "name_eng": "NEWE ZIV"
    },
    {
      "id": 1057,
      "name": "נווה זוהר",
      "name_eng": "NEWE ZOHAR"
    },
    {
      "id": 1372,
      "name": "נצר חזני",
      "name_eng": "NEZER HAZZANI"
    },
    {
      "id": 435,
      "name": "נצר סרני",
      "name_eng": "NEZER SERENI"
    },
    {
      "id": 3655,
      "name": "ניל\"י",
      "name_eng": "NILI"
    },
    {
      "id": 4035,
      "name": "נמרוד",
      "name_eng": "NIMROD"
    },
    {
      "id": 348,
      "name": "ניר עם",
      "name_eng": "NIR AM"
    },
    {
      "id": 2048,
      "name": "ניר עקיבא",
      "name_eng": "NIR AQIVA"
    },
    {
      "id": 553,
      "name": "ניר בנים",
      "name_eng": "NIR BANIM"
    },
    {
      "id": 256,
      "name": "ניר דוד (תל עמל)",
      "name_eng": "NIR DAWID (TEL AMAL)"
    },
    {
      "id": 808,
      "name": "ניר אליהו",
      "name_eng": "NIR ELIYYAHU"
    },
    {
      "id": 769,
      "name": "ניר עציון",
      "name_eng": "NIR EZYON"
    },
    {
      "id": 720,
      "name": "ניר גלים",
      "name_eng": "NIR GALLIM"
    },
    {
      "id": 11,
      "name": "ניר ח\"ן",
      "name_eng": "NIR HEN"
    },
    {
      "id": 2047,
      "name": "ניר משה",
      "name_eng": "NIR MOSHE"
    },
    {
      "id": 69,
      "name": "ניר עוז",
      "name_eng": "NIR OZ"
    },
    {
      "id": 165,
      "name": "ניר יפה",
      "name_eng": "NIR YAFE"
    },
    {
      "id": 699,
      "name": "ניר ישראל",
      "name_eng": "NIR YISRA'EL"
    },
    {
      "id": 402,
      "name": "ניר יצחק",
      "name_eng": "NIR YIZHAQ"
    },
    {
      "id": 331,
      "name": "ניר צבי",
      "name_eng": "NIR ZEVI"
    },
    {
      "id": 602,
      "name": "נירים",
      "name_eng": "NIRIM"
    },
    {
      "id": 1236,
      "name": "נירית",
      "name_eng": "NIRIT"
    },
    {
      "id": 351,
      "name": "ניצן",
      "name_eng": "NIZZAN"
    },
    {
      "id": 1419,
      "name": "ניצן ב'",
      "name_eng": "NIZZAN B"
    },
    {
      "id": 1195,
      "name": "ניצנה (קהילת חינוך)",
      "name_eng": "NIZZANA (QEHILAT HIN"
    },
    {
      "id": 851,
      "name": "ניצני עוז",
      "name_eng": "NIZZANE OZ"
    },
    {
      "id": 1280,
      "name": "ניצני סיני",
      "name_eng": "NIZZANE SINAY"
    },
    {
      "id": 359,
      "name": "ניצנים",
      "name_eng": "NIZZANIM"
    },
    {
      "id": 15,
      "name": "נועם",
      "name_eng": "NO'AM"
    },
    {
      "id": 1333,
      "name": "נוף איילון",
      "name_eng": "NOF AYYALON"
    },
    {
      "id": 1061,
      "name": "נוף הגליל",
      "name_eng": "NOF HAGALIL"
    },
    {
      "id": 257,
      "name": "נופך",
      "name_eng": "NOFEKH"
    },
    {
      "id": 3790,
      "name": "נופים",
      "name_eng": "NOFIM"
    },
    {
      "id": 1284,
      "name": "נופית",
      "name_eng": "NOFIT"
    },
    {
      "id": 55,
      "name": "נוגה",
      "name_eng": "NOGAH"
    },
    {
      "id": 3726,
      "name": "נוקדים",
      "name_eng": "NOQEDIM"
    },
    {
      "id": 447,
      "name": "נורדיה",
      "name_eng": "NORDIYYA"
    },
    {
      "id": 4304,
      "name": "נוב",
      "name_eng": "NOV"
    },
    {
      "id": 833,
      "name": "נורית",
      "name_eng": "NURIT"
    },
    {
      "id": 4010,
      "name": "אודם",
      "name_eng": "ODEM"
    },
    {
      "id": 31,
      "name": "אופקים",
      "name_eng": "OFAQIM"
    },
    {
      "id": 810,
      "name": "עופר",
      "name_eng": "OFER"
    },
    {
      "id": 3617,
      "name": "עופרה",
      "name_eng": "OFRA"
    },
    {
      "id": 1046,
      "name": "אוהד",
      "name_eng": "OHAD"
    },
    {
      "id": 737,
      "name": "עולש",
      "name_eng": "OLESH"
    },
    {
      "id": 1108,
      "name": "אומן",
      "name_eng": "OMEN"
    },
    {
      "id": 666,
      "name": "עומר",
      "name_eng": "OMER"
    },
    {
      "id": 680,
      "name": "אומץ",
      "name_eng": "OMEZ"
    },
    {
      "id": 1020,
      "name": "אור עקיבא",
      "name_eng": "OR AQIVA"
    },
    {
      "id": 1294,
      "name": "אור הגנוז",
      "name_eng": "OR HAGANUZ"
    },
    {
      "id": 67,
      "name": "אור הנר",
      "name_eng": "OR HANER"
    },
    {
      "id": 2400,
      "name": "אור יהודה",
      "name_eng": "OR YEHUDA"
    },
    {
      "id": 780,
      "name": "אורה",
      "name_eng": "ORA"
    },
    {
      "id": 882,
      "name": "אורנים",
      "name_eng": "ORANIM"
    },
    {
      "id": 3760,
      "name": "אורנית",
      "name_eng": "ORANIT"
    },
    {
      "id": 2012,
      "name": "אורות",
      "name_eng": "OROT"
    },
    {
      "id": 4013,
      "name": "אורטל",
      "name_eng": "ORTAL"
    },
    {
      "id": 3748,
      "name": "עתניאל",
      "name_eng": "OTNI'EL"
    },
    {
      "id": 32,
      "name": "עוצם",
      "name_eng": "OZEM"
    },
    {
      "id": 2059,
      "name": "פעמי תש\"ז",
      "name_eng": "PA'AME TASHAZ"
    },
    {
      "id": 597,
      "name": "פלמחים",
      "name_eng": "PALMAHIM"
    },
    {
      "id": 1151,
      "name": "פארן",
      "name_eng": "PARAN"
    },
    {
      "id": 7800,
      "name": "פרדס חנה-כרכור",
      "name_eng": "PARDES HANNA-KARKUR"
    },
    {
      "id": 171,
      "name": "פרדסיה",
      "name_eng": "PARDESIYYA"
    },
    {
      "id": 599,
      "name": "פרוד",
      "name_eng": "PAROD"
    },
    {
      "id": 749,
      "name": "פטיש",
      "name_eng": "PATTISH"
    },
    {
      "id": 838,
      "name": "פדיה",
      "name_eng": "PEDAYA"
    },
    {
      "id": 3768,
      "name": "פדואל",
      "name_eng": "PEDU'EL"
    },
    {
      "id": 750,
      "name": "פדויים",
      "name_eng": "PEDUYIM"
    },
    {
      "id": 1185,
      "name": "פלך",
      "name_eng": "PELEKH"
    },
    {
      "id": 3723,
      "name": "פני חבר",
      "name_eng": "PENE HEVER"
    },
    {
      "id": 536,
      "name": "פקיעין (בוקייעה)",
      "name_eng": "PEQI'IN (BUQEI'A)"
    },
    {
      "id": 281,
      "name": "פקיעין חדשה",
      "name_eng": "PEQI'IN HADASHA"
    },
    {
      "id": 2053,
      "name": "פרזון",
      "name_eng": "PERAZON"
    },
    {
      "id": 1231,
      "name": "פרי גן",
      "name_eng": "PERI GAN"
    },
    {
      "id": 3659,
      "name": "פסגות",
      "name_eng": "PESAGOT"
    },
    {
      "id": 7900,
      "name": "פתח תקווה",
      "name_eng": "PETAH TIQWA"
    },
    {
      "id": 839,
      "name": "פתחיה",
      "name_eng": "PETAHYA"
    },
    {
      "id": 3615,
      "name": "פצאל",
      "name_eng": "PEZA'EL"
    },
    {
      "id": 767,
      "name": "פורת",
      "name_eng": "PORAT"
    },
    {
      "id": 1313,
      "name": "פוריה עילית",
      "name_eng": "PORIYYA ILLIT"
    },
    {
      "id": 1104,
      "name": "פוריה - כפר עבודה",
      "name_eng": "PORIYYA-KEFAR AVODA"
    },
    {
      "id": 1105,
      "name": "פוריה - נווה עובד",
      "name_eng": "PORIYYA-NEWE OVED"
    },
    {
      "id": 1234,
      "name": "קבועה (שבט)",
      "name_eng": "QABBO'A"
    },
    {
      "id": 1211,
      "name": "קדרים",
      "name_eng": "QADDARIM"
    },
    {
      "id": 195,
      "name": "קדימה-צורן",
      "name_eng": "QADIMA-ZORAN"
    },
    {
      "id": 638,
      "name": "קלנסווה",
      "name_eng": "QALANSAWE"
    },
    {
      "id": 3601,
      "name": "קליה",
      "name_eng": "QALYA"
    },
    {
      "id": 3640,
      "name": "קרני שומרון",
      "name_eng": "QARNE SHOMERON"
    },
    {
      "id": 972,
      "name": "קוואעין (שבט)",
      "name_eng": "QAWA'IN"
    },
    {
      "id": 1243,
      "name": "קציר",
      "name_eng": "QAZIR"
    },
    {
      "id": 4100,
      "name": "קצרין",
      "name_eng": "QAZRIN"
    },
    {
      "id": 3781,
      "name": "קדר",
      "name_eng": "QEDAR"
    },
    {
      "id": 392,
      "name": "קדמה",
      "name_eng": "QEDMA"
    },
    {
      "id": 3557,
      "name": "קדומים",
      "name_eng": "QEDUMIM"
    },
    {
      "id": 4024,
      "name": "קלע",
      "name_eng": "QELA"
    },
    {
      "id": 414,
      "name": "קלחים",
      "name_eng": "QELAHIM"
    },
    {
      "id": 1167,
      "name": "קיסריה",
      "name_eng": "QESARIYYA"
    },
    {
      "id": 4006,
      "name": "קשת",
      "name_eng": "QESHET"
    },
    {
      "id": 1052,
      "name": "קטורה",
      "name_eng": "QETURA"
    },
    {
      "id": 334,
      "name": "קבוצת יבנה",
      "name_eng": "QEVUZAT YAVNE"
    },
    {
      "id": 4025,
      "name": "קדמת צבי",
      "name_eng": "QIDMAT ZEVI"
    },
    {
      "id": 615,
      "name": "קדרון",
      "name_eng": "QIDRON"
    },
    {
      "id": 78,
      "name": "קרית ענבים",
      "name_eng": "QIRYAT ANAVIM"
    },
    {
      "id": 3611,
      "name": "קרית ארבע",
      "name_eng": "QIRYAT ARBA"
    },
    {
      "id": 6800,
      "name": "קרית אתא",
      "name_eng": "QIRYAT ATTA"
    },
    {
      "id": 9500,
      "name": "קרית ביאליק",
      "name_eng": "QIRYAT BIALIK"
    },
    {
      "id": 469,
      "name": "קרית עקרון",
      "name_eng": "QIRYAT EQRON"
    },
    {
      "id": 2630,
      "name": "קרית גת",
      "name_eng": "QIRYAT GAT"
    },
    {
      "id": 1034,
      "name": "קרית מלאכי",
      "name_eng": "QIRYAT MAL'AKHI"
    },
    {
      "id": 8200,
      "name": "קרית מוצקין",
      "name_eng": "QIRYAT MOTZKIN"
    },
    {
      "id": 3746,
      "name": "קרית נטפים",
      "name_eng": "QIRYAT NETAFIM"
    },
    {
      "id": 2620,
      "name": "קרית אונו",
      "name_eng": "QIRYAT ONO"
    },
    {
      "id": 412,
      "name": "קרית שלמה",
      "name_eng": "QIRYAT SHELOMO"
    },
    {
      "id": 2800,
      "name": "קרית שמונה",
      "name_eng": "QIRYAT SHEMONA"
    },
    {
      "id": 2300,
      "name": "קרית טבעון",
      "name_eng": "QIRYAT TIV'ON"
    },
    {
      "id": 9600,
      "name": "קרית ים",
      "name_eng": "QIRYAT YAM"
    },
    {
      "id": 1137,
      "name": "קרית יערים",
      "name_eng": "QIRYAT YE'ARIM"
    },
    {
      "id": 2039,
      "name": "קרית יערים(מוסד)",
      "name_eng": "QIRYAT YE'ARIM(INSTI"
    },
    {
      "id": 766,
      "name": "קוממיות",
      "name_eng": "QOMEMIYYUT"
    },
    {
      "id": 1179,
      "name": "קורנית",
      "name_eng": "QORANIT"
    },
    {
      "id": 964,
      "name": "קודייראת א-צאנע(שבט)",
      "name_eng": "QUDEIRAT AS-SANI"
    },
    {
      "id": 8700,
      "name": "רעננה",
      "name_eng": "RA'ANANA"
    },
    {
      "id": 1161,
      "name": "רהט",
      "name_eng": "RAHAT"
    },
    {
      "id": 1069,
      "name": "רם-און",
      "name_eng": "RAM-ON"
    },
    {
      "id": 135,
      "name": "רמת דוד",
      "name_eng": "RAMAT DAWID"
    },
    {
      "id": 8600,
      "name": "רמת גן",
      "name_eng": "RAMAT GAN"
    },
    {
      "id": 184,
      "name": "רמת הכובש",
      "name_eng": "RAMAT HAKOVESH"
    },
    {
      "id": 2650,
      "name": "רמת השרון",
      "name_eng": "RAMAT HASHARON"
    },
    {
      "id": 335,
      "name": "רמת השופט",
      "name_eng": "RAMAT HASHOFET"
    },
    {
      "id": 4701,
      "name": "רמת מגשימים",
      "name_eng": "RAMAT MAGSHIMIM"
    },
    {
      "id": 127,
      "name": "רמת רחל",
      "name_eng": "RAMAT RAHEL"
    },
    {
      "id": 460,
      "name": "רמת רזיאל",
      "name_eng": "RAMAT RAZI'EL"
    },
    {
      "id": 122,
      "name": "רמת ישי",
      "name_eng": "RAMAT YISHAY"
    },
    {
      "id": 178,
      "name": "רמת יוחנן",
      "name_eng": "RAMAT YOHANAN"
    },
    {
      "id": 339,
      "name": "רמת צבי",
      "name_eng": "RAMAT ZEVI"
    },
    {
      "id": 543,
      "name": "ראמה",
      "name_eng": "RAME"
    },
    {
      "id": 8500,
      "name": "רמלה",
      "name_eng": "RAMLA"
    },
    {
      "id": 4702,
      "name": "רמות",
      "name_eng": "RAMOT"
    },
    {
      "id": 206,
      "name": "רמות השבים",
      "name_eng": "RAMOT HASHAVIM"
    },
    {
      "id": 735,
      "name": "רמות מאיר",
      "name_eng": "RAMOT ME'IR"
    },
    {
      "id": 445,
      "name": "רמות מנשה",
      "name_eng": "RAMOT MENASHE"
    },
    {
      "id": 372,
      "name": "רמות נפתלי",
      "name_eng": "RAMOT NAFTALI"
    },
    {
      "id": 789,
      "name": "רנן",
      "name_eng": "RANNEN"
    },
    {
      "id": 1228,
      "name": "רקפת",
      "name_eng": "RAQEFET"
    },
    {
      "id": 1334,
      "name": "ראס אל-עין",
      "name_eng": "RAS AL-EIN"
    },
    {
      "id": 990,
      "name": "ראס עלי",
      "name_eng": "RAS ALI"
    },
    {
      "id": 1225,
      "name": "רביד",
      "name_eng": "RAVID"
    },
    {
      "id": 713,
      "name": "רעים",
      "name_eng": "RE'IM"
    },
    {
      "id": 444,
      "name": "רגבים",
      "name_eng": "REGAVIM"
    },
    {
      "id": 390,
      "name": "רגבה",
      "name_eng": "REGBA"
    },
    {
      "id": 3568,
      "name": "ריחן",
      "name_eng": "REHAN"
    },
    {
      "id": 3822,
      "name": "רחלים",
      "name_eng": "REHELIM"
    },
    {
      "id": 854,
      "name": "רחוב",
      "name_eng": "REHOV"
    },
    {
      "id": 8400,
      "name": "רחובות",
      "name_eng": "REHOVOT"
    },
    {
      "id": 540,
      "name": "ריחאניה",
      "name_eng": "REIHANIYYE"
    },
    {
      "id": 542,
      "name": "ריינה",
      "name_eng": "REINE"
    },
    {
      "id": 922,
      "name": "רכסים",
      "name_eng": "REKHASIM"
    },
    {
      "id": 437,
      "name": "רשפים",
      "name_eng": "RESHAFIM"
    },
    {
      "id": 1260,
      "name": "רתמים",
      "name_eng": "RETAMIM"
    },
    {
      "id": 564,
      "name": "רבדים",
      "name_eng": "REVADIM"
    },
    {
      "id": 3795,
      "name": "רבבה",
      "name_eng": "REVAVA"
    },
    {
      "id": 354,
      "name": "רביבים",
      "name_eng": "REVIVIM"
    },
    {
      "id": 2051,
      "name": "רווחה",
      "name_eng": "REWAHA"
    },
    {
      "id": 2016,
      "name": "רוויה",
      "name_eng": "REWAYA"
    },
    {
      "id": 3565,
      "name": "רימונים",
      "name_eng": "RIMMONIM"
    },
    {
      "id": 616,
      "name": "רינתיה",
      "name_eng": "RINNATYA"
    },
    {
      "id": 8300,
      "name": "ראשון לציון",
      "name_eng": "RISHON LEZIYYON"
    },
    {
      "id": 247,
      "name": "רשפון",
      "name_eng": "RISHPON"
    },
    {
      "id": 3619,
      "name": "רועי",
      "name_eng": "RO'I"
    },
    {
      "id": 2640,
      "name": "ראש העין",
      "name_eng": "ROSH HAAYIN"
    },
    {
      "id": 26,
      "name": "ראש פינה",
      "name_eng": "ROSH PINNA"
    },
    {
      "id": 3602,
      "name": "ראש צורים",
      "name_eng": "ROSH ZURIM"
    },
    {
      "id": 3782,
      "name": "רותם",
      "name_eng": "ROTEM"
    },
    {
      "id": 1341,
      "name": "רוח מדבר",
      "name_eng": "RUAH MIDBAR"
    },
    {
      "id": 362,
      "name": "רוחמה",
      "name_eng": "RUHAMA"
    },
    {
      "id": 997,
      "name": "רומת הייב",
      "name_eng": "RUMAT HEIB"
    },
    {
      "id": 539,
      "name": "רומאנה",
      "name_eng": "RUMMANE"
    },
    {
      "id": 419,
      "name": "סעד",
      "name_eng": "SA'AD"
    },
    {
      "id": 454,
      "name": "סער",
      "name_eng": "SA'AR"
    },
    {
      "id": 1360,
      "name": "סעוה",
      "name_eng": "SA'WA"
    },
    {
      "id": 525,
      "name": "סאג'ור",
      "name_eng": "SAJUR"
    },
    {
      "id": 7500,
      "name": "סח'נין",
      "name_eng": "SAKHNIN"
    },
    {
      "id": 3567,
      "name": "סלעית",
      "name_eng": "SAL'IT"
    },
    {
      "id": 1245,
      "name": "סלמה",
      "name_eng": "SALLAMA"
    },
    {
      "id": 1156,
      "name": "סמר",
      "name_eng": "SAMAR"
    },
    {
      "id": 636,
      "name": "צנדלה",
      "name_eng": "SANDALA"
    },
    {
      "id": 1176,
      "name": "ספיר",
      "name_eng": "SAPPIR"
    },
    {
      "id": 126,
      "name": "שריד",
      "name_eng": "SARID"
    },
    {
      "id": 578,
      "name": "סאסא",
      "name_eng": "SASA"
    },
    {
      "id": 587,
      "name": "סביון",
      "name_eng": "SAVYON"
    },
    {
      "id": 989,
      "name": "סואעד (כמאנה) (שבט)",
      "name_eng": "SAWA'ID (KAMANE)"
    },
    {
      "id": 942,
      "name": "סואעד (חמרייה)",
      "name_eng": "SAWA'ID(HAMRIYYE)"
    },
    {
      "id": 1170,
      "name": "סייד (שבט)",
      "name_eng": "SAYYID"
    },
    {
      "id": 1223,
      "name": "שדי אברהם",
      "name_eng": "SEDE AVRAHAM"
    },
    {
      "id": 885,
      "name": "שדה בוקר",
      "name_eng": "SEDE BOQER"
    },
    {
      "id": 36,
      "name": "שדה דוד",
      "name_eng": "SEDE DAWID"
    },
    {
      "id": 861,
      "name": "שדה אליעזר",
      "name_eng": "SEDE ELI'EZER"
    },
    {
      "id": 304,
      "name": "שדה אליהו",
      "name_eng": "SEDE ELIYYAHU"
    },
    {
      "id": 2015,
      "name": "שדי חמד",
      "name_eng": "SEDE HEMED"
    },
    {
      "id": 721,
      "name": "שדה אילן",
      "name_eng": "SEDE ILAN"
    },
    {
      "id": 18,
      "name": "שדה משה",
      "name_eng": "SEDE MOSHE"
    },
    {
      "id": 259,
      "name": "שדה נחום",
      "name_eng": "SEDE NAHUM"
    },
    {
      "id": 329,
      "name": "שדה נחמיה",
      "name_eng": "SEDE NEHEMYA"
    },
    {
      "id": 1058,
      "name": "שדה ניצן",
      "name_eng": "SEDE NIZZAN"
    },
    {
      "id": 2057,
      "name": "שדי תרומות",
      "name_eng": "SEDE TERUMOT"
    },
    {
      "id": 739,
      "name": "שדה עוזיהו",
      "name_eng": "SEDE UZZIYYAHU"
    },
    {
      "id": 284,
      "name": "שדה ורבורג",
      "name_eng": "SEDE WARBURG"
    },
    {
      "id": 142,
      "name": "שדה יעקב",
      "name_eng": "SEDE YA'AQOV"
    },
    {
      "id": 2008,
      "name": "שדה יצחק",
      "name_eng": "SEDE YIZHAQ"
    },
    {
      "id": 293,
      "name": "שדה יואב",
      "name_eng": "SEDE YO'AV"
    },
    {
      "id": 2049,
      "name": "שדה צבי",
      "name_eng": "SEDE ZEVI"
    },
    {
      "id": 1031,
      "name": "שדרות",
      "name_eng": "SEDEROT"
    },
    {
      "id": 27,
      "name": "שדות מיכה",
      "name_eng": "SEDOT MIKHA"
    },
    {
      "id": 327,
      "name": "שדות ים",
      "name_eng": "SEDOT YAM"
    },
    {
      "id": 1286,
      "name": "שגב-שלום",
      "name_eng": "SEGEV-SHALOM"
    },
    {
      "id": 2046,
      "name": "סגולה",
      "name_eng": "SEGULA"
    },
    {
      "id": 1132,
      "name": "שניר",
      "name_eng": "SENIR"
    },
    {
      "id": 538,
      "name": "שעב",
      "name_eng": "SHA'AB"
    },
    {
      "id": 4009,
      "name": "שעל",
      "name_eng": "SHA'AL"
    },
    {
      "id": 856,
      "name": "שעלבים",
      "name_eng": "SHA'ALVIM"
    },
    {
      "id": 661,
      "name": "שער אפרים",
      "name_eng": "SHA'AR EFRAYIM"
    },
    {
      "id": 237,
      "name": "שער העמקים",
      "name_eng": "SHA'AR HAAMAQIM"
    },
    {
      "id": 264,
      "name": "שער הגולן",
      "name_eng": "SHA'AR HAGOLAN"
    },
    {
      "id": 921,
      "name": "שער מנשה",
      "name_eng": "SHA'AR MENASHE"
    },
    {
      "id": 306,
      "name": "שדמות דבורה",
      "name_eng": "SHADMOT DEVORA"
    },
    {
      "id": 3578,
      "name": "שדמות מחולה",
      "name_eng": "SHADMOT MEHOLA"
    },
    {
      "id": 692,
      "name": "שפיר",
      "name_eng": "SHAFIR"
    },
    {
      "id": 7,
      "name": "שחר",
      "name_eng": "SHAHAR"
    },
    {
      "id": 1266,
      "name": "שחרות",
      "name_eng": "SHAHARUT"
    },
    {
      "id": 1373,
      "name": "שלווה במדבר",
      "name_eng": "SHALVA BAMIDBAR"
    },
    {
      "id": 873,
      "name": "שלווה",
      "name_eng": "SHALWA"
    },
    {
      "id": 432,
      "name": "שמרת",
      "name_eng": "SHAMERAT"
    },
    {
      "id": 366,
      "name": "שמיר",
      "name_eng": "SHAMIR"
    },
    {
      "id": 1287,
      "name": "שני",
      "name_eng": "SHANI"
    },
    {
      "id": 3649,
      "name": "שקד",
      "name_eng": "SHAQED"
    },
    {
      "id": 292,
      "name": "שרונה",
      "name_eng": "SHARONA"
    },
    {
      "id": 398,
      "name": "שרשרת",
      "name_eng": "SHARSHERET"
    },
    {
      "id": 1377,
      "name": "שבי דרום",
      "name_eng": "SHAVE DAROM"
    },
    {
      "id": 3571,
      "name": "שבי שומרון",
      "name_eng": "SHAVE SHOMERON"
    },
    {
      "id": 282,
      "name": "שבי ציון",
      "name_eng": "SHAVE ZIYYON"
    },
    {
      "id": 324,
      "name": "שאר ישוב",
      "name_eng": "SHE'AR YASHUV"
    },
    {
      "id": 555,
      "name": "שדמה",
      "name_eng": "SHEDEMA"
    },
    {
      "id": 8800,
      "name": "שפרעם",
      "name_eng": "SHEFAR'AM"
    },
    {
      "id": 232,
      "name": "שפיים",
      "name_eng": "SHEFAYIM"
    },
    {
      "id": 846,
      "name": "שפר",
      "name_eng": "SHEFER"
    },
    {
      "id": 658,
      "name": "שייח' דנון",
      "name_eng": "SHEIKH DANNUN"
    },
    {
      "id": 1160,
      "name": "שכניה",
      "name_eng": "SHEKHANYA"
    },
    {
      "id": 812,
      "name": "שלומי",
      "name_eng": "SHELOMI"
    },
    {
      "id": 439,
      "name": "שלוחות",
      "name_eng": "SHELUHOT"
    },
    {
      "id": 1233,
      "name": "שקף",
      "name_eng": "SHEQEF"
    },
    {
      "id": 1045,
      "name": "שתולה",
      "name_eng": "SHETULA"
    },
    {
      "id": 763,
      "name": "שתולים",
      "name_eng": "SHETULIM"
    },
    {
      "id": 1378,
      "name": "שיזף",
      "name_eng": "SHEZAF"
    },
    {
      "id": 527,
      "name": "שזור",
      "name_eng": "SHEZOR"
    },
    {
      "id": 865,
      "name": "שיבולים",
      "name_eng": "SHIBBOLIM"
    },
    {
      "id": 913,
      "name": "שבלי - אום אל-גנם",
      "name_eng": "SHIBLI"
    },
    {
      "id": 1165,
      "name": "שילת",
      "name_eng": "SHILAT"
    },
    {
      "id": 3641,
      "name": "שילה",
      "name_eng": "SHILO"
    },
    {
      "id": 3784,
      "name": "שמעה",
      "name_eng": "SHIM'A"
    },
    {
      "id": 1337,
      "name": "שמשית",
      "name_eng": "SHIMSHIT"
    },
    {
      "id": 1197,
      "name": "נאות סמדר",
      "name_eng": "SHIZZAFON"
    },
    {
      "id": 1364,
      "name": "שלומית",
      "name_eng": "SHLOMIT"
    },
    {
      "id": 741,
      "name": "שואבה",
      "name_eng": "SHO'EVA"
    },
    {
      "id": 1304,
      "name": "שוהם",
      "name_eng": "SHOHAM"
    },
    {
      "id": 614,
      "name": "שומרה",
      "name_eng": "SHOMERA"
    },
    {
      "id": 1265,
      "name": "שומריה",
      "name_eng": "SHOMERIYYA"
    },
    {
      "id": 415,
      "name": "שוקדה",
      "name_eng": "SHOQEDA"
    },
    {
      "id": 1235,
      "name": "שורשים",
      "name_eng": "SHORASHIM"
    },
    {
      "id": 456,
      "name": "שורש",
      "name_eng": "SHORESH"
    },
    {
      "id": 224,
      "name": "שושנת העמקים",
      "name_eng": "SHOSHANNAT HAAMAQIM"
    },
    {
      "id": 1102,
      "name": "צוקי ים",
      "name_eng": "SHOSHANNAT HAAMAQIM("
    },
    {
      "id": 394,
      "name": "שובל",
      "name_eng": "SHOVAL"
    },
    {
      "id": 761,
      "name": "שובה",
      "name_eng": "SHUVA"
    },
    {
      "id": 610,
      "name": "סתריה",
      "name_eng": "SITRIYYA"
    },
    {
      "id": 1238,
      "name": "סופה",
      "name_eng": "SUFA"
    },
    {
      "id": 526,
      "name": "סולם",
      "name_eng": "SULAM"
    },
    {
      "id": 3756,
      "name": "סוסיה",
      "name_eng": "SUSEYA"
    },
    {
      "id": 752,
      "name": "תעוז",
      "name_eng": "TA'OZ"
    },
    {
      "id": 462,
      "name": "טל שחר",
      "name_eng": "TAL SHAHAR"
    },
    {
      "id": 1181,
      "name": "טל-אל",
      "name_eng": "TAL-EL"
    },
    {
      "id": 2050,
      "name": "תלמי ביל\"ו",
      "name_eng": "TALME BILU"
    },
    {
      "id": 2003,
      "name": "תלמי אלעזר",
      "name_eng": "TALME EL'AZAR"
    },
    {
      "id": 1051,
      "name": "תלמי אליהו",
      "name_eng": "TALME ELIYYAHU"
    },
    {
      "id": 744,
      "name": "תלמי יפה",
      "name_eng": "TALME YAFE"
    },
    {
      "id": 727,
      "name": "תלמי יחיאל",
      "name_eng": "TALME YEHI'EL"
    },
    {
      "id": 1237,
      "name": "תלמי יוסף",
      "name_eng": "TALME YOSEF"
    },
    {
      "id": 3788,
      "name": "טלמון",
      "name_eng": "TALMON"
    },
    {
      "id": 8900,
      "name": "טמרה",
      "name_eng": "TAMRA"
    },
    {
      "id": 547,
      "name": "טמרה (יזרעאל)",
      "name_eng": "TAMRA (YIZRE'EL)"
    },
    {
      "id": 970,
      "name": "תראבין א-צאנע (שבט)",
      "name_eng": "TARABIN AS-SANI"
    },
    {
      "id": 1346,
      "name": "תראבין א-צאנע(ישוב)",
      "name_eng": "TARABIN AS-SANI"
    },
    {
      "id": 778,
      "name": "תרום",
      "name_eng": "TARUM"
    },
    {
      "id": 2730,
      "name": "טייבה",
      "name_eng": "TAYIBE"
    },
    {
      "id": 497,
      "name": "טייבה (בעמק)",
      "name_eng": "TAYIBE(BAEMEQ)"
    },
    {
      "id": 2062,
      "name": "תאשור",
      "name_eng": "TE'ASHUR"
    },
    {
      "id": 1214,
      "name": "טפחות",
      "name_eng": "TEFAHOT"
    },
    {
      "id": 103,
      "name": "תל עדשים",
      "name_eng": "TEL ADASHIM"
    },
    {
      "id": 5000,
      "name": "תל אביב - יפו",
      "name_eng": "TEL AVIV - YAFO"
    },
    {
      "id": 154,
      "name": "תל מונד",
      "name_eng": "TEL MOND"
    },
    {
      "id": 719,
      "name": "תל קציר",
      "name_eng": "TEL QAZIR"
    },
    {
      "id": 1054,
      "name": "תל שבע",
      "name_eng": "TEL SHEVA"
    },
    {
      "id": 1283,
      "name": "תל תאומים",
      "name_eng": "TEL TE'OMIM"
    },
    {
      "id": 287,
      "name": "תל יצחק",
      "name_eng": "TEL YIZHAQ"
    },
    {
      "id": 84,
      "name": "תל יוסף",
      "name_eng": "TEL YOSEF"
    },
    {
      "id": 1177,
      "name": "טללים",
      "name_eng": "TELALIM"
    },
    {
      "id": 814,
      "name": "תלמים",
      "name_eng": "TELAMIM"
    },
    {
      "id": 3719,
      "name": "תלם",
      "name_eng": "TELEM"
    },
    {
      "id": 3743,
      "name": "טנא",
      "name_eng": "TENE"
    },
    {
      "id": 2002,
      "name": "תנובות",
      "name_eng": "TENUVOT"
    },
    {
      "id": 3563,
      "name": "תקוע",
      "name_eng": "TEQOA"
    },
    {
      "id": 665,
      "name": "תקומה",
      "name_eng": "TEQUMA"
    },
    {
      "id": 6700,
      "name": "טבריה",
      "name_eng": "TIBERIAS"
    },
    {
      "id": 2061,
      "name": "תדהר",
      "name_eng": "TIDHAR"
    },
    {
      "id": 709,
      "name": "תפרח",
      "name_eng": "TIFRAH"
    },
    {
      "id": 163,
      "name": "תימורים",
      "name_eng": "TIMMORIM"
    },
    {
      "id": 1244,
      "name": "תמרת",
      "name_eng": "TIMRAT"
    },
    {
      "id": 2100,
      "name": "טירת כרמל",
      "name_eng": "TIRAT KARMEL"
    },
    {
      "id": 663,
      "name": "טירת יהודה",
      "name_eng": "TIRAT YEHUDA"
    },
    {
      "id": 268,
      "name": "טירת צבי",
      "name_eng": "TIRAT ZEVI"
    },
    {
      "id": 2720,
      "name": "טירה",
      "name_eng": "TIRE"
    },
    {
      "id": 10,
      "name": "תירוש",
      "name_eng": "TIROSH"
    },
    {
      "id": 3558,
      "name": "תומר",
      "name_eng": "TOMER"
    },
    {
      "id": 4029,
      "name": "רמת טראמפ",
      "name_eng": "TRUMP HEIGHTS"
    },
    {
      "id": 962,
      "name": "טובא-זנגריה",
      "name_eng": "TUBA-ZANGARIYYE"
    },
    {
      "id": 498,
      "name": "טורעאן",
      "name_eng": "TUR'AN"
    },
    {
      "id": 1083,
      "name": "תושיה",
      "name_eng": "TUSHIYYA"
    },
    {
      "id": 1172,
      "name": "תובל",
      "name_eng": "TUVAL"
    },
    {
      "id": 446,
      "name": "אודים",
      "name_eng": "UDIM"
    },
    {
      "id": 2710,
      "name": "אום אל-פחם",
      "name_eng": "UMM AL-FAHM"
    },
    {
      "id": 2024,
      "name": "אום אל-קוטוף",
      "name_eng": "UMM AL-QUTUF"
    },
    {
      "id": 1358,
      "name": "אום בטין",
      "name_eng": "UMM BATIN"
    },
    {
      "id": 957,
      "name": "עוקבי (בנו עוקבה)",
      "name_eng": "UQBI (BANU UQBA)"
    },
    {
      "id": 403,
      "name": "אורים",
      "name_eng": "URIM"
    },
    {
      "id": 278,
      "name": "אושה",
      "name_eng": "USHA"
    },
    {
      "id": 826,
      "name": "עוזה",
      "name_eng": "UZA"
    },
    {
      "id": 528,
      "name": "עוזייר",
      "name_eng": "UZEIR"
    },
    {
      "id": 1133,
      "name": "ורדון",
      "name_eng": "WARDON"
    },
    {
      "id": 3639,
      "name": "ורד יריחו",
      "name_eng": "WERED YERIHO"
    },
    {
      "id": 1138,
      "name": "יעד",
      "name_eng": "YA'AD"
    },
    {
      "id": 795,
      "name": "יערה",
      "name_eng": "YA'ARA"
    },
    {
      "id": 1117,
      "name": "יעל",
      "name_eng": "YA'EL"
    },
    {
      "id": 577,
      "name": "יד בנימין",
      "name_eng": "YAD BINYAMIN"
    },
    {
      "id": 758,
      "name": "יד חנה",
      "name_eng": "YAD HANNA"
    },
    {
      "id": 1134,
      "name": "יד השמונה",
      "name_eng": "YAD HASHEMONA"
    },
    {
      "id": 358,
      "name": "יד מרדכי",
      "name_eng": "YAD MORDEKHAY"
    },
    {
      "id": 775,
      "name": "יד נתן",
      "name_eng": "YAD NATAN"
    },
    {
      "id": 64,
      "name": "יד רמב\"ם",
      "name_eng": "YAD RAMBAM"
    },
    {
      "id": 499,
      "name": "יפיע",
      "name_eng": "YAFI"
    },
    {
      "id": 3566,
      "name": "יפית",
      "name_eng": "YAFIT"
    },
    {
      "id": 798,
      "name": "יגל",
      "name_eng": "YAGEL"
    },
    {
      "id": 96,
      "name": "יגור",
      "name_eng": "YAGUR"
    },
    {
      "id": 1158,
      "name": "יהל",
      "name_eng": "YAHEL"
    },
    {
      "id": 811,
      "name": "יכיני",
      "name_eng": "YAKHINI"
    },
    {
      "id": 1295,
      "name": "יאנוח-ג'ת",
      "name_eng": "YANUH-JAT"
    },
    {
      "id": 753,
      "name": "ינוב",
      "name_eng": "YANUV"
    },
    {
      "id": 3647,
      "name": "יקיר",
      "name_eng": "YAQIR"
    },
    {
      "id": 417,
      "name": "יקום",
      "name_eng": "YAQUM"
    },
    {
      "id": 2026,
      "name": "ירדנה",
      "name_eng": "YARDENA"
    },
    {
      "id": 718,
      "name": "ירחיב",
      "name_eng": "YARHIV"
    },
    {
      "id": 183,
      "name": "ירקונה",
      "name_eng": "YARQONA"
    },
    {
      "id": 575,
      "name": "יסעור",
      "name_eng": "YAS'UR"
    },
    {
      "id": 828,
      "name": "ישרש",
      "name_eng": "YASHRESH"
    },
    {
      "id": 1227,
      "name": "יתד",
      "name_eng": "YATED"
    },
    {
      "id": 2660,
      "name": "יבנה",
      "name_eng": "YAVNE"
    },
    {
      "id": 46,
      "name": "יבנאל",
      "name_eng": "YAVNE'EL"
    },
    {
      "id": 759,
      "name": "יציץ",
      "name_eng": "YAZIZ"
    },
    {
      "id": 1044,
      "name": "יעף",
      "name_eng": "YE'AF"
    },
    {
      "id": 1144,
      "name": "ידידה",
      "name_eng": "YEDIDA"
    },
    {
      "id": 233,
      "name": "כפר ידידיה",
      "name_eng": "YEDIDYA"
    },
    {
      "id": 409,
      "name": "יחיעם",
      "name_eng": "YEHI'AM"
    },
    {
      "id": 9400,
      "name": "יהוד-מונוסון",
      "name_eng": "YEHUD-MONOSON"
    },
    {
      "id": 831,
      "name": "ירוחם",
      "name_eng": "YEROHAM"
    },
    {
      "id": 916,
      "name": "ישע",
      "name_eng": "YESHA"
    },
    {
      "id": 440,
      "name": "יסודות",
      "name_eng": "YESODOT"
    },
    {
      "id": 29,
      "name": "יסוד המעלה",
      "name_eng": "YESUD HAMA'ALA"
    },
    {
      "id": 1232,
      "name": "יבול",
      "name_eng": "YEVUL"
    },
    {
      "id": 134,
      "name": "יפעת",
      "name_eng": "YIF'AT"
    },
    {
      "id": 453,
      "name": "יפתח",
      "name_eng": "YIFTAH"
    },
    {
      "id": 2011,
      "name": "ינון",
      "name_eng": "YINNON"
    },
    {
      "id": 623,
      "name": "יראון",
      "name_eng": "YIR'ON"
    },
    {
      "id": 502,
      "name": "ירכא",
      "name_eng": "YIRKA"
    },
    {
      "id": 805,
      "name": "ישעי",
      "name_eng": "YISH'I"
    },
    {
      "id": 3607,
      "name": "ייט\"ב",
      "name_eng": "YITAV"
    },
    {
      "id": 3749,
      "name": "יצהר",
      "name_eng": "YIZHAR"
    },
    {
      "id": 452,
      "name": "יזרעאל",
      "name_eng": "YIZRE'EL"
    },
    {
      "id": 1112,
      "name": "יודפת",
      "name_eng": "YODEFAT"
    },
    {
      "id": 4007,
      "name": "יונתן",
      "name_eng": "YONATAN"
    },
    {
      "id": 240,
      "name": "יקנעם עילית",
      "name_eng": "YOQNE'AM ILLIT"
    },
    {
      "id": 241,
      "name": "יקנעם (מושבה)",
      "name_eng": "YOQNE'AM(MOSHAVA)"
    },
    {
      "id": 803,
      "name": "יושיביה",
      "name_eng": "YOSHIVYA"
    },
    {
      "id": 866,
      "name": "יטבתה",
      "name_eng": "YOTVATA"
    },
    {
      "id": 2009,
      "name": "יובל",
      "name_eng": "YUVAL"
    },
    {
      "id": 1226,
      "name": "יובלים",
      "name_eng": "YUVALIM"
    },
    {
      "id": 2742,
      "name": "זבארגה (שבט)",
      "name_eng": "ZABARGA"
    },
    {
      "id": 1079,
      "name": "צפרירים",
      "name_eng": "ZAFRIRIM"
    },
    {
      "id": 594,
      "name": "צפריה",
      "name_eng": "ZAFRIYYA"
    },
    {
      "id": 816,
      "name": "זנוח",
      "name_eng": "ZANOAH"
    },
    {
      "id": 975,
      "name": "זרזיר",
      "name_eng": "ZARZIR"
    },
    {
      "id": 815,
      "name": "זבדיאל",
      "name_eng": "ZAVDI'EL"
    },
    {
      "id": 413,
      "name": "צאלים",
      "name_eng": "ZE'ELIM"
    },
    {
      "id": 8000,
      "name": "צפת",
      "name_eng": "ZEFAT"
    },
    {
      "id": 799,
      "name": "זכריה",
      "name_eng": "ZEKHARYA"
    },
    {
      "id": 796,
      "name": "צלפון",
      "name_eng": "ZELAFON"
    },
    {
      "id": 1290,
      "name": "זמר",
      "name_eng": "ZEMER"
    },
    {
      "id": 818,
      "name": "זרחיה",
      "name_eng": "ZERAHYA"
    },
    {
      "id": 2064,
      "name": "זרועה",
      "name_eng": "ZERU'A"
    },
    {
      "id": 612,
      "name": "צרופה",
      "name_eng": "ZERUFA"
    },
    {
      "id": 788,
      "name": "זיתן",
      "name_eng": "ZETAN"
    },
    {
      "id": 9300,
      "name": "זכרון יעקב",
      "name_eng": "ZIKHRON YA'AQOV"
    },
    {
      "id": 1065,
      "name": "זמרת",
      "name_eng": "ZIMRAT"
    },
    {
      "id": 613,
      "name": "ציפורי",
      "name_eng": "ZIPPORI"
    },
    {
      "id": 584,
      "name": "זיקים",
      "name_eng": "ZIQIM"
    },
    {
      "id": 1213,
      "name": "צבעון",
      "name_eng": "ZIV'ON"
    },
    {
      "id": 1150,
      "name": "צופר",
      "name_eng": "ZOFAR"
    },
    {
      "id": 198,
      "name": "צופית",
      "name_eng": "ZOFIT"
    },
    {
      "id": 1111,
      "name": "צופיה",
      "name_eng": "ZOFIYYA"
    },
    {
      "id": 44,
      "name": "זוהר",
      "name_eng": "ZOHAR"
    },
    {
      "id": 1136,
      "name": "צוחר",
      "name_eng": "ZOHAR"
    },
    {
      "id": 567,
      "name": "צרעה",
      "name_eng": "ZOR'A"
    },
    {
      "id": 465,
      "name": "צובה",
      "name_eng": "ZOVA"
    },
    {
      "id": 3791,
      "name": "צופים",
      "name_eng": "ZUFIN"
    },
    {
      "id": 1113,
      "name": "צור הדסה",
      "name_eng": "ZUR HADASSA"
    },
    {
      "id": 276,
      "name": "צור משה",
      "name_eng": "ZUR MOSHE"
    },
    {
      "id": 1148,
      "name": "צור נתן",
      "name_eng": "ZUR NATAN"
    },
    {
      "id": 1345,
      "name": "צור יצחק",
      "name_eng": "ZUR YIZHAQ"
    },
    {
      "id": 774,
      "name": "צוריאל",
      "name_eng": "ZURI'EL"
    },
    {
      "id": 1221,
      "name": "צורית",
      "name_eng": "ZURIT"
    },
    {
      "id": 1180,
      "name": "צביה",
      "name_eng": "ZVIYYA"
    }
  ]