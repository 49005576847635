import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useEffect } from 'react';

function AddressInput({ onChange, addressFromGovId}) {
    const [address, setAddress] = React.useState('');

    useEffect(() => {
        onChange(address);
    }, [address]);
    const handleSelect = async (value) => {
        setAddress(value);
        try {
            onChange(value);
        } catch (error) {
            console.error('Error:', error);
        }
    };



    const inputStyle = {
        width: '100%',
        padding: '10px 15px',
        fontSize: '16px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    };

    return (
        <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
            searchOptions={{ componentRestrictions: { country: 'il' }, language: 'he' }}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <input
                        {...getInputProps({ placeholder: 'כתובת' })}
                        style={inputStyle}
                        value={addressFromGovId}
                    />
                    <div>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => (
                            <div {...getSuggestionItemProps(suggestion)}>
                                {suggestion.description}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
}

export default AddressInput;
