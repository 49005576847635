export const TagId = 0
export const TagEmployerId = 9
export const TagEmployerName = 10
export const TagFirstName = 1
export const TagLastName = 8
export const TagJob = 2
export const TagStatus = 3
export const TagDob = 11
export const TagPhoneNumber = 4
export const TagEdit = 5
export const TagDelete = 6
export const TagActions = 7
export const TagNone = 999

export const usersColumns = [
    {
        Header: "שם חברה",
        accessor: "companyName",
        tag: TagEmployerName,
    },
    {
        Header: "מס׳ עובד",
        accessor: "id",
        tag: TagId,  
    },
    {
      Header: "שם מלא",
      accessor: "fullName",
      tag: TagFirstName,
    },
    {
      Header: "תאריך לידה",
      accessor: "details.dob",
      tag: TagDob,
    },
    {
        Header: "סטטוס",
        accessor: "status",
        tag: TagStatus,
    },  
    {
        Header: "טלפון",
        accessor: "phoneNumber",
        tag: TagPhoneNumber,
    },
    {
        Header: "פעולות נוספות",
        accessor: "missingInformationStatus",
        tag: TagActions,
    }
  ];

export const usersList = [
    {
      "employer_id": 7,
      "employee_id": 134,
      "first_name":"אבי הלוי",
      "job_title": "מחסנאי", 
      "status": "פעיל",
      "phone_number": "0589013714",
      "actions": ["edit","delete"], 
    },
    {
        "employer_id": 7,
        "employee_id": 122,
        "first_name":"רון כהן",
        "job_title": "תפעול", 
        "status": "פעיל",
        "phone_number": "0521213444",
        "actions": ["edit","delete"], 
    },
    {
        "employer_id": 7,
        "employee_id": 134,
        "first_name":"אלירן אבו",
        "job_title": "מלגזן", 
        "status": "פעיל",
        "phone_number": "0500015434",
        "actions": ["edit","delete"], 
    },
    {
        "employer_id": 7,
        "employee_id": 134,
        "first_name":"מוחמד אבו יונס",
        "job_title": "עובד כללי", 
        "status": "פעיל",
        "phone_number": "0511015655",
        "actions": ["edit","delete"], 
    },
    {
        "employer_id": 7,
        "employee_id": 134,
        "first_name":"טאהר אלה",
        "job_title": "מחסנאי", 
        "status": "פעיל",
        "phone_number": "0589013714",
        "actions": ["edit","delete"], 
    },
    {
        "employer_id": 7,
        "employee_id": 134,
        "first_name":"נדב כהן",
        "job_title": "אחזקה כללי", 
        "status": "פעיל",
        "phone_number": "0587115387",
        "actions": ["edit","delete"], 
    },
    {
        "employer_id": 7,
        "employee_id": 134,
        "first_name":"מקסים ולנצ׳סקו",
        "job_title": "חשמלאי אחזקה", 
        "status": "פעיל",
        "phone_number": "0526598111",
        "actions": ["edit","delete"], 
    },
    {
        "employer_id": 7,
        "employee_id": 134,
        "first_name":"ברק רונן",
        "job_title": "מנהל מחסן", 
        "status": "פעיל",
        "phone_number": "0503420999",
        "actions": ["edit","delete"], 
    },
    {
        "employer_id": 7,
        "employee_id": 134,
        "first_name":"יוני גז",
        "job_title": "אחראי קליטת סחורה", 
        "status": "פעיל",
        "phone_number": "0520022455",
        "actions": ["edit","delete"], 
    }
  ];


  export const marinadaEmployeesList = [
    {
      "employee_id": 2,
      "first_name":"אבו חומוס עאמד",
      "job_title": "אחמ״ש", 
      "status": "פעיל",
      "phone_number": "0589013714",
      "actions": ["edit","delete"], 
    },
    {
        "employee_id": 12,
        "first_name":"אבוטבול פרידה",
        "job_title": "מלצר", 
        "status": "פעיל",
        "phone_number": "0521213444",
        "actions": ["edit","delete"], 
    },
    {
        "employee_id": 13,
        "first_name":"טאה אחמד",
        "job_title": "מלצר", 
        "status": "פעיל",
        "phone_number": "0500015434",
        "actions": ["edit","delete"], 
    },
    {
        "employee_id": 15,
        "first_name":"טאה ראגח",
        "job_title": "טבח", 
        "status": "פעיל",
        "phone_number": "0511015655",
        "actions": ["edit","delete"], 
    },
    {
        "employee_id": 22,
        "first_name":"טאהר אלה",
        "job_title": "מלצר", 
        "status": "פעיל",
        "phone_number": "0589013714",
        "actions": ["edit","delete"], 
    },
    {
        "employee_id": 30,
        "first_name":"יעקב גילה",
        "job_title": "מנהל מסעדה", 
        "status": "פעיל",
        "phone_number": "0587115387",
        "actions": ["edit","delete"], 
    },
    {
        "employee_id": 33,
        "first_name":"פראחן אשרף",
        "job_title": "עובד מטבח", 
        "status": "פעיל",
        "phone_number": "0526598111",
        "actions": ["edit","delete"], 
    },
    {
        "employee_id": 35,
        "first_name":"פרחאן מוחמד",
        "job_title": "אחזקה", 
        "status": "פעיל",
        "phone_number": "0503420999",
        "actions": ["edit","delete"], 
    }
  ];