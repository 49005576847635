import React, { useState } from "react";
import NewMessageModal from "./NewMessageModal";
import {Button} from "@chakra-ui/react";
import {MultiSelect} from "chakra-multiselect";

function MessageTable() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [value, setValue] = useState([])

    const handleTemplateSubmit = (template) => {
        // Here you would save the template to your database or pass it to another component
        setIsModalOpen(false);
    }
    const items = [
        'Neptunium',
        'Plutonium',
        'Americium',
        'Curium',
    ]
    const _options = items.map((label) => ({ label, value: label.toLowerCase() }))

    return (
        <div>
            <Button
                backgroundColor='rgb(0, 133, 230)'
                textColor='white'
                onClick={() => setIsModalOpen(true)}>צור הודעה חדשה</Button>
            <NewMessageModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSubmit={handleTemplateSubmit} />
        </div>
    );
}

export default MessageTable;
