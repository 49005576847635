import {Box} from "@chakra-ui/react";
import React from "react";
import ReportsTable from "./components/ReportsTable";

export default function Reports() {
    // Chakra Color Mode
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <ReportsTable />
        </Box>
    );
}