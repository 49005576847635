import React, {useEffect, useState} from "react";
import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import BarChart from "components/charts/BarChart";
import Card from "components/card/Card.js";
import {
  barChartDataDailyTraffic,
  barChartOptionsDailyTraffic, lineChartOptionsTotalSpent,
} from "variables/charts";

import {getRecentMonths} from "../../../../functions/dateUtils"

export default function DailyLoginsRecentMonths(props) {
  const { history, ...rest } = props;

  const [ lastMonth, setLastMonth] = useState(0)
  const [ monthlyLogins, setMonthlyLogins] = useState([{
    data: [0],
  }])

  useEffect(() => {
    if(history && history.length > 0) {
      setLastMonth(history[history.length-1])
      setMonthlyLogins([{
        data: history
      }])
    }
  }, [history]);

  useEffect(()=>{
    barChartOptionsDailyTraffic.xaxis.categories = getRecentMonths();
  },[])

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Card align='center' direction='column' w='100%' {...rest}>
      <Flex justify='space-between' align='start' px='10px' pt='5px'>
        <Flex flexDirection='column' align='start' me='20px'>
          <Flex w='100%'>
            <Text
              me='auto'
              color={textColor}
              fontSize='22px'
              fontWeight='700'
              lineHeight='100%'>
              כניסות חודשיות ל-Payro
            </Text>
          </Flex>
          <Flex align='end'>
            <Text
                mt='5px'
              color={textColor}
              fontSize='30px'
              fontWeight='700'
              lineHeight='100%'>
              {lastMonth}
            </Text>
            <Text
              ms='6px'
              color='secondaryGray.600'
              fontSize='m'
              fontWeight='500'>
            בחודש האחרון
            </Text>
          </Flex>
        </Flex>
        <Flex align='center'>
        </Flex>
      </Flex>
      <Box h='240px' mt='auto'>
        <BarChart
            chartData={monthlyLogins}
            chartOptions={barChartOptionsDailyTraffic}
        />
      </Box>
    </Card>
  );
}
