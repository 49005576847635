import { Box } from "@chakra-ui/react";
import UsersTable from "./components/UsersTable";

export default function Users() {
    // Chakra Color Mode
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <UsersTable />
      </Box>
   );
  }