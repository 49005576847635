export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];
export const columnsDataComplex = [
  {
    Header: "שם",
    accessor: "fullName",
  },
  {
    Header: "סניף",
    accessor: "branchName",
  },
  {
    Header: "סכום",
    accessor: "amount",
  },
  {
    Header: "סטטוס",
    accessor: "status",
  },
  {
    Header: "תאריך",
    accessor: "createdAt",
  }
];
