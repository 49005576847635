
export const TagId = 0
export const TagName = 1
export const TagAmount = 2
export const TagGovId = 3
export const TagCompanyName = 10
export const TagBank = 4
export const TagBranchName = 5
export const TagAccount = 6
export const TagDate = 7
export const TagStatus = 8
export const TagActions = 9

export const tableColumns = [
    {
        Header: "שם מלא",
        accessor: "fullName",
        tag: TagName,
        width: "150px",
    },
    {
        Header: "סניף",
        accessor: row => (row && row.branch) || row.companyName,
        tag: TagBranchName,
        width: "150px",
    },
    {
        Header: "סכום",
        accessor: "amount",
        tag: TagAmount,
        width: "150px"
    },
    {
        Header: "סטטוס",
        accessor: "status",
        tag: TagStatus,
        width: "150px"
    },
    {
        Header: "תאריך",
        accessor: "createdAt",
        tag: TagDate,
        width: "150px"
    }
];
