export const TagId = 0
export const TagTitle = 1
export const TagStatus = 2
export const TagActions = 3

export const formsColumns = [
    {
        Header: "מס׳ טופס",
        accessor: "id",
        tag: TagId,
    },
    {
      Header: "כותרת טופס",
      accessor: "title",
      tag: TagTitle,
    },
    {
        Header: "סטטוס",
        accessor: "status",
        tag: TagStatus,
    },
    {
        Header: "",
        accessor: "action",
        tag: TagActions,
    },
];
