import { useHistory } from "react-router-dom";

export function NavigateTo(page) {
  const history = useHistory();
  if(page.value){
    history.push(page.value);
  }
  // do nothing
}

export const Page = {
	SignIn: {"value": "/auth/sign-in"},
	OTP: {"value": "/auth/otp"}
}