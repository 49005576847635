import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import React, {useEffect, useMemo} from "react";
import {
    useSortBy,
    useTable,
} from "react-table";
import {TagActions, TagId, TagName} from "../../companies/components/companiesTableData";
import Card from "components/card/Card";
import EmployeesApi from "../../../../api/employees";
import {prettifyDate} from "../../../../functions/dateUtils";

export const tableColumns = [
    {
        Header: "חברה",
        accessor: "companyName",
        tag: TagId,
        width: "10px",
    },
    {
        Header: "שם",
        accessor: "fullName",
        tag: TagName,
        width: "150px"
    },
    {
        Header: "תאריך",
        accessor: "registrationDate",
        tag: TagActions,
        width: "150px"
    }
];

export default function RegistrationsTable(props) {

    const columns = useMemo(() => tableColumns, [tableColumns]);
    const [data, setData] = React.useState([])

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    const refreshRegistrations = ()=> {
        EmployeesApi.getUsersRegistrations().then(res => {
            if(res.data != null) {
                let array = res.data.reverse()
                setData(array);
            }
        }).catch(e => {
            })
    }

    useEffect(() => {
        refreshRegistrations()
    }, []);

    return (
        <Card
            direction='column'
            w='100%'
            px='0px'
            overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text
                    color={textColor}
                    fontSize='22px'
                    fontWeight='700'
                    lineHeight='100%'>
                    נרשמו לאחרונה
                </Text>
            </Flex>
            <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
                <Thead>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                                <Th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    pe='10px'
                                    key={index}
                                    borderColor={borderColor}>
                                    <Flex
                                        justify='space-between'
                                        align='center'
                                        fontSize={{ sm: "10px", lg: "12px" }}
                                        color='gray.400'>
                                        {column.render("Header")}
                                    </Flex>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()} key={index}>
                                {row.cells.map((cell, index) => {
                                    let data = "";
                                    if (cell.column.Header === "שם" || cell.column.Header === "חברה") {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        );
                                    } else if (cell.column.Header === "תאריך") {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {prettifyDate(cell.value)}
                                            </Text>
                                        );
                                    }
                                    return (
                                        <Td
                                            {...cell.getCellProps()}
                                            key={index}
                                            fontSize={{ sm: "14px" }}
                                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                            borderColor='transparent'>
                                            {data}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </Card>
    );
}
