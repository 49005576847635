import React, {useEffect, useState} from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, useToast, Input
} from "@chakra-ui/react";
import EmployeesApi from "../../../../api/employees";

export default function AdminTestIntegrationCredentialsModal(props) {

    let { isOpen, onClose, company, callBack,
        ...rest } = props;
    const [id, setId] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const toast = useToast()

    useEffect(() => {
        if(!isOpen) {
            setId("")
            setPhoneNumber("")
        }
    }, [isOpen]);

    const handleTest =() => {
        if (id === "" || phoneNumber === "") {
            toast({
                title: "נא למלא את כל השדות",
                position: 'top',
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        EmployeesApi.testAlfonIntegration(id, phoneNumber, company.id).then((res) => {
            const status = res.data.status;
            const successStatuses = ["success", "user_exists_different_company", "user_exists"];
            if(!successStatuses.includes(status)) {
                toast({
                    title: res.data.message,
                    position: 'top',
                    status: "error",
                    duration: 8000,
                    isClosable: true,
                });
            }
            else {
                toast({
                    title: res.data.message,
                    position: 'top',
                    status: "success",
                    duration: 8000,
                    isClosable: true,
                });
            }
            }, (error) => {
                toast({
                    title: error.response.data.message,
                    status: "error",
                    duration: 8000,
                    isClosable: true,
                });
            });

    }

    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               isCentered>
            <ModalOverlay />
            <ModalContent maxH="600px" maxW={'400px'}>
                <ModalHeader>בדיקת עובד באלפון</ModalHeader>
                <ModalBody>

                    <Input mt={'10px'}  placeholder='מספר זיהוי' onChange={(event) => {
                        setId(event.target.value);
                    }}/>

                    <Input mt={'10px'} placeholder='מספר טלפון' onChange={(event) => {
                        setPhoneNumber(event.target.value);
                    }}/>

                    <Button mt={'10px'} onClick={handleTest} backgroundColor={'green.200'}>בדיקה</Button>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>סגור</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
