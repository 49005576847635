import {
    Input
  } from "@chakra-ui/react";
  
export function InputMaxLength(props) {
    const { maxLength,
        fontSize,
        type,
        placeholder,
        mb,
        fontWeight,
        size, 
        onChange,
        ...rest } = props;
    
    const onInput = (e) => {
        if (e.target.value.length > e.target.maxLength) 
          e.target.value = e.target.value.slice(0, e.target.maxLength);
      }
    
    return (
      <>
        <Input
            onChange={onChange}
            fontSize={fontSize}
            type={type}
            placeholder={placeholder}
            mb={mb}
            fontWeight={fontWeight}
            size={size}
            maxLength={maxLength}
            onInput={onInput}
            textAlign='center'/>
      </>
    );
  }