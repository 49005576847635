import {useEffect, useState} from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Text, useToast,
} from "@chakra-ui/react";
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import EmployeesApi from "../../../../api/employees";

export default function AddNewEmployeesFileModal(props) {

    let { isOpen, onClose, callBack,
        ...rest } = props;

    const [error, setError] = useState(undefined);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        setSelectedFile(null);
    }, [isOpen])

    const onDrop = useCallback(acceptedFiles => {
        // Set the file selected state
        setSelectedFile(acceptedFiles[0]);
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const toast = useToast()

    const uploadFile = () => {
        EmployeesApi.uploadFile(selectedFile).then(res => {
            if(res.data) {
                toast({
                    title: `קובץ נשלח בהצלחה`,
                    position: 'top',
                    status: 'success',
                    isClosable: false,
                })
                setTimeout(() => {
                    onClose()
                }, 2000);
            }
        }).catch(e => {
            toast({
                title: `שגיאה בקובץ`,
                position: 'top',
                status: 'error',
                isClosable: false,
            })
        })
    }


    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>הוספת קובץ נתוני עובדים חדש</ModalHeader>
                <ModalBody>
                    <h4 style={{
                        fontSize: "1.2em",
                        color: "red",
                        textAlign: "center",
                        fontWeight: 400,
                        transition: ".2s all"}}>
                        {error}
                    </h4>

                    <div {...getRootProps()} style={{border: '1px solid black', padding: '20px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                        <input {...getInputProps()} />
                        {
                            selectedFile ?
                                <Text>{selectedFile.name}</Text> :
                                isDragActive ?
                                    <Text>גרור את קובץ נתוני העובדים לכאן</Text> :
                                    <Text>בחר את קובץ נתוני העובדים העדכני</Text>
                        }
                    </div>


                </ModalBody>
                <ModalFooter>
                    { selectedFile && <Button onClick={uploadFile}>שלח</Button> }
                    <Button onClick={onClose}>סגור</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
