import MockApi from "./mockApi";
import {API} from "./env";
import {axiosInstance} from "./httpClient";


export default class EmployeesApi {


    static async findEmployees(query){
        try {
            const result = await (this.searchByQuery(`/employees/search`, query));
            return result.data;
        } catch(err){
            throw err;
        }
    }

    static isDefaultQuery(query) {
        const defaultQuery = {
            page: 1,
            resultsPerPage: 10
        };

        // Check if query has the same number of keys as defaultQuery
        if (Object.keys(query).length !== Object.keys(defaultQuery).length) {
            return false;
        }

        // Check if all keys and values in defaultQuery match the corresponding ones in query
        return Object.keys(defaultQuery).every(key => query[key] === defaultQuery[key]);
    }

    static async searchByQuery(path, query, axiosConfig = {}){
        if(MockApi.IsActive) {
            return MockApi.searchByQuery(path, query);
        }
        if (this.isDefaultQuery(query)) {
            // return this as async function
            return Promise.resolve({ data: { users: [], totalPages: 0 } })
        }

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;

        const {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        } = axiosConfig;

        return axiosInstance.post(`${API}${path}`, query, {
            ...axiosConfig,
            headers,
        });
    }

    static getEmployeeDetails = (aCompanyId, aUserId) => {
        if(MockApi.IsActive) {
            return MockApi.GetEmployees();
        }
        if(aCompanyId === null){
            return;
        }
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            params: {
                userId: aUserId
            }
        };

        return axiosInstance.get(`${API}/employees/details?companyId=`+aCompanyId , config);
    };

    static addEmployee = (employee) => {
         let userInfo = JSON.parse(localStorage.getItem("user"));
         let accessToken = userInfo.token;
         let employeeJson = {}
         if(employee.employerId) {
             employeeJson['employerId'] = employee.employerId
         }
         employeeJson['firstName'] = employee.firstName
         employeeJson['lastName'] = employee.lastName
         employeeJson['phoneNumber'] = employee.phoneNumber
         employeeJson['roles'] = employee.roles
         employeeJson['details'] = {}
         employeeJson['details']['govId'] = employee.govId

        if(employee.cityId) {
            employeeJson['details']['cityId'] = employee.cityId
        }
        if(employee.dob) {
            employeeJson['details']['dob'] = employee.dob
        }
        if(employee.gender) {
            employeeJson['details']['gender'] = employee.gender
        }
        if(employee.internalId) {
            employeeJson['details']['internalId'] = employee.internalId
        }
        if(employee.jobTitle) {
            employeeJson['details']['jobTitle'] = employee.jobTitle
        }
        if(employee.jobDepartment) {
            employeeJson['details']['jobDepartment'] = employee.jobDepartment
        }
         if(employee.bankId) {
             employeeJson['details']['bankAccountDetails'] = {}
             employeeJson['details']['bankAccountDetails']['bankId'] = employee.bankId
             employeeJson['details']['bankAccountDetails']['accountId'] = employee.accountId
             employeeJson['details']['bankAccountDetails']['branchId'] = employee.branchId
         }
         if(employee.salaryPerHour) {
             employeeJson['details']['salaryDetails'] = {}
             employeeJson['details']['salaryDetails']['hourlyGrossRate'] = employee.salaryPerHour
         }
         else if (employee.salaryPerMonth) {
             employeeJson['details']['salaryDetails'] = {}

             employeeJson['details']['salaryDetails']['monthlyGrossRate'] = employee.salaryPerMonth
         }
        const body = JSON.stringify({
             "data": [employeeJson],
             });

        const config = {
             method: 'post',
             headers: {
                 'Content-Type': 'application/json',
                 'Authorization': 'Bearer ' + accessToken
             }
             };

         return axiosInstance.post(`${API}/employees`,body, config);
     };

    static addEmployees = (employees) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const body = JSON.stringify({
            "data": employees,
        });

        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/employees`,body, config);
    };

    static delete = (userId) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;

        const body = JSON.stringify({
            "id": userId
            });

        const config = {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            data: body
            };


        return axiosInstance.delete(`${API}/employees`, config);
    };

    static update = (employeeJson) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;

        const body = JSON.stringify(employeeJson);

        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            data: body
            };


        return axiosInstance.post(`${API}/employees/update`,body, config);
    };

    static getUsersRegistrations = () => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.get(`${API}/users/registrations`, config);
    };

    static uploadFile = (file, companyId) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let formData = new FormData();
        formData.append("file", file)
        if(companyId) {
            formData.append("companyId", companyId)
        }
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + accessToken
            }
        };
        return axiosInstance.post(`${API}/employees/upload/`, formData, config);
    }

    static testAlfonIntegration = (id, phoneNumber, companyId) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const body = JSON.stringify({
            "id": id,
            "phoneNumber": phoneNumber,
            "companyId": companyId});

        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/auth/test/`, body, config);
    }
}

