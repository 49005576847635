import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    FormLabel,
    ModalFooter,
    SimpleGrid,
    Text, Input,
    useToast,
} from "@chakra-ui/react";

import {generateDailyTransfers} from "../../../../functions/generatePayrollFile";
import React, {useEffect, useRef, useState} from 'react';
import ReportsApi from "../../../../api/reports";

export default function GenerateTransfersModal(props) {

    let { isOpen, onClose, applications } = props;
    const [transfersArray, setTransfersArray] = React.useState([])
    const [validatedTransfersIds, setValidatedTransfersIds] = React.useState([])
    const [referenceInputVisible, setReferenceInputVisible] = React.useState(false)
    const [referenceValue, setReferenceValue] = useState("");
    const [activityReferenceValue, setActivityReferenceValue] = useState("");

    useEffect(() => {
        if(isOpen) {
            resetState();
        }
    }, [isOpen]);

    const toast = useToast()
    const inputRef = useRef(null);

    const resetState = () => {
        setTransfersArray([]);
        setValidatedTransfersIds([]);
        setReferenceInputVisible(false);
        setReferenceValue("");
        setActivityReferenceValue("");
    }

    const getTransfers = ()=> {
        ReportsApi.getTransfersReport().then(res => {
            setTransfersArray(res.data);
            generateDailyTransfers(res.data)
        }).catch(e => {
            setTransfersArray([])
        })
    }

    const handleValidateFileClick = () => {
        // 👇️ open file input box on click of other element
        inputRef.current.click();
    };

    const handleFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }

        ReportsApi.validateDailyTransfersFile(fileObj).then(res => {
            setValidatedTransfersIds(res.data)
            setReferenceInputVisible(true);
            toast({
                title: `קובץ העברות תקין`,
                position: 'top',
                status: 'success',
                isClosable: false,
            })
        }).catch(e => {
            toast({
                title: `שגיאה בקובץ הערות`,
                position: 'top',
                status: 'error',
                isClosable: false,
            })
        })
    };


    const approveTransfersToTransfer = () => {

        ReportsApi.approveTransfersToTransfer(validatedTransfersIds, referenceValue, activityReferenceValue).then(res => {

            toast({
                title: `העברות אושרו בהצלחה`,
                position: 'top',
                status: 'success',
                isClosable: false,
            })


            resetState();

        }).catch(e => {
            toast({
                title: `שגיאה באישור העברות`,
                position: 'top',
                status: 'error',
                isClosable: false,
            })
        })
    }

    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               isCentered
               closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>יצירת קובץ העברות יומיות</ModalHeader>
                <ModalBody>

                    <SimpleGrid
                        marginTop='30px'
                        columns={{sm: 1}}
                        gap='20px'
                        mb='0px'>

                        <Text>שלב 1.</Text>

                        <Button backgroundColor='green.200' onClick={getTransfers}>הורד קובץ</Button>

                        <input
                            style={{display: 'none'}}
                            ref={inputRef}
                            type="file"
                            onChange={handleFileChange}
                        />

                        <Text>שלב 2.</Text>
                        <Button backgroundColor='blue.300' onClick={handleValidateFileClick}>בדוק קובץ העברות</Button>


                        <Text>שלב 3.</Text>

                        { referenceInputVisible && <>
                            <Input placeholder='אסמכתא' onChange={(event) => {
                                setReferenceValue(event.target.value);
                            }} />

                            <Input placeholder='מספר תנועה' onChange={(event) => {
                                setActivityReferenceValue(event.target.value);
                            }} />
                        </>}

                        <Button backgroundColor='gray.300' onClick={approveTransfersToTransfer}>אשר העברות</Button>

                    </SimpleGrid>

                    <br/>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>סגור</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
