import React from "react";

export const TagId = 0
export const TagName = 1
export const TagAmount = 2
export const TagGovId = 3
export const TagCompanyName = 10
export const TagBank = 4
export const TagBranch = 5
export const TagAccount = 6
export const TagDate = 7
export const TagStatus = 8
export const TagActions = 9

export const statusOptions = [
    { val: '', label: 'הכל' },
    { val: '0', label: 'בוטל' },
    { val: '100', label: 'נדחה' },
    { val: '300', label: 'ממתין להעברה' },
    { val: '500', label: 'הועבר' },
    { val: '700', label: 'הושלם' },
];

export const tableColumns = [
    {
        Header: "חברה",
        accessor: "companyName",
        tag: TagName,
        width: "150px",
    },
    {
        Header: "שם עובד",
        accessor: "fullName",
        tag: TagName,
        width: "150px",
    },
    {
        Header: "סכום",
        accessor: "amount",
        tag: TagAmount,
        width: "150px"
    },
    {
        Header: "סטטוס",
        accessor: "status",
        tag: TagStatus,
        width: "150px"
    },
    {
        Header: "תאריך",
        accessor: "createdAt",
        tag: TagDate,
        width: "150px"
    },
    {
        Header: "פעולות נוספות",
        accessor: "actions",
        tag: TagActions,
    }

];