export const TagId = 0
export const TagFirstName = 1
export const TagLastName = 8
export const TagJob = 2
export const TagStatus = 3
export const TagPhoneNumber = 4
export const TagEdit = 5
export const TagDelete = 6
export const TagActions = 7
export const TagBranch = 10

export const employeesColumns = [
    {
        Header: "מס׳ עובד",
        accessor: "id",
        tag: TagId,
    },
    {
      Header: "שם מלא",
      accessor: "fullName",
      tag: TagFirstName,
    },
    {
        Header: "סניף",
        accessor: row => (row && row.details && row.details.branch) || row.companyName,
        tag: TagBranch,
    },
    {
      Header: "תפקיד",
      accessor: "details.jobTitle",
      tag: TagJob,
    },
    {
        Header: "סטטוס",
        accessor: "status",
        tag: TagStatus,
    },
    {
        Header: "טלפון",
        accessor: "phoneNumber",
        tag: TagPhoneNumber,
    },
    {
        Header: "פעולות נוספות",
        accessor: "actions",
        tag: TagActions,
    },
  ];
