/* eslint-disable */
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  useDisclosure,
    MenuList, MenuItem
} from "@chakra-ui/react";

  import { ChevronDownIcon } from '@chakra-ui/icons'

import Card from "components/card/Card";
import React, { useEffect, useMemo } from "react";
import {
  useSortBy,
  useTable,
} from "react-table";
import CompaniesApi from "../../../../api/companies"
import { tableColumns, TagActions, TagId, TagName } from "./companiesTableData";
import AddNewCompanyModal from "./AddNewCompanyModal";
import EditCompanyModal from "./EditCompanyModal";
import TaReportCompanyModal from "./TaReportCompanyModal";
import CompanyDetailsModal from "./CompanyDetailsModal";

export default function CompaniesTable() {
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const [data, setData] = React.useState([])
  const [selectedCompany, setSelectedCompany] = React.useState(null)

  const refreshCompanies = ()=> {
    CompaniesApi.getAll().then(res => {
      setData(res.data);
   })
   .catch(e => {
   })
  }

  useEffect(() => {
    refreshCompanies()
  }, []);

  const handleDetailsClicked = (cellRow) => {
    const selectedCompany = data[cellRow.index];
    setSelectedCompany(selectedCompany)
    onCompanyDetailsOpen()
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const {
    isOpen: isCompanyDetailsOpen,
    onOpen: onCompanyDetailsOpen,
    onClose: onCompanyDetailsClose
  } = useDisclosure()

  const {
    isOpen: isNewCompanyOpen,
    onOpen: onNewCompanyOpen,
    onClose: onNewCompanyClose
  } = useDisclosure()


  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Button
        backgroundColor='rgb(0, 133, 230)'
        textColor='white'
        onClick={onNewCompanyOpen}
        >הוספת חברה</Button>

      </Flex>
      <AddNewCompanyModal callBack={refreshCompanies} isOpen={isNewCompanyOpen} onClose={onNewCompanyClose} />
      <CompanyDetailsModal company={selectedCompany} isOpen={isCompanyDetailsOpen} onClose={onCompanyDetailsClose} />

      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  let cellWidth = cell.column.width;
                  if (cell.column.tag === TagId ||
                    cell.column.tag === TagName) {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  }
                  else if (cell.column.tag === TagActions) {
                    data = (
                      <Flex align='center'>
                        <Button variant={'brand'} onClick={() => handleDetailsClicked(cell.row)} >פרטים</Button>
                      </Flex>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      width={cellWidth}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
