import React from 'react';
import Select from 'react-select';
import { useField, useFormikContext } from 'formik';

const FormikReactSelect = ({ name, options,placeholder, ...restProps }) => {
    const [field] = useField(name);
    const { setFieldValue } = useFormikContext();

    const handleChange = (option) => {
        setFieldValue(name, option ? option.value : '');
    };

    const selectedOption = options.find(option => option.value === field.value);

    return (
        <Select
            {...field}
            {...restProps}
            value={selectedOption}
            onChange={handleChange}
            options={options}
            placeholder={placeholder}

        />
    );
};

export default FormikReactSelect;
