import React, {useEffect, useMemo} from "react";
import {useSortBy, useTable} from "react-table";
import {
    Button,
    Flex,
    Icon,
    Modal, ModalBody, ModalContent, ModalFooter, ModalHeader,
    ModalOverlay, Select, SimpleGrid,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue, useDisclosure,
    useToast
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import {prettifyDateToTime} from "../../../../functions/dateUtils";
import IntegrationsApi from "../../../../api/integrations";
import {MdCheckCircle, MdOutlineError} from "react-icons/md";
import {exportErrorsFile} from "../../../../functions/generatePayrollFile";

export const TagDate = 0
export const TagCompany = 1
export const TagProvider = 2
export const TagDataCount = 3
export const TagErrorsCount = 4
export const TagStatus = 5

export const tableColumns = [
    {
        Header: "Date",
        accessor: "createdAt",
        tag: TagDate,
        width: "150px"
    },
    {
        Header: "Company",
        accessor: "companyName",
        tag: TagCompany,
        width: "10px",
    },
    {
        Header: "Provider",
        accessor: "integrationProviderName",
        tag: TagProvider,
        width: "150px"
    },
    {
        Header: "Data Count",
        accessor: "rowsAffected",
        tag: TagDataCount,
        width: "150px"
    },
    {
        Header: "Errors Count",
        accessor: "errorsCount",
        tag: TagErrorsCount,
        width: "150px"
    },
    {
        Header: "Status",
        accessor: "status",
        tag: TagStatus,
        width: "150px"
    },
];

function PipelineModal(props) {

    let { isOpen, onClose, pipeline } = props;
    const toast = useToast()
    const [pipelineInformation, setPipelineInformation] = React.useState(undefined)
    const [errorsList, setErrorsList] = React.useState([])

    useEffect(() => {
        if(isOpen && pipeline) {
            setPipelineInformation(pipeline)
            getPipelineErrorsList(pipeline.id)
        } else {
            setPipelineInformation(undefined)
            setErrorsList([])
        }
    }, [isOpen, pipeline]);

    const handleDownloadFileClick = () => {
        IntegrationsApi.downloadIntegrationFile(pipelineInformation.id).then(res => {

            try {
                let fileContent;
                let fileExtension;
                let isJsonFile = res.data instanceof Array;
                if(isJsonFile){
                    fileContent = JSON.stringify(res.data)
                    fileExtension = ".json"
                    const blob = new Blob([fileContent], {
                        type: "application/octet-stream",
                    });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download =  'file' + fileExtension;
                    a.click();
                } else {
                    fileContent = res.data
                    let a = document.createElement('a');
                    a.href = 'data:application/octet-stream;base64,' + fileContent;
                    a.download = "file" + fileExtension;
                    a.click();
                }
            } catch (e) {
            }

        }).catch(e => {
        })
    }

    const handleRunAgainClick = () => {
        IntegrationsApi.triggerIntegrationAction(pipelineInformation.integrationActionId).then(res => {
            toast({
                title: `Data Pipeline Started`,
                position: 'top',
                status: 'success',
                isClosable: false,
            })
        }).catch(e => {
            toast({
                title: `Data Pipeline Failure`,
                position: 'top',
                status: 'error',
                isClosable: false,
            })
        })
    }


    const handleDownloadErrorsFileClick = () => {
        exportErrorsFile(errorsList)
    }

    const getPipelineErrorsList = (pipelineId) => {
        IntegrationsApi.getIntegrationsErrors(pipelineId, null).then(res => {
            if(res.data != null) {
                setErrorsList(res.data)
            }
        }).catch(e => {
        })
    }

    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               isCentered
               closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent maxW="1000px" dir={'ltr'}>
                <ModalHeader>Data Pipeline</ModalHeader>
                <ModalBody>

                    <SimpleGrid
                        marginTop='30px'
                        columns={{sm: 1}}
                        gap='20px'
                        mb='0px'>

                        <Flex flexDirection={'row'}>
                            <Button backgroundColor={'green.200'} width={'200px'} onClick={handleRunAgainClick}>Run Pipeline</Button>

                            <Button marginLeft={'50px'} backgroundColor={'gray.200'} width={'280px'} onClick={handleDownloadFileClick}>Download Integration Response</Button>

                            <Button marginLeft={'50px'} backgroundColor={'red.200'} width={'200px'} onClick={handleDownloadErrorsFileClick}>Download Errors Report</Button>

                        </Flex>

                        <Flex flexDirection={'column'}>
                            {(errorsList && errorsList.length > 0) &&
                                <Text fontWeight={'bold'} fontSize={'18px'} textColor={'red'}>Errors</Text>
                            }

                            {(errorsList && errorsList.length > 0) &&
                            <div  style={{
                                height: '400px',
                                overflow:"scroll",
                                outline:"0",
                                border: "1px solid #FFFFFF50"}} >

                                    <Flex flexDirection={'column'} marginBottom={'20px'}>
                                        {'\n'}
                                        {errorsList.map((value) => (
                                            <h4 style={{
                                                fontSize: "1em",
                                                textAlign: "right",
                                                fontWeight: '600',
                                                transition: ".2s all"}}>
                                                <br/>
                                                <Text align={'left'}>{JSON.stringify(value).replace(/\\/g,'')}</Text>
                                            </h4>
                                        ))}
                                    </Flex>
                            </div>
                            }

                        </Flex>


                    </SimpleGrid>

                    <br/>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default function PipelinesTable(props) {

    const columns = useMemo(() => tableColumns, [tableColumns]);
    const [data, setData] = React.useState([])
    const [selectedPipeline, setSelectedPipeline] = React.useState(undefined)

    const {
        isOpen: isPipelineModalOpen,
        onOpen: onPipelineModalOpen,
        onClose: onPipelineModalClose
    } = useDisclosure()

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    const refreshDataPipelines = ()=> {
        IntegrationsApi.getIntegrationsResults().then(res => {
            if(res.data != null) {
                setData(res.data);
            }
        }).catch(e => {
        })
    }

    useEffect(() => {
        refreshDataPipelines()
    }, []);

    const handlePipelineDetailsClick = (cellRow) => {
        setSelectedPipeline(data[cellRow.index])
        onPipelineModalOpen()
    }

    return (
        <Card
            direction='column'
            w='100%'
            px='0px'
            overflowX={{ sm: "scroll", lg: "hidden" }}>
            <PipelineModal pipeline={selectedPipeline} isOpen={isPipelineModalOpen} onClose={onPipelineModalClose} />
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text
                    color={textColor}
                    fontSize='22px'
                    fontWeight='700'
                    lineHeight='100%'>
                    Pipelines
                </Text>
            </Flex>
            <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
                <Thead>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                                <Th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    pe='10px'
                                    key={index}
                                    borderColor={borderColor}>
                                    <Flex
                                        justify='space-between'
                                        align='center'
                                        fontSize={{ sm: "10px", lg: "12px" }}
                                        color='gray.400'>
                                        {column.render("Header")}
                                    </Flex>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()} key={index}>
                                {row.cells.map((cell, index) => {
                                    let data = "";
                                    if (cell.column.tag === TagDate) {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {prettifyDateToTime(cell.value)}
                                            </Text>
                                        );
                                    } else if (cell.column.tag === TagStatus) {
                                        data = (
                                            <Flex justifyContent={'space-between'}>
                                                <Flex align='center'>
                                                    <Icon
                                                        w='24px'
                                                        h='24px'
                                                        me='5px'
                                                        color={(cell.value === 'success') ? "green.500" : "red.500"}
                                                        as={ cell.value === 'success' ? MdCheckCircle : MdOutlineError}
                                                    />
                                                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                        {cell.value}
                                                    </Text>
                                                </Flex>
                                                <Button onClick={() => handlePipelineDetailsClick(cell.row)} marginRight='20px' textColor='black' backgroundColor='gray.200' height='30px'>Info</Button>
                                            </Flex>
                                        );
                                    }
                                    else  {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        );
                                    }
                                    return (
                                        <Td
                                            {...cell.getCellProps()}
                                            key={index}
                                            fontSize={{ sm: "14px" }}
                                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                            borderColor='transparent'>
                                            {data}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </Card>
    );
}
