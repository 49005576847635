import AddressInput from './AddressInput';

import React, {useEffect, useState} from "react";
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    FormControl,
    FormLabel,
    Input,
    ModalFooter,
    Text,
    Switch,
    Spinner,
    useToast,
    Image,
} from "@chakra-ui/react";
import CompaniesApi from "../../../../api/companies";
import {useDropzone} from 'react-dropzone';
import { Box, Center, Icon } from '@chakra-ui/react';
import { FaUpload } from 'react-icons/fa';

const fields = [
    { name: "isActive", label: "חברה פעילה", type: "switch" },
    { name: "govId", label: "ח.פ החברה" },
    { name: "name", label: "שם חברה" },
    { name: "companyExternalId", label: "מזהה חברה חיצוני" },
    { name: "companyDisplayedName", label: "שם תצוגה באפליקציה" },
    { name: "address", label: "כתובת" },
    { name: "employeesMonthlyTransferLimit", label: "מגבלת העברה חודשית לעובד" },
    { name: "employeesCount", label: "מספר עובדים" },
];



export default function AddNewCompanyModal({ isOpen, onClose, callBack, ...rest }) {
    const [warningShown, setWarningShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [logoPreview, setLogoPreview] = useState("");
    const [logoFileName, setLogoFileName] = useState("");


    const onDrop = React.useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];

        setCompanyJson(prev => ({
            ...prev,
            companyLogo: file
        }));

        setLogoFileName(file.name); // Update the file name state

        const reader = new FileReader();
        reader.onload = () => {
            setLogoPreview(reader.result);
        };
        reader.readAsDataURL(file);
    }, []);


    const {getRootProps, getInputProps} = useDropzone({onDrop, multiple: false});


    useEffect(() => {
        if (!isOpen) {
            setError("");
            setCompanyJson({});
            setLogoPreview(""); // Reset logo preview
            setLogoFileName(""); // Reset logo file name
        } else {
            setCompanyJson({ isActive: false, displayName: {} });
        }
    }, [isOpen]);


    const errorMapping = {
        3: "התקבלה שגיאה בבקשה למאגר המידע הממשלתי אנא מלא את שם החברה באופן ידני",
        4: "לא נמצאה חברה עם ח.פ זהה במאגר המידע הממשלתי",
    }
    const [error, setError] = useState("");
    const [companyJson, setCompanyJson] = useState({
        isActive: false,
        displayName: {},
    });


    useEffect(() => {
    }, [companyJson]);
    const toast = useToast()
    const handleChange = (e) => {
        if(e.target.name === 'govId') {
            const govId = e.target.value;
            if(e.target.value.length >= 9) {
                setIsLoading(true);
                CompaniesApi.getCompanyByGovId(govId).then(res => {
                    setIsLoading(false);
                    if (res.data.success) {
                        const company = res.data.company;
                        const companyName = company["שם חברה"];
                        const companyAddress = company["שם רחוב"] + " " + company["מספר בית"] + ", " + company["שם עיר"] + ", " + company["מדינה"];
                        setCompanyJson((prev) => ({
                            ...prev,
                            ["name"]: companyName,
                            ["address"]: companyAddress,
                        }));
                    } else {
                        setCompanyJson((prev) => ({
                            ...prev,
                            ["name"]: "",
                        }));
                        const errorCode = res.data.errorCode
                        toast({
                            title: errorMapping[errorCode],
                            position: 'top',
                            status: 'error',
                            isClosable: false,
                        });
                    }
                }).catch(e => {
                    setIsLoading(false);
                    setCompanyJson((prev) => ({
                        ...prev,
                        ["name"]: "",
                        ["address"]: "",
                    }));
                    toast({
                        title: errorMapping[3],
                        position: 'top',
                        status: 'error',
                        isClosable: false,
                    });
                });
            }
        }

        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        if (e.target.name === 'employeesMonthlyTransferLimit' || e.target.name === 'employeesCount') {
            value = parseInt(value);
        }
        setCompanyJson((prev) => ({
            ...prev,
            [e.target.name]: value,
        }));
    };



    const validateForm = (companyInfo) => {
        let missingFields = [];
        for (let field of fields) {
            if (!companyInfo[field.name] && field.name !== 'isActive' && field.name !== 'address') {
                missingFields.push(field.label);
            }
        }
        return missingFields;
    };

    const addNewCompany = () => {
        if (!warningShown) {
            const missingFields = validateForm(companyJson);
            if (missingFields.length > 0) {
                toast({
                    title: "יש למלא את השדות הבאים:",
                    description: missingFields.join(", "),
                    position: 'top',
                    status: 'warning',
                    isClosable: true,
                    duration: 5000
                });
                setWarningShown(true);
                return;
            }
        }
            CompaniesApi.createEmployer(companyJson)
                .then((res) => {
                    toast({
                        title: "חברה נשמרה בהצלחה",
                        position: 'top',
                        status: 'success',
                        isClosable: false,
                    });
                    callBack();
                    onClose();
                })
                .catch((error) => {
                    toast({
                        title: "שגיאה בעת יצירת החברה",
                        position: 'top',
                        status: 'error',
                        isClosable: false,
                    });
                    console.error("Error creating new company:", error);
                    setError("שגיאה בעת יצירת החברה");
                });
    };



    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>הוספת חברה חדשה</ModalHeader>
                <ModalBody>
                    {isLoading ? (
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px'}}>
                            <Spinner/>
                        </div>
                    ) : (
                        <>
                            {error && (
                                <Text fontSize="1.2em" color="red" textAlign="center" mb="4">
                                    {error}
                                </Text>
                            )}

                            {fields.map((field) => (
                                <FormControl mt={4} key={field.name}>
                                    <FormLabel>
                                        {field.label}
                                        {field.label !== "כתובת" && <span style={{color: "red"}}> *</span>}
                                    </FormLabel>
                                    {field.name === "address" ? (
                                        <AddressInput addressFromGovId={companyJson['address']}
                                                      onChange={(value) => setCompanyJson(prev => ({
                                                          ...prev,
                                                          address: value
                                                      }))}/>
                                    ) : field.type === "switch" ? (
                                            <Switch colorScheme="teal" size="lg" name={field.name} onChange={handleChange}
                                                    isChecked={companyJson["isActive"]}/>
                                        )
                                        : (
                                            <Input placeholder={field.label} name={field.name} onChange={handleChange}
                                                   value={companyJson[field.name] || ""}/>

                                        )}
                                </FormControl>
                            ))}
                        </>
                    )}
                    <FormControl mt={4}>
                        <FormLabel>לוגו חברה</FormLabel>
                        <Box {...getRootProps()} p={5} border="2px dashed" borderColor="gray.200" borderRadius="md">
                            <input {...getInputProps()} />
                            <Center flexDirection="column">
                                {/* If there is no logoPreview, show the upload instructions */}
                                {!logoPreview && (
                                    <>
                                        <Icon as={FaUpload} w={12} h={12} color="gray.500"/>
                                        <Text mt={2}>גרור לוגו לכאן או לחץ ובחר קובץ</Text>
                                    </>
                                )}
                                {logoPreview && (
                                    <>
                                        <Box textAlign="center" width="100%">
                                            <Text fontSize="l" dir="ltr">
                                                <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Google storage name:</span> {logoFileName}
                                            </Text>
                                        </Box>
                                        <Box width="300px" height="100px" display="flex" justifyContent="center" alignItems="center" mt={2}>
                                            <Image src={logoPreview} alt="Logo preview" objectFit="contain" maxW="100%" maxH="100%"/>
                                        </Box>
                                    </>
                                )}
                            </Center>
                        </Box>
                    </FormControl>



                </ModalBody>
                <ModalFooter>
                    <Button onClick={addNewCompany}>אישור</Button>
                    <Button onClick={onClose} variant="outline">
                        סגור
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}