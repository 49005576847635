import {
    Box,
    Button,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure
} from "@chakra-ui/react";
import React, {useEffect, useMemo, useState} from "react";
import Card from "../../../components/card/Card";
import {formsColumns, TagActions, TagId, TagStatus, TagTitle} from "./formsData";
import AddNewFormModal from "./AddNewFormModal";
import FormsApi from "../../../api/forms";
import {useSortBy, useTable} from "react-table";
import EditFormModal from "./EditFormModal";


export default function FormsTable() {

    const columns = useMemo(() => formsColumns, [formsColumns]);

    const [data, setData] = React.useState([])
    const [selectedForm, setSelectedForm] = useState(null)

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    const {
        isOpen: isEditFormOpen,
        onOpen: onEditFormOpen,
        onClose: onEditFormClose
    } = useDisclosure()

    const {
        isOpen: isNewFormOpen,
        onOpen: onNewFormOpen,
        onClose: onNewFormClose
    } = useDisclosure()

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = () => {
        FormsApi.getAll().then((response) => {
            if(response.data === null) return;
            if(response.data.length === 0) return;
           setData(response.data);
        });
    }
    const handleEditClick = (cellRow) => {
        let index = cellRow.index
        setSelectedForm(data[index])
        onEditFormOpen()
    }

    const onNewFormCreated = () => {

    }

    const onEditForm = () => {
        refreshData();
    }


    return (
        <>
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card
                direction='column'
                w='100%'
                px='0px'
                overflowX={{ sm: "scroll", lg: "hidden" }}>
                <Flex mx={'20px'} justify='space-between' mb='20px' align='center'>

                    <Button variant={'brand'} onClick={() => onNewFormOpen()}>
                        הוספת טופס
                    </Button>

                    <AddNewFormModal isOpen={isNewFormOpen} onClose={onNewFormClose} onNewFormCreated={onNewFormCreated} />
                    <EditFormModal isOpen={isEditFormOpen} onClose={onEditFormClose} onFormUpdate={onEditForm} form={selectedForm} />

                </Flex>

                {data.length === 0 ? (
                    <Flex direction="column" justifyContent="center" alignItems="center" height={'300px'}>
                        <Text color={textColor} fontSize='xl' fontWeight='700'>
                            אין טפסים להצגה
                        </Text>
                    </Flex>
                ) : (
                <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
                    <Thead>
                        {headerGroups.map((headerGroup, index) => (
                            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <Th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        pe='10px'
                                        key={index}
                                        borderColor={borderColor}>
                                        <Flex
                                            justify='space-between'
                                            align='center'
                                            fontSize={{ sm: "10px", lg: "12px" }}
                                            color='gray.400'>
                                            {column.render("Header")}
                                        </Flex>
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {rows.map((row, index) => {
                            prepareRow(row);
                            return (
                                <Tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, index) => {
                                        let data = "";
                                        let cellWidth = cell.column.width;
                                        if (cell.column.tag === TagId || cell.column.tag === TagTitle) {
                                            data = (
                                                <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                    {cell.value}
                                                </Text>
                                            );
                                        }
                                        else if(cell.column.tag === TagStatus) {
                                            data = (
                                                <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                    {cell.value === 0 ? "לא פעיל": "פעיל"}
                                                </Text>
                                            );
                                        }
                                        else if (cell.column.tag === TagActions) {
                                            data = (
                                                <Flex align='center'>
                                                    <Button onClick={() => handleEditClick(cell.row)} marginLeft='20px' textColor='black' backgroundColor='green.200' height='35px'>פרטים</Button>
                                                </Flex>
                                            );
                                        }
                                        return (
                                            <Td
                                                {...cell.getCellProps()}
                                                key={index}
                                                fontSize={{ sm: "14px" }}
                                                width={cellWidth}
                                                borderColor='transparent'>
                                                {data}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
                )}
            </Card>

            </Box>
        </>
    );
}
