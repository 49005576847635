import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  SimpleGrid,
  Select,
    useToast,
} from "@chakra-ui/react";


import EmployeesApi from "../../../../api/employees"
import { useEffect, useMemo, useState } from "react";
import { citiesB } from "../../../../data/citiesData";
import {AutoCompleteSelector} from "../../../../functions/AutoCompleteSelector";

export default function AddNewUserModal(props) {

    let { isOpen, onClose, callBack,
        ...rest } = props;

    const [error, setError] = useState(undefined);
    const [cityId, setCityId] = useState(undefined)
    const [employeeJson, setEmployeeJson] = useState({});
    const citiesData = useMemo(() => citiesB, [citiesB]);

    const handleChangeFor = (event) => {
        const name = event.target.name;
        let value = event.target.value;

        if (name === "roles") {
            value = [value];
        }

        const { employee } = employeeJson;
        const newEmployee = {
            ...employee,
            [name]: value
        };
        setEmployeeJson({ employee: newEmployee });
    }


    useEffect(() => {
        if(isOpen) {
            setEmployeeJson({employee: {}});
            setError(undefined);
        }
      }, [isOpen])


    const toast = useToast();

    const addNewEmployee = () => {
        let employeeInfo = employeeJson.employee
        if(!employeeInfo.firstName) {
            setError("אנא הכנס שם פרטי")
        }
        else if(!employeeInfo.lastName) {
            setError("אנא הכנס שם משפחה")
        }
        else if(!employeeInfo.govId || employeeInfo.govId.length != 9) {
            setError("אנא הכנס תעודת זהות")
        }
        else if(!employeeInfo.phoneNumber || employeeInfo.phoneNumber.length != 10) {
            setError("אנא הכנס טלפון")
        }
        else if(!employeeInfo.gender) {
            setError("אנא בחר מגדר")
        }

        else if(!employeeInfo.employerId) {
          setError("אנא הכנס מספר מעסיק")
        }
        else if(employeeInfo.roles.length == 0) {
          setError("אנא הכנס הרשאה")
        }
        else if(cityId.length == 0) {
          setError("אנא בחר עיר")
        }
        else {
            employeeInfo['cityId'] = cityId
            EmployeesApi.addEmployee(employeeInfo).then(res => {
                callBack()
                onClose()


                // Show success toast
                toast({
                    title: "יצירת המשתמש בוצעה בהצלחה",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position: "top"
                });
            })
                .catch(function (error) {
                    setError("שגיאה");

                    // Show error toast
                    toast({
                        title: "לא הצלחנו ליצור את המשתמש",
                        description: "אנא נסה שנית מאוחר יותר",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                        position: "top"
                    });
                });
        }
    }



    return (
        <Modal isOpen={isOpen}
         onClose={onClose}
         isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>הוספת עובד חדש</ModalHeader>
          <ModalBody>
          <h4 style={{
                fontSize: "1.2em",
                color: "red",
                textAlign: "center",
                fontWeight: 400,
                transition: ".2s all"}}>
              {error}
            </h4>
          <FormLabel fontWeight='bold'>פרטים אישיים</FormLabel>
                 <SimpleGrid
                    columns={{sm: 2}}
                    gap='10px'
                    mb='0px'>
                    <FormControl mt={4}>
                        <Input placeholder='שם פרטי' name="firstName" onChange={handleChangeFor} />
                    </FormControl>

                    <FormControl mt={4}>
                        <Input placeholder='שם משפחה' name="lastName" onChange={handleChangeFor} />
                    </FormControl>

                    <FormControl mt={4}>
                        <Input placeholder='תעודת זהות' name="govId" onChange={handleChangeFor} />
                    </FormControl>

                    <FormControl mt={4}>
                        <Input placeholder='טלפון' name="phoneNumber" onChange={handleChangeFor} />
                    </FormControl>

                    <FormControl mt={4}>
                        <Input type='date' placeholder='תאריך לידה' name="dob" onChange={handleChangeFor} />
                    </FormControl>

                    <Select pr='10px' placeholder='בחר מגדר' mt={4} name="gender" onChange={handleChangeFor}>
                        <option value='male'>גבר</option>
                        <option value='female'>אישה</option>
                        <option value='other'>אחר</option>
                    </Select>

                     <Select pr='10px' placeholder='בחר הרשאה' mt={4} name="roles" onChange={handleChangeFor}>
                        <option value={['employerUserAdmin']}>מעסיק</option>
                        <option value={['employee']}>עובד</option>
                    </Select>

                     {/*<FormControl mt={4}>*/}
                     {/*    <AutoCompleteSelector cityId={cityId} setCityId={setCityId} />*/}
                     {/*</FormControl>*/}
                     <FormControl mt={4}>
                         <AutoCompleteSelector state={cityId} stateSetter={setCityId} data={citiesData} />
                     </FormControl>

                 </SimpleGrid>
                <br/>


            <FormLabel fontWeight='bold'>פרטי מעסיק</FormLabel>

            <SimpleGrid
                    columns={{  lg: 2 }}
                    gap='15px'
                    mb='0px'>

          <FormControl mt={4}>
              <Input placeholder='מס מעסיק' name="employerId" onChange={handleChangeFor}/>
            </FormControl>

            <FormControl mt={4}>
              <Input placeholder='מס עובד' name="internalId" onChange={handleChangeFor}/>
            </FormControl>

            <FormControl mt={4}>
              <Input placeholder='מחלקה' name="jobDepartment" onChange={handleChangeFor}/>
            </FormControl>

            <FormControl mt={4}>
              <Input placeholder='תפקיד' name="jobTitle" onChange={handleChangeFor}/>
            </FormControl>
            </SimpleGrid>

            <br/>

            <FormLabel fontWeight='bold'>פרטי חשבון בנק</FormLabel>

           <SimpleGrid
                columns={{  lg: 3 }}
                gap='15px'
                mb='0px'>

               <Select pr='10px' placeholder='בחר בנק' mt={4} name="bankId" onChange={handleChangeFor}>
                   <option value='12'>הפועלים</option>
                   <option value='14'>אוצר החייל</option>
                   <option value='9'>בנק הדואר</option>
                   <option value='11'>דיסקונט</option>
                   <option value='10'>לאומי</option>
                   <option value='20'>מזרחי טפחות</option>
                   <option value='18'>וואן זירו</option>
                   <option value='31'>הבינלאומי</option>
                   <option value='13'>אגוד</option>
                   <option value='04'>יהב לעובדי מדינה</option>
                   <option value='54'>ירושלים</option>
                   <option value='46'>מסד</option>
                   <option value='17'>מרכנתיל דיסקונט</option>
                   <option value='34'>ערבי ישראלי</option>
                   <option value='26'>יובנק</option>
               </Select>

               <FormControl mt={4}>
                   <Input placeholder='סניף' name="branchId" onChange={handleChangeFor} />
                </FormControl>

                <FormControl mt={4}>
                    <Input placeholder='חשבון' name="accountId" onChange={handleChangeFor} />
                </FormControl>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button onClick={addNewEmployee}>אישור</Button>
            <Button onClick={onClose}>סגור</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}
