// Chakra imports
import {
    AvatarGroup,
    Avatar,
    Box,
    Button,
    Flex,
    Icon,
    Image,
    Link,
    Text,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    FormControl,
    Input
} from '@chakra-ui/react'

// Custom components
import Card from "components/card/Card.js";
// Assets
import React, { useState } from "react";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import { MdCheckCircle } from "react-icons/md";

export default function IntegrationCard(props) {
    const { image, name, title, subtitle, author, bidders, download, status, type } = props;
    const [like, setLike] = useState(false);
    const textColor = useColorModeValue("navy.700", "white");
    const textColorBid = useColorModeValue("brand.500", "white");

    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)


    let inputContent;
    if (type === '2') {
        inputContent = <Text dir='ltr' color={textColor} fontSize='15px'fontWeight='400' justify='left'>ab6844608cf04634a31884923ea3c37c4187832a22a464093441</Text>;
    } else if (type === '3') {
        inputContent =  <FormControl mt={4}><Input ref={initialRef} defaultValue={'marinada1'} /><br/><Input type={'password'} marginTop='20px' placeholder='סיסמה' defaultValue={'457845'}/></FormControl>;
    } else {
        inputContent =  <FormControl mt={4}><Input ref={initialRef} placeholder='שם משתמש' /><br/><Input marginTop='20px' placeholder='סיסמה' /></FormControl>;
    }


    return (

        <Card p='20px'
              border={status === '1' ? "4px solid" : "0px"}
              borderColor='#05e68f'>
            <Flex direction={{ base: "column" }} justify='center'>
                <Box mb={{ base: "20px", "2xl": "20px" }} position='relative'>
                    <Image
                        src={image}
                        w={{ base: "100%", "3xl": "100%" }}
                        h={{ base: "100%", "3xl": "100%" }}
                        borderRadius='20px'
                    />
                </Box>
                <Flex flexDirection='column' justify='space-between' h='100%'>
                    <Flex
                        justify='space-between'
                        direction={{
                            base: "row",
                            md: "column",
                            lg: "row",
                            xl: "column",
                            "2xl": "row",
                        }}
                        mb='auto'>
                        <Flex direction='column'>
                            <Flex justify='alignItems' align='center' mb='5px'>
                                <Text
                                    color={textColor}
                                    fontSize={{
                                        base: "xl",
                                        md: "lg",
                                        lg: "lg",
                                        xl: "lg",
                                        "2xl": "md",
                                        "3xl": "lg",
                                    }}
                                    mb='-2px'
                                    fontWeight='bold'
                                    me='8px'>
                                    {name}
                                </Text>
                                <Icon visibility={status === '1' ? "visible" : "hidden"} w='24px' h='24px' color="green.500" as={MdCheckCircle} />
                            </Flex>
                            <Text
                                color='secondaryGray.600'
                                fontSize={{
                                    base: "sm",
                                }}
                                fontWeight='400'
                                me='14px'>
                                {subtitle}
                            </Text>
                        </Flex>
                        <Link
                            mt={{
                                base: "0px",
                                md: "10px",
                                lg: "0px",
                                xl: "10px",
                                "2xl": "0px",
                            }}>
                            <Button
                                variant={status === '1' ? "darkBrand" : "brand"}
                                color='white'
                                fontSize='sm'
                                fontWeight='500'
                                borderRadius='70px'
                                px='24px'
                                py='5px'
                                onClick={onOpen}>
                                {status === '1' ? "הגדרות" : "הוסף"}
                            </Button>
                            <Modal
                                initialFocusRef={initialRef}
                                finalFocusRef={finalRef}
                                isOpen={isOpen}
                                onClose={onClose}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>הוספת אינטגרציה</ModalHeader>
                                    <ModalBody>

                                        <Text fontWeight='bold' mb='1rem'>
                                            {
                                                type === '1' ? "הוסף את פרטי ההתחברות למערכת נוכחות" : "הזדהה בעזרת Token ל-Payro Time Attendance API"
                                            }

                                        </Text>
                                    </ModalBody>
                                    <ModalBody pb={6}>
                                        {inputContent}
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button colorScheme='blue' mr={3}>
                                            שמור
                                        </Button>
                                        <Button onClick={onClose}>ביטול</Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        </Link>
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    );
}
  