
export const TagName = 1
export const TagAmount = 2
export const TagDate = 7
export const TagActions = 9
export const TagCount = 3
export const TagType = 5
export const TagStatus = 4
export const TagBranch = 10

export const tableColumns = [
    {
        Header: "חודש",
        accessor: "payPeriod",
        tag: TagDate,
        width: "150px",
    },
    {
        Header: "סניף",
        accessor: "companyName",
        tag: TagBranch,
        width: "150px",
    },
    {
        Header: "סה״כ",
        accessor: "settlementTotalAmount",
        tag: TagAmount,
        width: "150px"
    },
    {
        Header: "סטטוס",
        accessor: "state",
        tag: TagStatus,
        width: "150px"
    },
    {
        Header: "פעולות נוספות",
        accessor: "actions",
        tag: TagActions,
    }
];
