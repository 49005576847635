/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl, FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { InputMaxLength } from "components/input/InputMaxLength";
import AuthApi from "api/auth";
import { NavigateTo } from "nav/navigator";
import { Page } from "nav/navigator";
import {NavLink, useHistory} from "react-router-dom";
import { setAccessToken } from "api/auth";
import { useAuth } from "auth/auth.context";
import classes from "../../../layouts/auth/style.module.css";

function OTP() {

  const [otpCode, setOtpCode] = useState("");
  const [error, setError] = useState(undefined);
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const history = useHistory();
  const { setUser } = useAuth();

  const goToMain = async (userInfo) => {
    setUser(userInfo);
    localStorage.removeItem("tempSessionToken");
    return history.push("/rtl-default")
  };

  const getUserInfo = async (response) => {

    let accessToken = response.accessToken;

    try {
      let response = await AuthApi.GetUserInfo(accessToken);
      if (response.data && response.data.success === false) {
        return setError(response.data.msg);
      }
      return goToMain({
        "token": accessToken,
        "info": {
          "companyExternalId": response.data.companyExternalId,
          "firstName": response.data.firstName,
          "lastName": response.data.lastName,
          "type": response.data.type
        }
    });
    } catch (err) {
      if (err && err.response && err.response.status === 401) {
        return setError("קוד לא תקין")
      }
      else if (err.message) {
        return setError(err.message);
      }
      return setError("שגיאה");
    }
  }

  const handleContinue = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (otpCode === "") {
      return setError("אנא הכנס קוד אימות");
    }

    if (otpCode.length != 6) {
      return setError("אנא הכנס קוד אימות תקין");
    }
    try {
      let response = await AuthApi.Login(otpCode);
      if (response.data && response.data.success === false) {
        return setError(response.data.msg);
      }
      return getUserInfo(response.data);
    } catch (err) {
      if (err && err.response && err.response.status === 401) {
        return setError("קוד לא תקין")
      }
      else if (err.message) {
        return setError(err.message);
      }
      return setError("שגיאה");
    }
  };
  return (
    <DefaultAuth>
      <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w='100%'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'
          bc="green.500"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "26vh" }}
          flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            התחברות
          </Heading>
          <Text
              fontFamily="'Noto Sans Hebrew', sans-serif"
              mb="36px"
              ms="4px"
              color="var(--secondary-grey-600, #A3AED0)"
              fontWeight="400"
              fontSize="20px"
              fontStyle="normal"
              lineHeight="100%" // Equivalent to 20px
              letterSpacing="-0.4px"
          >
            הזינו את קוד האימות שקיבלתם לנייד
          </Text>
        </Box>
        <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>

          <Flex
              zIndex='2'
              direction='column'
              w={{ base: "100%", md: "420px" }}
              maxW='100%'
              background='transparent'
              borderRadius='15px'
              mx={{ base: "auto", lg: "unset" }}
              me='auto'
              mb={{ base: "20px", md: "auto" }}>
            <h4 style={{
              fontSize: "1.2em",
              color: "red",
              textAlign: "center",
              fontWeight: 400,
              transition: ".2s all"}}>
              {error}
            </h4>
          <FormControl
              paddingTop='10px'>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
            >

              <Text
                  color="#2B3674"
                  fontFamily="'Noto Sans Hebrew', sans-serif"
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="100%" // Equivalent to 18px
                  letterSpacing="-0.36px"
              >
                קוד אימות
              </Text>


            </FormLabel>

            <InputMaxLength
                isRequired={true}
                variant='auth'
                fontSize='20px'
                ms={{ base: "0px", md: "0px" }}
                type='email'
                placeholder='000000'
                mb='24px'
                fontWeight='500'
                size='lg'
                maxLength='10'
                onChange={(event) => {
                  setOtpCode(event.target.value);
                  setError(undefined);
                }}/>


            <Flex justifyContent='space-between' align='center' mb='24px'>


            </Flex>

            <Button
                className={classes.button}
                onClick={handleContinue}
                variant={'brand'} >
              המשך
            </Button>

          </FormControl>
          </Flex>
          <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='start'
              maxW='100%'
              mt='0px'>
            <Text
                fontFamily="'Noto Sans Hebrew', sans-serif"
                color="#2B3674"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="26px" // Equivalent to 162.5% for 16px font size
                letterSpacing="-0.32px"
                as="span"
                ms="5px"
            >
              לא רשום אצלנו?
              <a href='https://www.payro.io/contact-us' target="_blank" rel="noopener noreferrer">
                <Text
                    fontFamily="'Noto Sans Hebrew', sans-serif"
                    color="#000"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="26px"
                    letterSpacing="-0.32px"
                    as="span"
                    ms="5px"
                >
                  צור קשר
                </Text>
              </a>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default OTP;
