import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    FormControl,
    FormLabel,
    Input,
    ModalFooter,
    SimpleGrid,
    Select, ChakraProvider, Box, useRadioGroup, HStack, useRadio,
} from "@chakra-ui/react";


import EmployeesApi from "../../../../api/employees"
import React, { useEffect, useMemo, useState } from "react";
import { citiesB } from "../../../../data/citiesData";
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from "@choc-ui/chakra-autocomplete";
import {RadioCard} from "./RadioCard";


export default function AddNewEmployeeModal(props) {

    let { isOpen, onClose, callBack,
        ...rest } = props;

    const [error, setError] = useState(undefined);
    const [cityId, setCityId] = useState('')
    const [employeeJson, setEmployeeJson] = useState({});
    const citiesData = useMemo(() => citiesB, [citiesB]);

    const handleChangeFor = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const { employee } = employeeJson;
        const newEmployee = {
            ...employee,
            [name]: value
        };
        setEmployeeJson({ employee: newEmployee });
    }

    useEffect(() => {
        setEmployeeJson({employee: {}});
        setError(undefined);
    }, [isOpen])

    const addNewEmployee = () => {
        let employeeInfo = employeeJson.employee
        if(!employeeInfo.firstName) {
            setError("אנא הכנס שם פרטי")
        }
        else if(!employeeInfo.lastName) {
            setError("אנא הכנס שם משפחה")
        }
        else if(!employeeInfo.govId || employeeInfo.govId.length != 9) {
            setError("אנא הכנס תעודת זהות")
        }
        else if(!employeeInfo.phoneNumber || employeeInfo.phoneNumber.length != 10) {
            setError("אנא הכנס טלפון")
        }

        // else if(!employeeInfo.gender) {
        //     setError("אנא בחר מגדר")
        // }
        // else if(!employeeInfo.bankId) {
        //     setError("אנא בחר בנק")
        // }
        // else if(!employeeInfo.branchId) {
        //     setError("אנא הכנס סניף")
        // }
        // else if(!employeeInfo.accountId) {
        //     setError("אנא הכנס מספר חשבון בנק")
        // }
        // else if(cityId.length == 0) {
        //     setError("אנא בחר עיר")
        // }
        else {
            if(cityId) {
                employeeInfo['cityId'] = cityId
            }
            EmployeesApi.addEmployee(employeeInfo).then(res => {
                callBack()
                onClose()
            })
                .catch(function (error) {
                    if (error.response) {
                    }
                    setError("שגיאה")
                });
        }
    }



    const [salaryType, setSalaryType] = useState("hourly");
    const [salaryInput, setSalaryInput] = useState('');

    const handleSalaryChange = (event) => {
        const salaryValue = event.target.value;
        setSalaryInput(salaryValue); // Update the salary input state

        // Update employeeJson with salary details
        let updatedEmployee = { ...employeeJson.employee };
        if (salaryType === "hourly") {
            updatedEmployee.salaryPerHour = salaryValue;
            delete updatedEmployee.salaryPerMonth;
        } else {
            updatedEmployee.salaryPerMonth = salaryValue;
            delete updatedEmployee.salaryPerHour;
        }
        setEmployeeJson({ employee: updatedEmployee });
    };

    useEffect(() => {
        // Clear out the non-relevant salary field when salary type changes
        let updatedEmployee = { ...employeeJson.employee };
        if (salaryType === "hourly") {
            delete updatedEmployee.salaryPerMonth;
        } else {
            delete updatedEmployee.salaryPerHour;
        }
        setEmployeeJson({ employee: updatedEmployee });
        setSalaryInput(''); // Reset salary input
    }, [salaryType]);

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: "salaryType",
        defaultValue: "hourly",
        onChange: setSalaryType
    });

    const salaryOptions = [
        { value: "hourly", label: "שכר ברוטו שעתי" },
        { value: "monthly", label: "שכר ברוטו חודשי" }
    ];

    const group = getRootProps();


    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>הוספת עובד חדש</ModalHeader>
                <ModalBody>
                    <h4 style={{
                        fontSize: "1.2em",
                        color: "red",
                        textAlign: "center",
                        fontWeight: 400,
                        transition: ".2s all"}}>
                        {error}
                    </h4>
                    <FormLabel fontWeight='bold'>פרטים אישיים</FormLabel>
                    <SimpleGrid
                        columns={{sm: 2}}
                        gap='10px'
                        mb='0px'>
                        <FormControl mt={4}>
                            <Input placeholder='שם פרטי' name="firstName" onChange={handleChangeFor} />
                        </FormControl>

                        <FormControl mt={4}>
                            <Input placeholder='שם משפחה' name="lastName" onChange={handleChangeFor} />
                        </FormControl>

                        <FormControl mt={4}>
                            <Input placeholder='תעודת זהות' name="govId" onChange={handleChangeFor} />
                        </FormControl>

                        <FormControl mt={4}>
                            <Input placeholder='טלפון' name="phoneNumber" onChange={handleChangeFor} />
                        </FormControl>

                        <FormControl mt={4}>
                            <Input type='date' placeholder='תאריך לידה' name="dob" onChange={handleChangeFor} />
                        </FormControl>

                        <FormControl mt={4}>
                        <AutoComplete>
                            <AutoCompleteInput variant="filled" placeholder="בחר עיר"/>
                            <AutoCompleteList>
                                {citiesData.map((city) => (
                                    <AutoCompleteItem
                                        key={city.id}
                                        value={city.name}
                                        name="cityId">
                                        <h1 key={city.id} onClick={(e) => {
                                            setCityId(city.id)
                                        }}>{city.name}</h1>
                                    </AutoCompleteItem>
                                ))}
                            </AutoCompleteList>
                        </AutoComplete>
                        </FormControl>

                        <FormControl mt={4}>
                        <Select
                            sx={{
                                // Adjust the padding and background properties
                                paddingRight: '2rem', // Ensure enough padding on the right for the text
                                paddingLeft: '1rem', // Add padding on the left for the icon
                                backgroundPosition: 'left 0.5rem center', // Position the icon to the left
                            }}
                            placeholder='בחר מגדר' name="gender" onChange={handleChangeFor}>
                            <option value='male'>גבר</option>
                            <option value='female'>אישה</option>
                            <option value='other'>אחר</option>
                        </Select>
                        </FormControl>

                    </SimpleGrid>

                    <br/>
                    <FormControl>
                        <FormLabel fontWeight='bold'>פרטי שכר</FormLabel>
                        <ChakraProvider>
                            <HStack {...group}>
                                {salaryOptions.map(({ value, label }) => (
                                    <RadioCard key={value} {...getRadioProps({ value })}>
                                        {label}
                                        </RadioCard>
                                        ))}
                                    </HStack>
                                    <br />
                                    <Input
                                    placeholder={salaryType === "hourly" ? "הכנס שכר שעתי" : "הכנס שכר חודשי"}
                                    name="salary"
                                    // value={salaryInput}
                                    onChange={handleSalaryChange}
                            />
                        </ChakraProvider>
                    </FormControl>

                <br/>
                    {/*<FormControl>*/}
                    {/*    <FormLabel fontWeight='bold'>פרטי שכר</FormLabel>*/}
                    {/*    <ChakraProvider>*/}
                    {/*    <Switcher*/}
                    {/*        isChecked={isHourly}*/}
                    {/*        handleToggle={handleToggle}*/}
                    {/*        option1="שכר ברוטו שעתי"*/}
                    {/*        option2="שכר ברוטו חודשי"*/}
                    {/*    />*/}
                    {/*        <br/>*/}
                    {/*    {isHourly ? (*/}
                    {/*                <Input placeholder="הכנס שכר שעתי" name="salery" onChange={handleSalaryChange} />*/}
                    {/*    ) : (*/}
                    {/*            <Input placeholder="הכנס שכר חודשי" name="salery" onChange={handleSalaryChange} />*/}
                    {/*    )}*/}
                    {/*    </ChakraProvider>*/}
                    {/*</FormControl>*/}
                    {/*<br/>*/}


                    <FormLabel fontWeight='bold'>פרטי מעסיק</FormLabel>

                    <SimpleGrid
                        columns={{  lg: 2 }}
                        gap='15px'
                        mb='0px'>

                        <FormControl mt={4}>
                            <Input placeholder='מס עובד' name="internalId" onChange={handleChangeFor}/>
                        </FormControl>

                        <FormControl mt={4}>
                            <Input placeholder='מחלקה' name="jobDepartment" onChange={handleChangeFor}/>
                        </FormControl>

                        <FormControl mt={4}>
                            <Input placeholder='תפקיד' name="jobTitle" onChange={handleChangeFor}/>
                        </FormControl>
                    </SimpleGrid>


                    <br/>

                    <FormLabel fontWeight='bold'>פרטי חשבון בנק</FormLabel>

                    <SimpleGrid
                        columns={{  lg: 3 }}
                        gap='15px'
                        mb='0px'>

                        <Select pr='10px' placeholder='בחר בנק' mt={4} name="bankId" onChange={handleChangeFor}>
                            <option value='12'>הפועלים</option>
                            <option value='14'>אוצר החייל</option>
                            <option value='11'>דיסקונט</option>
                            <option value='10'>לאומי</option>
                            <option value='20'>מזרחי טפחות</option>
                            <option value='18'>וואן זירו</option>
                            <option value='31'>הבינלאומי</option>
                            <option value='13'>אגוד</option>
                            <option value='04'>יהב לעובדי מדינה</option>
                            <option value='54'>ירושלים</option>
                            <option value='46'>מסד</option>
                            <option value='17'>מרכנתיל דיסקונט</option>
                            <option value='34'>ערבי ישראלי</option>
                            <option value='26'>יובנק</option>
                        </Select>

                        <FormControl mt={4}>
                            <Input placeholder='סניף' name="branchId" onChange={handleChangeFor} />
                        </FormControl>

                        <FormControl mt={4}>
                            <Input placeholder='חשבון' name="accountId" onChange={handleChangeFor} />
                        </FormControl>
                    </SimpleGrid>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={addNewEmployee}>אישור</Button>
                    <Button onClick={onClose}>סגור</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
