import React from "react";
import ReactApexChart from "react-apexcharts";


class PieChart extends React.Component {

  // const {activeUsers, nonActiveUsers, ...rest } = props;

  // componentDidMount() {
  //   this.setState({
  //     chartData: this.props.chartData,
  //     chartOptions: this.props.chartOptions,
  //   });
  // }


  shouldComponentUpdate(nextProps, nextState, nextContext) {
      // Rendering the component only if
      // passed props value is changed
      if (nextProps.chartData !== this.props.chartData) {
        return true;
      } else {
        return false;
      }
  }

  render() {
    return (
      <ReactApexChart
        options={this.props.chartOptions}
        series={this.props.chartData}
        type='pie'
        width='100%'
        height='55%'
      />
    );
  }
}

export default PieChart;
