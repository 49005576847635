import React, {useEffect, useMemo} from "react";
import {useSortBy, useTable} from "react-table";
import {
    Button,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
    Select,
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import CompaniesApi from "../../../../api/companies";
import {
    tableColumns,
    TagActions,
    TagAmount, TagBranch, TagCount,
    TagDate,
    TagName, TagStatus, TagType
} from "./reportsTableData";

import { prettifyPayPeriodDate} from "../../../../functions/dateUtils";
import ReportDetailsModal from "./ReportDetailsModal";
import MonthDropdownSelection from "../../../../functions/MonthDropdownSelection";
import {Reports} from "../../../../api/reports";

export default function ReportsTable() {
    const columns = useMemo(() => tableColumns, [tableColumns]);
    const [data, setData] = React.useState([])
    const [date, setDate] = React.useState("")
    const [selectedReport, setSelectedReport] = React.useState(undefined)

    const refreshReports = ()=> {
        CompaniesApi.getReports(date).then(res => {
            setData(res.data);
        }).catch(e => {
                setData([])
        })
    }

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    const {
        isOpen: isReportModalOpen,
        onOpen: onReportModalOpen,
        onClose: onReportModalClose
    } = useDisclosure()

    const handleRefreshClick = () => {
        refreshReports()
    }

    const handleDateChange = (event) => {
        setDate(event)
    }


    const handleDetailsClick = (cellRow) => {
        setSelectedReport(data[cellRow.index])
        onReportModalOpen()
    }

    return (
        <Card
            direction='column'
            w='100%'
            px='0px'

            overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Flex px='25px' mb='20px' align='center'>

                <MonthDropdownSelection handleSelectedMonth={handleDateChange}/>

                <ReportDetailsModal data={data} report={selectedReport} date={date} isOpen={isReportModalOpen} onClose={onReportModalClose} />

                <Button
                    marginRight='30px'
                    width='80px'
                    variant={'brand'} onClick={handleRefreshClick} >הצג
                </Button>

            </Flex>

            <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
                <Thead>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                                <Th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    pe='10px'
                                    key={index}
                                    borderColor={borderColor}>
                                    <Flex
                                        justify='space-between'
                                        align='center'
                                        fontSize={{ sm: "10px", lg: "12px" }}
                                        color='gray.400'>
                                        {column.render("Header")}
                                    </Flex>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()} key={index}>
                                {row.cells.map((cell, index) => {
                                    let data = "";
                                    let cellWidth = cell.column.width;
                                    if(cell.column.tag === TagAmount) {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                ₪{cell.value}
                                            </Text>
                                        );
                                    }
                                    else if (cell.column.tag === TagType) {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        );
                                    }
                                    else if (cell.column.tag === TagCount) {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        );
                                    }
                                    else if (cell.column.tag === TagName || cell.column.tag === TagBranch) {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        );
                                    }
                                    else if(cell.column.tag === TagDate) {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {prettifyPayPeriodDate(cell.value)}
                                            </Text>
                                        );
                                    }
                                    else if(cell.column.tag === TagStatus) {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {Reports.stateText(cell.value)}
                                            </Text>
                                        );
                                    }
                                    else if (cell.column.tag === TagActions) {
                                        data = (
                                            <Flex align='center'>
                                                <Button onClick={() => handleDetailsClick(cell.row)} marginLeft='20px' textColor='black' backgroundColor='green.200' height='35px'>פרטים</Button>
                                            </Flex>
                                        );
                                    }
                                    return (
                                        <Td
                                            {...cell.getCellProps()}
                                            key={index}
                                            fontSize={{ sm: "14px" }}
                                            width={cellWidth}
                                            borderColor='transparent'>
                                            {data}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </Card>
    );
}
