
export const TagName = 1
export const TagAmount = 2
export const TagDate = 7
export const TagActions = 9
export const TagCount = 3
export const TagStatus = 4

export const tableColumns = [
    {
        Header: "חודש",
        accessor: "payPeriod",
        tag: TagDate,
        width: "150px",
    },
    {
        Header: "חברה",
        accessor: "companyName",
        tag: TagName,
        width: "150px",
    },
    {
        Header: "סטטוס",
        accessor: "state",
        tag: TagStatus,
        width: "150px"
    },
    {
        Header: "פעולות נוספות",
        accessor: "actions",
        tag: TagActions,
    }
];
