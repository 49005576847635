import {Box, SimpleGrid} from "@chakra-ui/react";
import React from "react";
import PipelinesTable from "./components/PipelinesTable";
import PipelinesErrorsTable from "./components/PipelinesErrorsTable";

export default function DataPipelines() {

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid
                mb='20px'
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: "20px", xl: "20px" }}>
                <PipelinesTable />
                {/*<PipelinesErrorsTable />*/}
            </SimpleGrid>
        </Box>
    );
}
