import React from "react";

// Chakra imports
import {Flex, useColorModeValue, Text, Image} from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import User from "../../../api/user";
import Logo from "../../../assets/img/auth/Logo.png";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'
          marginTop="50px">
        <Image src={Logo} alt="Logo"
               w='60%'
               marginBottom="16px"
        />
      <Text fontSize='2xl' fontWeight='bold' style={{ paddingBottom: 30 }}>
        {User.GetInfo().type === 1 ? 'admin' : 'for business'}
      </Text>
      <HSeparator mt='30px' mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
