import {Box} from "@chakra-ui/react";
import React from "react";
import AdminTransfersTable from "./components/AdminTransfersTable";

export default function Applications() {
    // Chakra Color Mode
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <AdminTransfersTable />
        </Box>
    );
}