/**
 * Handling Logout
 * @param setUser const { setUser } = useAuth()
 * @param history const history = useHistory()
 * @returns {Promise<*>}
 */
export const handleLogout = () => {
    localStorage.clear();
    if (typeof window !== 'undefined') {
        window.location.href = '/';
    }
};
