import {Select} from "@chakra-ui/react";
import React from "react";

import {GenerateRecentMonthsForDropdown} from "./dateUtils";


export default function MonthDropdownSelection(props){

    const months = GenerateRecentMonthsForDropdown();
    const [selectedMonth, setSelectedMonth] = React.useState('');
    const handleDateChanged = (e) => {
        setSelectedMonth(e.target.value);
        props.handleSelectedMonth(e.target.value);
    };

    return(
        <Select
            marginRight='0px'
            width='190px'
            pr='15px'
            onChange={handleDateChanged}
            value={selectedMonth}
        >
            <option value='' disabled hidden>בחר חודש</option>
            {months.map((month) => (
                <option key={month.value} value={month.value}>
                    {month.label}
                </option>
            ))}
        </Select>
    )
}
