import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import RTLLayout from "layouts/rtl/RTLLayout";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ProtectedRoute } from "auth/protected.route";
import { AuthProvider } from "auth/auth.context";

ReactDOM.render(
  <ChakraProvider theme={theme}>
  <AuthProvider>
    <React.StrictMode>
      <HashRouter>
        <Switch>
          <Route path={`/auth`} component={AuthLayout} />
          {/* <ProtectedRoute path={`/admin`} component={RTLLayout} /> */}
          <ProtectedRoute path={`/rtl`} component={RTLLayout} />
          <Redirect from='/' to='/rtl' />
        </Switch>
      </HashRouter>
    </React.StrictMode>
    </AuthProvider>
  </ChakraProvider>,
  document.getElementById("root")
);
