import {Box} from "@chakra-ui/react";
import React from "react";
import TransfersTable from "./components/TransfersTable";

export default function Transfers() {
    // Chakra Color Mode
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <TransfersTable />
        </Box>
    );
}