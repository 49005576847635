// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import React, {useEffect, useState} from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdBarChart, MdOutlineCalendarToday } from "react-icons/md";
// Assets

import {
  marinadaLineChartDataTotalSpent,
  lineChartOptionsTotalSpent, lineChartDataTotalSpent,

} from "variables/charts";

import {getRecentMonths} from "../../../../functions/dateUtils"

export default function TotalTransferInFourMonth(props) {
  const { monthlySum, ...rest } = props;

  // Chakra Color Mode
  const [monthlyTransfersData, setMonthlyTransfersData] = useState([{
    data: [0],
  }])

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  useEffect(() => {
    setMonthlyTransfersData([{
      data: monthlySum
    }])
  }, [monthlySum])

  useEffect(()=>{
    lineChartOptionsTotalSpent.xaxis.categories = getRecentMonths();
  },[])

  return (
    <Card
      justifyContent='center'
      align='center'
      direction='column'
      w='100%'
      mb='0px'
      {...rest}>
      <Flex justify='space-between' ps='0px' pe='20px' pt='5px'>
        <Flex align='center' w='100%'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
         סה״כ העברות חודשיות ₪
        </Text>
        </Flex>
      </Flex>
      <Flex w='100%' flexDirection={{ base: "column", lg: "row" }}>
        <Box minH='260px' minW='100%' mt='auto'>
          <LineChart
            chartData={monthlyTransfersData}
            chartOptions={lineChartOptionsTotalSpent}
          />
        </Box>
      </Flex>
    </Card>
  );
}
