export const TagId = 0
export const TagName = 1
export const TagWH = 2
export const TagDate = 3
export const TagActions = 7

export const tableColumns = [
  {
    Header: "מס׳ חברה",
    accessor: "id",
    tag: TagId,
    width: "10px",
  },
  {
      Header: "חברה",
      accessor: "name",
      tag: TagName,  
      width: "150px"
  },
  {
      Header: "פעולות נוספות",
      accessor: "actions",
      tag: TagActions,
      width: "150px"
  }
];

export const taReportTableColumns = [
    {
        Header: "שם",
        accessor: "name",
        tag: TagName,
        width: "150px",
    },
    {
        Header: "תאריך",
        accessor: "startTime",
        tag: TagDate,
        width: "150px",
    },
    {
        Header: "שעות עבודה",
        accessor: "workingHours",
        tag: TagWH,
        width: "150px",
    }
];