import * as XLSX from "xlsx";
import {Users} from "../api/user";
import { citiesB } from "../data/citiesData";
import {useEffect, useState} from "react";
import {getCompanies} from "./companiesCache";

export const generateEmployeesFile = async (data) => {

    const companiesIdCache = await getCompanies()

    let employees = []
    data.forEach(employee => {
        let element = {};
        if(employee['firstName'] === null || employee['firstName'] === undefined) {
            const fullName =  employee['fullName'].split(' '),
            firstName = fullName[0],
            lastName = fullName[fullName.length - 1];
            element['שם פרטי'] = firstName
            element['שם משפחה'] = lastName
        } else {
            element['שם פרטי'] = employee['firstName']
            element['שם משפחה'] = employee['lastName']
        }
        element['מספר טלפון'] = employee['phoneNumber']
        element['חברה'] = companiesIdCache[Number(employee['companyId'])];
        element['סטטוס'] = Users.statusText(Number(employee['status']))
        if(employee['details'] != null && employee['details'] !== undefined)
        {
            element['ת.ז'] = employee['details']['govId']
            element['תפקיד'] = employee['details']['jobTitle']
            element['מגדר'] = employee['details']['gender']
            element['תאריך לידה'] = employee['details']['dob']
            element['מספר עובד פנימי'] = employee['details']['internalEmployeeId']
            if(employee['details']['cityId'] != null && employee['details']['cityId'] !== undefined) {
                const cityId = employee['details']['cityId']
                element['עיר'] = citiesB.find(i => i.id === cityId).name
            }
        }
        employees.push(element)
    });
    // console.log(employees)
    const worksheet = XLSX.utils.json_to_sheet(employees);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    /* fix headers */
    XLSX.utils.sheet_add_aoa(worksheet, [["שם פרטי", "שם משפחה", "טלפון", "חברה", "סטטוס", "ת.ז", "תפקיד", "מגדר", "תאריך לידה", "מספר עובד פנימי", "עיר"]], { origin: "A1" });

    /* calculate column width */
    worksheet["!cols"] = [ { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 },{ wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 } ];

    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSX.writeFile(workbook, "Employees.xlsx");
}
