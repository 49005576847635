/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Text,
  FormLabel,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { InputMaxLength } from "components/input/InputMaxLength";
import AuthApi from "api/auth";
import { NavigateTo } from "nav/navigator";
import { Page } from "nav/navigator";
import {NavLink, useHistory} from "react-router-dom";
import classes from '../../../layouts/auth/style.module.css';

function SignIn() {

  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState(undefined);
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const history = useHistory();

  const goToOtp = async (response) => {
    // let user = { ...response.data.user };
    // user.token = response.data.token;
    // user = JSON.stringify(user);
    // setUser(user);
    // localStorage.setItem("user", user);
    // return history.push("/dashboards");
    // return history.push("/auth/otp");
    AuthApi.sessionToken = response.data.response.sessionToken;
    localStorage.setItem("tempSessionToken", response.data.response.sessionToken)
    history.push(Page.OTP.value);
  };

  const handleContinue = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (phoneNumber === "") {
      return setError("אנא הכנס מספר טלפון");
    }

    if (phoneNumber.length != 10 || !phoneNumber.match('[0-9]{10}')) {
      return setError("אנא הכנס מספר טלפון תקין");
    }
    try {
      let response = await AuthApi.Auth(phoneNumber);
      if (response.data && response.data.success === false) {
        // setButtonText("Sign in");
        return setError(response.data.msg);
      }
      return goToOtp(response);
    } catch (err) {

      if (err && err.response) {
        switch(err.response.status) {
          case 401:
            return setError("משתמש לא קיים במערכת")
          case 400:
            return setError("אנא הכנס מספר טלפון תקין")
            // code block
        }
      }
      else if (err.message) {
        return setError(err.message);
      }
      return setError("שגיאה");
    }
  };

  return (
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w='100%'
            mx={{ base: "auto", lg: "0px" }}
            me='auto'
            h='100%'
            alignItems='start'
            justifyContent='center'
            bc="green.500"
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "26vh" }}
            flexDirection='column'>
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
              התחברות
            </Heading>
            <Text
                // fontFamily="'Noto Sans Hebrew', sans-serif"
                mb="36px"
                ms="4px"
                color="var(--secondary-grey-600, #A3AED0)"
                fontWeight="400"
                fontSize="20px"
                fontStyle="normal"
                lineHeight="100%" // Equivalent to 20px
                letterSpacing="-0.4px"
            >
              הכנס את מספר הטלפון כדי להתחבר למערכת Payro!
            </Text>
          </Box>
          <Flex
              zIndex='2'
              direction='column'
              w={{ base: "100%", md: "420px" }}
              maxW='100%'
              background='transparent'
              borderRadius='15px'
              mx={{ base: "auto", lg: "unset" }}
              me='auto'
              mb={{ base: "20px", md: "auto" }}>
                   <h4 style={{
                         fontSize: "1.2em",
                         color: "red",
                         textAlign: "center",
                       fontWeight: 400,
                         transition: ".2s all"}}>
                       {error}
                     </h4>

            <FormControl>
              <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'
              >

                <Text
                    color="#2B3674"
                    // fontFamily="'Noto Sans Hebrew', sans-serif"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="500"
                    lineHeight="100%" // Equivalent to 18px
                    letterSpacing="-0.36px"
                >
                  מספר טלפון
                </Text>


              </FormLabel>
                       <InputMaxLength
                          isRequired={true}
                          fontSize='20px'
                          ms={{ base: "0px", md: "0px" }}
                          type='email'
                          placeholder='052-0000000'
                          mb='24px'
                          fontWeight='500'
                          size='lg'
                          maxLength='10'
                          onChange={(event) => {
                            setPhoneNumber(event.target.value);
                            setError(undefined);
                          }}/>



              <Flex justifyContent='space-between' align='center' mb='24px'>


              </Flex>

              <Button
                  className={classes.button}
                  onClick={handleContinue}
                  variant={'brand'}
              >
                התחבר
              </Button>
            </FormControl>
            <Flex
                flexDirection='column'
                justifyContent='center'
                alignItems='start'
                maxW='100%'
                mt='0px'>
              <Text
                  // fontFamily="'Noto Sans Hebrew', sans-serif"
                  color="#2B3674"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="26px" // Equivalent to 162.5% for 16px font size
                  letterSpacing="-0.32px"
                  as="span"
                  ms="5px"
              >
                לא רשום אצלנו?
                <a href='https://www.payro.io/contact-us' target="_blank" rel="noopener noreferrer">
                  <Text
                      // fontFamily="'Noto Sans Hebrew', sans-serif"
                      color="#000"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="700"
                      lineHeight="26px"
                      letterSpacing="-0.32px"
                      as="span"
                      ms="5px"
                  >
                    צור קשר
                  </Text>
                </a>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </DefaultAuth>
  );
}

export default SignIn;
