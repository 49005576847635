import React, {useEffect, useMemo, useRef} from "react";
import CompaniesApi from "../../../../api/companies";
import {
    Button, Flex,
    Modal,
    ModalBody,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, TableContainer, useToast
} from "@chakra-ui/react";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";

import {TagDate, TagName, TagWH, taReportTableColumns} from "./companiesTableData";
import {prettifyDate} from "../../../../functions/dateUtils";
import {generateTimeReportingFile} from "../../../../functions/generateTimeAttendanceReport";
import ReportsApi from "../../../../api/reports";
import IntegrationsApi from "../../../../api/integrations";

export default function TaReportCompanyModal(props) {

    let { isOpen, onClose, company, callBack,
        ...rest } = props;
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [data, setData] = React.useState([])
    const columns = useMemo(() => taReportTableColumns, [taReportTableColumns]);
    const inputRef = useRef(null);
    const toast = useToast()

    useEffect(() => {
        getTaReport()
    }, [company]);

    const handleDownload =() => {
        generateTimeReportingFile(data)
    }

    const handleUpload =() => {
        if(company === null || (
            company.id !== 7 &&
            company.id !== 4 &&
            company.id !== 6 &&
            company.id !== 17 &&
            company.id !== 18 &&
            company.id !== 19 && company.id !== 21)) {
            toast({
                title: `לא נתמך לחברה זו`,
                position: 'top',
                status: 'error',
                isClosable: false,
            })
        } else {
            inputRef.current.click();
        }
    }

    const handleFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        let providerId = null;
        // if kitchen, set tabit provider
        if(company.id === 7) {
            providerId = '6'
        }
        else if(company.id === 4 || company.id === 6 || company.id === 21) {
            providerId = '5'
        }
        // mamila or landver, grantik provider
        else if(company.id === 9 || company.id === 19) {
            providerId = '8'
        }
        else if(company.id === 17) {
            providerId = '14'
        }
        else if(company.id === 18) {
            providerId = '15'
        }
        if(fileObj && providerId && company.id) {
            IntegrationsApi.uploadTaFile(fileObj, providerId, company.id).then(res => {
                if(res.data) {
                    const errors = res.data
                    if(errors != null && errors.length > 0) {
                        toast({
                            title: ` קובץ נטען עם שגיאות ` + JSON.stringify(errors),
                            position: 'top',
                            status: 'success',
                            isClosable: false,
                        })
                    } else {
                        toast({
                            title: `קובץ נטען בהצלחה`,
                            position: 'top',
                            status: 'success',
                            isClosable: false,
                        })
                    }
                 }
            }).catch(e => {
                toast({
                    title: `שגיאה בקובץ`,
                    position: 'top',
                    status: 'error',
                    isClosable: false,
                })
            })
        }
        else if(company.id === null){
                toast({
                    title: `מספר חברה שגוי`,
                    position: 'top',
                    status: 'error',
                    isClosable: false,
                })
        } else if(providerId === null){
            toast({
                title: `לא נמצא אינטגרציה`,
                position: 'top',
                status: 'error',
                isClosable: false,
            })
        }
    };


    const getTaReport = () => {
        if(company) {
            let month = new Date().getMonth()+1;
            CompaniesApi.integrationsTaReport("01/"+ month + "/" + new Date().getFullYear(), company.id).then(res => {

                if(res && res.data.shifts) {
                    setData(res.data.shifts)
                }

            })
                .catch(function (error) {
                    if (error.response) {
                    }
                });
        }
    }


    const tableInstance = useTable(
        {
            columns,
            data,
            autoResetPage: false
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;

    initialState.pageSize = 100;


    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               isCentered>
            <ModalOverlay />
            <ModalContent maxH="600px" maxW={'700px'}>
                <ModalHeader>דו״ח נוכחות</ModalHeader>
                <ModalBody>
                    <Button onClick={handleDownload} backgroundColor={'blue.200'}>Download</Button>
                    <Button marginRight={'30px'} onClick={handleUpload} backgroundColor={'blue.200'}>Upload</Button>
                    <input
                        style={{display: 'none'}}
                        ref={inputRef}
                        type="file"
                        onChange={handleFileChange}
                    />

                    <TableContainer size='sm' maxHeight={'500px'}  overflowY={'auto'} width={'700px'}>
                        <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
                            <Thead>
                                {headerGroups.map((headerGroup, index) => (
                                    <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                        {headerGroup.headers.map((column, index) => (
                                            <Th
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                pe='10px'
                                                key={index}
                                                borderColor={borderColor}>
                                                <Flex
                                                    justify='space-between'
                                                    align='center'
                                                    fontSize={{ sm: "10px", lg: "12px" }}
                                                    color='gray.400'>
                                                    {column.render("Header")}
                                                </Flex>
                                            </Th>
                                        ))}
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody {...getTableBodyProps()}>
                                {page.map((row, index) => {
                                    prepareRow(row);
                                    return (
                                        <Tr {...row.getRowProps()} key={index}>
                                            {row.cells.map((cell, index) => {
                                                let data = "";
                                                if(cell.column.tag === TagName || cell.column.tag === TagWH) {
                                                    data = (
                                                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                            {cell.value}
                                                        </Text>
                                                    );
                                                }
                                                else if(cell.column.tag === TagDate) {
                                                    data = (
                                                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                            {prettifyDate(cell.value)}
                                                        </Text>
                                                    );
                                                }
                                                return (
                                                    <Td
                                                        {...cell.getCellProps()}
                                                        key={index}
                                                        fontSize={{ sm: "14px" }}
                                                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                                        borderColor='transparent'>
                                                        {data}
                                                    </Td>
                                                );
                                            })}
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>סגור</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
