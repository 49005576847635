import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    SimpleGrid,
    useToast, Input,
} from "@chakra-ui/react";

import React from "react";
import ReportsApi from "../../../../api/reports";
import MonthDropdownSelection from "../../../../functions/MonthDropdownSelection";

export default function AdminNewReportModal(props) {

    let { isOpen, onClose, report } = props;
    const toast = useToast()
    const [reportStatus, setReportStatus] = React.useState("")
    const [companyId, setCompanyId] = React.useState("")
    const [payPeriod, setPayPeriod] = React.useState("")

    const handleStatusChange = (event) => {
        const value = event.target.value;
        setReportStatus(value)
    }

    const createReportHandleClick = () => {

        ReportsApi.createEndOfMonthReport(payPeriod, companyId).then(res => {

            toast({
                title: `הדו״ח נוצר בהצלחה`,
                position: 'top',
                status: 'success',
                isClosable: false,
            })
            onClose()

        }).catch(e => {
            toast({
                title: `שגיאה ביצירת הדו״ח`,
                position: 'top',
                status: 'error',
                isClosable: false,
            })
        })
    }

    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               isCentered
               closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>יצירת דו״ח חדש</ModalHeader>
                <ModalBody>

                    <SimpleGrid
                        marginTop='30px'
                        columns={{sm: 2}}
                        gap='20px'
                        mb='0px'>

                        <MonthDropdownSelection handleSelectedMonth={setPayPeriod}/>

                        <Input placeholder='מס׳ חברה' onChange={(event) => {
                            setCompanyId(event.target.value);
                        }} />

                    </SimpleGrid>

                    <br/>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={createReportHandleClick}>צור</Button>
                    <Button onClick={onClose}>סגור</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
