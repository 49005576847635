import * as XLSX from "xlsx";
import {Applications, ApplicationStatus} from "../api/applications";

export const generateEmployeeShiftsFile = (employeeName, shifts) => {

    if(shifts) {

        let shiftsFinal = []
        shifts.forEach(shift => {
            let element = {};
            element['שם'] = employeeName
            element['תאריך'] = shift['shiftDate']
            element['התחלת עבודה'] = shift['startTime']
            element['סוף עבודה'] = shift['endTime']
            element['שעות עבודה'] = shift['workingHours']
            shiftsFinal.push(element)
        });
        // console.log(employees)
        const worksheet = XLSX.utils.json_to_sheet(shiftsFinal);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

        /* fix headers */
        XLSX.utils.sheet_add_aoa(worksheet, [["שם",  "תאריך", "התחלת עבודה", "סוף עבודה", "שעות עבודה"]], { origin: "A1" });

        /* calculate column width */
        // const max_width = employees.reduce((w, r) => Math.max(w, r.length), 10);
        worksheet["!cols"] = [ { wch: 30 }, { wch: 15 }, { wch: 30 }, { wch: 30 }, { wch: 20 } ];

        /* create an XLSX file and try to save to Presidents.xlsx */
        XLSX.writeFile(workbook, "TimeReporting.xlsx");
    }
}

export const generateEmployeeTransfersFile = (employeeName, transfers) => {

    if(transfers) {

        let transfersFinal = []
        transfers.forEach(transfer => {
            let element = {};
            element['שם'] = employeeName
            element['תאריך'] = transfer['createdDate']
            element['סכום'] = transfer['amount']
            element['סטטוס'] = Applications.statusText(transfer['status'])
            transfersFinal.push(element)
        });
        // console.log(employees)
        const worksheet = XLSX.utils.json_to_sheet(transfersFinal);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

        /* fix headers */
        XLSX.utils.sheet_add_aoa(worksheet, [["שם",  "תאריך", "סכום", "סטטוס"]], { origin: "A1" });

        /* calculate column width */
        // const max_width = employees.reduce((w, r) => Math.max(w, r.length), 10);
        worksheet["!cols"] = [ { wch: 30 }, { wch: 15 }, { wch: 30 }, { wch: 30 }, { wch: 20 } ];

        /* create an XLSX file and try to save to Presidents.xlsx */
        XLSX.writeFile(workbook, "Transfers.xlsx");
    }
}
