
// Default = '0',
//     All = '1',
//     MissingPhoneNumber = '2',
//     MissingName = '3',
//     MissingGovId = '4',
//     MissingInternalId = '5',
//     MissingIntegrationUid = '6',
//     MissingBankAccountDetails = '7',

export const prettifyMissingInformationStatus = (code) => {
    switch (code) {
        case '2':
            return "מספר טלפון"
        case '3':
            return "שם"
        case '4':
            return "תעודת זהות"
        case '5':
            return "מספר עובד פנימי"
        case '6':
            return "מזהה אינטגרציה"
        case '7':
            return "פרטי חשבון בנק"
        case '8':
            return "תאריך לידה"
        default:
            return "unknown";
    }
}
