export const fields = [
    {
        // Visible in table header and when matching columns.
        label: "מספר חברה",
        // This is the key used for this field when we call onSubmit.
        key: "employer_id",
        // Allows for better automatic column matching. Optional.
        alternateMatches: ["מספר חברה"],
        // Used when editing and validating information.
        fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
        },
        // Used in the first step to provide an example of what data is expected in this field. Optional.
        example: "3",
        // Can have multiple validations that are visible in Validation Step table.
        validations: [
            {
                // Can be "required" / "unique" / "regex"
                rule: "required",
                errorMessage: "נדרש מספר חברה",
                // There can be "info" / "warning" / "error" levels. Optional. Default "error".
                level: "warning",
            },
        ],
    },
    {
        // Visible in table header and when matching columns.
        label: "שם מלא",
        // This is the key used for this field when we call onSubmit.
        key: "full_name",
        // Allows for better automatic column matching. Optional.
        alternateMatches: ["שם מלא"],
        // Used when editing and validating information.
        fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
        },
        // Used in the first step to provide an example of what data is expected in this field. Optional.
        example: "אבי",
        // Can have multiple validations that are visible in Validation Step table.
        validations: [
            {
                // Can be "required" / "unique" / "regex"
                rule: "required",
                errorMessage: "נדרש שם פרטי",
                // There can be "info" / "warning" / "error" levels. Optional. Default "error".
                level: "warning",
            },
        ],
    },
    {
        // Visible in table header and when matching columns.
        label: "שם פרטי",
        // This is the key used for this field when we call onSubmit.
        key: "first_name",
        // Allows for better automatic column matching. Optional.
        alternateMatches: ["שם פרטי"],
        // Used when editing and validating information.
        fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
        },
        // Used in the first step to provide an example of what data is expected in this field. Optional.
        example: "אבי",
        // Can have multiple validations that are visible in Validation Step table.
        validations: [
            {
                // Can be "required" / "unique" / "regex"
                rule: "required",
                errorMessage: "נדרש שם פרטי",
                // There can be "info" / "warning" / "error" levels. Optional. Default "error".
                level: "warning",
            },
        ],
    },
    {
        // Visible in table header and when matching columns.
        label: "שם משפחה",
        // This is the key used for this field when we call onSubmit.
        key: "last_name",
        // Allows for better automatic column matching. Optional.
        alternateMatches: ["שם משפחה"],
        // Used when editing and validating information.
        fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
        },
        // Used in the first step to provide an example of what data is expected in this field. Optional.
        example: "אבי",
        // Can have multiple validations that are visible in Validation Step table.
        validations: [
            {
                // Can be "required" / "unique" / "regex"
                rule: "required",
                errorMessage: "נדרש שם משפחה",
                // There can be "info" / "warning" / "error" levels. Optional. Default "error".
                level: "warning",
            },
        ],
    },
    {
        label: "תעודת זהות",
        key: "id",
        alternateMatches: ["תעודת זהות", "ת.ז", "מספר זהות"],
        fieldType: {
            type: "input",
        },
        example: "123456789",
        validations: [
            {
                rule: "required",
                errorMessage: "רצוי להזין תעודת זהות",
                level: "warning",
            },
        ],
    },
    {
        label: "מספר דרכון",
        key: "passport_id",
        alternateMatches: ["מספר דרכון"],
        fieldType: {
            type: "input",
        },
        example: "123456789",
        validations: [
        ],
    },
    {
        label: "מספר עובד",
        key: "employee_id",
        alternateMatches: ["מספר עובד"],
        fieldType: {
            type: "input",
        },
        example: "123",
        validations: [
            {
                rule: "required",
                errorMessage: "נדרש מספר עובד",
                level: "warning",
            },
        ],
    },
    {
        label: "טלפון",
        key: "phone_number",
        alternateMatches: ["טלפון נייד", "טלפון"],
        fieldType: {
            type: "input",
        },
        example: "054-0776776",
        validations: [
            {
                rule: "required",
                errorMessage: "חובה להכניס מספר טלפון",
                level: "warning",
            },
        ],
    },
    {
        label: "מין",
        key: "gender",
        alternateMatches: ["מין", "מגדר", "קוד מין"],
        fieldType: {
            type: "input",
        },
        example: "זכר/נקבה",
        validations: [
            {
            },
        ],
    },
    {
        label: "תפקיד",
        key: "job_title",
        alternateMatches: ["תפקיד"],
        fieldType: {
            type: "input",
        },
        example: "מחסנאי",
        validations: [
            {
                rule: "required",
                errorMessage: "רצוי להכניס תפקיד",
                level: "warning",
            },
        ],
    },
    {
        label: "מזהה אינטגרציה",
        key: "integration_id",
        alternateMatches: ["מזהה אינטגרציה"],
        fieldType: {
            type: "input",
        },
        example: "1235",
        validations: [
            {
                rule: "required",
                errorMessage: "ניתן להזין מזהה איטגרציה",
                level: "warning",
            },
        ],
    },
    {
        label: "תאריך התחלת עבודה",
        key: "start_date",
        alternateMatches: ["תאריך תחילת עבודה", "תחילת עבודה"],
        fieldType: {
            type: "input",
        },
        example: "02/08/2021",
        validations: [
        ],
    },
    {
        label: "תאריך לידה",
        key: "dob_date",
        alternateMatches: ["תאריך לידה"],
        fieldType: {
            type: "input",
        },
        example: "02/08/2021",
        validations: [
        ],
    },
    {
        label: "עיר",
        key: "city_name",
        alternateMatches: ["עיר", "ישוב", "כתובת - ישוב"],
        fieldType: {
            type: "input",
        },
        example: "02/08/2021",
        validations: [
        ],
    },
    {
        label: "שכר שעתי (ברוטו)",
        key: "salary_per_hour",
        alternateMatches: ["שכר שעתי", "שכר"],
        fieldType: {
            type: "input",
        },
        example: "30",
        validations: [
            {
                rule: "required",
                errorMessage: "רצוי להזין שכר בסיס",
                level: "warning",
            },
        ],
    },
    {
        label: "שכר חודשי (ברוטו)",
        key: "salary_per_month",
        alternateMatches: ["שכר שעתי", "שכר"],
        fieldType: {
            type: "input",
        },
        example: "30",
        validations: [
            {
                rule: "required",
                errorMessage: "רצוי להזין שכר בסיס",
                level: "warning",
            },
        ],
    },
    {
        label: "מספר בנק",
        key: "bank_id",
        alternateMatches: ["בנק", "מספר בנק"],
        fieldType: {
            type: "input",
        },
        example: "1",
        validations: [
            {
                rule: "required",
                errorMessage: "נדרש להזין מספר בנק",
                level: "warning",
            },
        ],
    },
    {
        label: "מספר סניף",
        key: "bank_branch_id",
        alternateMatches: ["מספר סניף", "סניף", "בנק - מספר סניף"],
        fieldType: {
            type: "input",
        },
        example: "30",
        validations: [
            {
                rule: "required",
                errorMessage: "נדרש להזין מספר סניף",
                level: "warning",
            },
        ],
    },
    {
        label: "מספר חשבון בנק",
        key: "bank_account_id",
        alternateMatches: ["מספר חשבון בנק", "בנק - מספר חשבון"],
        fieldType: {
            type: "input",
        },
        example: "123456",
        validations: [
            {
                rule: "required",
                errorMessage: "נדרש להזין מספר חשבון בנק",
                level: "warning",
            },
        ],
    },
    {
        label: "פרטי חשבון בנק",
        key: "bank_account_details",
        alternateMatches: ["פרטי חשבון בנק"],
        fieldType: {
            type: "input",
        },
        example: "10/123/45678",
        validations: [
            {
                rule: "required",
                errorMessage: "נדרש להזין פרטי חשבון בנק",
                level: "warning",
            },
        ],
    }
].reverse();
