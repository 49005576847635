import React, {useEffect, useState} from 'react';
import { Table, TableContainer, Thead, Tbody, Tr, Th, Td, Button } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import {getCompanies} from "../../../../functions/companiesCache";

const SelectedFiltersView = ({ selectedFilters, handleRemoveFilter, includeCompanyName }) => {
    const statusOptionsMapping = {
        "100": "לא פעיל",
        "200": "פעיל",
        "300": "חסום",
        "400": "עזב",
    };

    const [companiesIdCache, setCompaniesIdCache] = useState({});

    useEffect(() => {
        if(includeCompanyName) {
            (async () => {
                const cache = await getCompanies();
                setCompaniesIdCache(cache);
            })();
        }
    }, []);



    return (
        <TableContainer>
            <Table variant='simple'>
                <Thead>
                    <Tr>
                        {selectedFilters.map(([filterName, _]) => (
                            <Th key={filterName}>{filterName}</Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        {selectedFilters.map(([filterName, filterValue]) => {
                            let displayValue = filterValue;

                            // Map status value to label
                            if (filterName === 'status' && statusOptionsMapping[filterValue]) {
                                displayValue = statusOptionsMapping[filterValue];
                            }
                            if (filterName === 'companyId' && companiesIdCache[filterValue]) {
                                displayValue = companiesIdCache[filterValue];
                            }

                            return (
                                <Td key={filterName}>
                                    <Button
                                        onClick={() => handleRemoveFilter(filterName)}
                                        rightIcon={<CloseIcon />}
                                        textColor={'white'}
                                        variant={'brand'}
                                    >
                                        {displayValue}
                                    </Button>
                                </Td>
                            );
                        })}
                    </Tr>
                </Tbody>
            </Table>
        </TableContainer>
    );
};

export default SelectedFiltersView;
