import MockApi from "./mockApi";
import {API} from "./env";
import {axiosInstance} from "./httpClient";

export const ApplicationStatus = {
    Canceled: 0,
    Declined: 100,
    PendingOtpConfirm: 200,
    PendingTransfer: 300,
    PendingApproval: 400,
    Transferred: 500,
    TransferReceipt: 600,
    Completed: 700
}

export class Applications {
    // this should be in sync with backend status

    static statusText = (status) => {
        switch (status) {
            case ApplicationStatus.Canceled:
                return "בוטל"
            case ApplicationStatus.Declined:
                return "נדחה"
            case ApplicationStatus.PendingApproval:
            case ApplicationStatus.PendingTransfer:
                return "ממתין להעברה"
            case ApplicationStatus.Transferred:
                return "הועבר"
            case ApplicationStatus.TransferReceipt:
                return "הועבר בהצלחה"
            case ApplicationStatus.Completed:
                return "הושלם"
            default:
                return "NONE"
        }
    }
}

export default class ApplicationsApi {

    static getAll = (status, startDate, endDate) => {

        if(MockApi.IsActive) {
            return MockApi.GetTransfers();
        }

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
        };
        if(startDate && endDate) {
            body['startDate'] = startDate;
            body['endDate'] = endDate;
        }
        if(status && status.length > 0) {
            body['status'] = status;
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/company/applications/list`, JSON.stringify(body), config);
    };

    static updateStatus = (status, applications) => {
        let idsArray = [];
        for(const application of applications) {
            idsArray.push(application.id);
        }
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
            id: idsArray,
            status: status
        };

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/applications/status`, JSON.stringify(body), config);
    };
}

