import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    FormControl,
    FormLabel,
    Input,
    ModalFooter,
    SimpleGrid,
    Select, useToast, Flex, Text, Spinner, Textarea,
} from "@chakra-ui/react";
  import EmployeesApi from "../../../../api/employees"
  import {useEffect, useMemo, useState} from "react";
  import { citiesB } from "../../../../data/citiesData";
import {prettifyMissingInformationStatus} from "../../../../functions/common";
import {generateEmployeeShiftsFile, generateEmployeeTransfersFile} from "../../../../functions/generateEmployeeReports";
import {AutoCompleteSelector} from "../../../../functions/AutoCompleteSelector";

export default function EditUserModal(props) {

    let { isOpen, onClose, employee, callBack, ...rest } = props;

    const citiesData = useMemo(() => citiesB, [citiesB]);
    const toast = useToast();
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false)

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [govId, setGovId] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [dob, setDob] = useState("");
    const [gender, setGender] = useState("");
    const [roles, setRoles] = useState("");
    const [status, setStatus] = useState("");
    const [cityId, setCityId] = useState(undefined);
    const [companyId, setCompanyId] = useState("");
    const [internalId, setInternalId] = useState("");
    const [integrationUid, setIntegrationUid] = useState("");
    const [jobDepartment, setJobDepartment] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [branch, setBranch] = useState("");
    const [bankId, setBankId] = useState("");
    const [accountId, setAccountId] = useState("");
    const [branchId, setBranchId] = useState("");
    const [bankRecipientName, setBankRecipientName] = useState("");

    const [shiftsCount, setShiftsCount] = useState("")
    const [shiftsHistory, setShiftsHistory] = useState([])
    const [totalWorkingHours, setTotalWorkingHours] = useState("")
    const [hourlyGrossRate, setHourlyGrossRate] = useState("")
    const [availableToTransferFactor, setAvailableToTransferFactor] = useState("");
    const [monthlyGrossRate, setMonthlyGrossRate] = useState("")
    const [earnedPay, setEarnedPay] = useState("")
    const [totalTransferred, setTotalTransferred] = useState("")
    const [availableToTransfer, setAvailableToTransfer] = useState("")
    const [transfersHistory, setTransfersHistory] = useState([])
    const [salaryChanged, setSalaryChanged] = useState(false)

    const [endDate, setEndDate] = useState(undefined);
    const [employeeTransferLimit, setEmployeeTransferLimit] = useState(undefined);
    const [notes, setNotes] = useState("");

    const [missingInformation , setMissingInformation] = useState([])

    useEffect(() => {
        if(isOpen) {
            setLoading(true)
            setTimeout(()=>{
                EmployeesApi.getEmployeeDetails(employee.companyId, employee.id).then(res => {
                    const employee = res.data.details;
                    const employeeDetails = res.data.details.details;
                    const timeAttendance = res.data.timeAttendance;
                    const salaryDetails = res.data.salaryDetails;
                    const transfers = res.data.transfers;
                    if(employee.firstName != null){
                        setFirstName(employee.firstName)
                    }
                    if(employee.lastName != null) {
                        setLastName(employee.lastName)
                    }
                    if(employee.phoneNumber != null) {
                        setPhoneNumber(employee.phoneNumber)
                    }
                    if(employee.status != null) {
                        setStatus(employee.status)
                    }
                    if(employee.companyId != null) {
                        setCompanyId(employee.companyId)
                    }
                    if(employee.informationStatus != null) {
                        setMissingInformation(employee.informationStatus)
                    }
                    if(employeeDetails != null) {
                        if(employeeDetails.govId != null) {
                            setGovId(employeeDetails.govId)
                        }
                        if(employeeDetails.dob != null) {
                            setDob(employeeDetails.dob)
                        }
                        if(employeeDetails.gender != null) {
                            setGender(employeeDetails.gender)
                        }
                        if (employee.roles != null) {
                            const employeeRoles = employee.roles;

                            let roleString = "Unknown Role";

                            if (employeeRoles.includes("admin")) {
                                roleString = "הכל שלך";
                            } else if (employeeRoles.includes("employerUserAdmin")) {
                                roleString = 'employerUserAdmin';
                            } else if (employeeRoles.includes("employee")) {
                                roleString = "employee";
                            }

                            setRoles(roleString);
                        }


                        if(employeeDetails.cityId != null) {
                            setCityId(employeeDetails.cityId)
                        }
                        if(employeeDetails.internalEmployeeId != null) {
                            setInternalId(employeeDetails.internalEmployeeId)
                        }
                        if(employeeDetails.integrationUid != null) {
                            setIntegrationUid(employeeDetails.integrationUid)
                        }
                        if(employeeDetails.jobDepartment != null) {
                            setJobDepartment(employeeDetails.jobDepartment)
                        }
                        if(employeeDetails.jobTitle != null) {
                            setJobTitle(employeeDetails.jobTitle)
                        }
                        if(employeeDetails.branch) {
                            setBranch(employeeDetails.branch);
                        }
                        if(employeeDetails.bankId != null) {
                            setBankId(employeeDetails.bankId)
                        }
                        if(employeeDetails.bankAccountId != null) {
                            setAccountId(employeeDetails.bankAccountId)
                        }
                        if(employeeDetails.bankBranchId != null) {
                            setBranchId(employeeDetails.bankBranchId)
                        }
                        if(employeeDetails.bankRecipientName != null) {
                            setBankRecipientName(employeeDetails.bankRecipientName)
                        }
                        if(employeeDetails.endDate != null) {
                            setEndDate(employeeDetails.endDate)
                        }
                        setEmployeeTransferLimit(employeeDetails.monthlyTransfersLimit)

                        if(employeeDetails.notes != null) {
                            setNotes(employeeDetails.notes)
                        }

                    }
                    if(timeAttendance != null) {
                        if(timeAttendance.shifts != null) {
                            setShiftsCount(timeAttendance.shifts.length)
                            setShiftsHistory(timeAttendance.shifts)
                        }
                        if(timeAttendance.workingHours) {
                            setTotalWorkingHours(timeAttendance.workingHours)
                        }
                    }
                    if(salaryDetails != null) {
                        if(salaryDetails.hourlyGrossRate != null) {
                            setHourlyGrossRate(salaryDetails.hourlyGrossRate)
                        }
                        if(salaryDetails.monthlyGrossRate != null) {
                            setMonthlyGrossRate(salaryDetails.monthlyGrossRate)
                        }
                        if(salaryDetails.earnedPay) {
                            setEarnedPay(salaryDetails.earnedPay)
                        }
                        if(salaryDetails.availableToTransferFactor) {
                            setAvailableToTransferFactor(salaryDetails.availableToTransferFactor)
                        }
                    }

                    if(transfers !== null) {
                        if(transfers.totalTransferred != null) {
                            setTotalTransferred(transfers.totalTransferred)
                        }
                        if(transfers.availableToTransfer != null) {
                            setAvailableToTransfer(transfers.availableToTransfer)
                        }
                        if(transfers.history != null) {
                            setTransfersHistory(transfers.history)
                        }
                    }

                    setLoading(false)
                }).catch(e => {
                })
            }, 200)
        } else {
            setLoading(false)
            setFirstName("")
            setLastName("")
            setGovId("")
            setPhoneNumber("")
            setDob("")
            setGender("")
            setRoles("")
            setStatus("")
            setCityId(undefined)
            setCompanyId("")
            setInternalId("")
            setIntegrationUid("")
            setJobDepartment("")
            setJobTitle("")
            setBranch("")
            setBankId("")
            setAccountId("")
            setBranchId("")
            setBankRecipientName("")
            setMissingInformation([])
            setShiftsCount("")
            setShiftsHistory([])
            setTotalWorkingHours("")
            setHourlyGrossRate("")
            setMonthlyGrossRate("")
            setAvailableToTransferFactor("")
            setEarnedPay("")
            setTotalTransferred("")
            setAvailableToTransfer("")
            setTransfersHistory([])
            setSalaryChanged(false)
            setEmployeeTransferLimit("")
            setNotes("")
        }
    }, [isOpen])

    const downloadEmployeeShiftsReport = ()=> {
        if(shiftsHistory === null || shiftsHistory.length === 0) {
            toast({
                title: `אין משמרות החודש`,
                position: 'top',
                status: 'error',
                isClosable: false,
            })
            return
        }

        let employeeName;
        if(firstName != null) {
            employeeName = firstName;
        }
        if(lastName) {
            employeeName = employeeName + ' ' + lastName;
        }
        generateEmployeeShiftsFile(employeeName, shiftsHistory)
    }

    const downloadTransfersReport = () => {
        if(transfersHistory === null || transfersHistory.length === 0) {
            toast({
                title: `אין העברות החודש`,
                position: 'top',
                status: 'error',
                isClosable: false,
            })
            return
        }
        let employeeName;
        if(firstName != null) {
            employeeName = firstName;
        }
        if(lastName) {
            employeeName = employeeName + ' ' + lastName;
        }
        generateEmployeeTransfersFile(employeeName, transfersHistory)
    }

    const updateEmployee = () => {
          setError(undefined)
          let employeeDetailsJson = {
          }
          let bankDetailsJson = {
          }
          let salaryDetailsJson = {
          }

          let employeeJson = {
            "userId": employee.id
          }
          if(firstName) {
            employeeJson["firstName"] = firstName;
          }
          if(lastName) {
            employeeJson["lastName"] = lastName;
          }
        if(roles) {
            employeeJson["roles"] = roles
        }
          if(phoneNumber) {
            employeeJson["phoneNumber"] = phoneNumber;
          }
            if(status != null && status.length > 0) {
                employeeJson["status"] = Number(status);
            }
        if(govId) {
            employeeDetailsJson["govId"] = govId;
          }
          if(dob) {
            employeeDetailsJson["dob"] = dob;
          }
          if(gender) {
            employeeDetailsJson["gender"] = gender;
          }

          if(cityId) {
            employeeDetailsJson["cityId"] = cityId;
          }
          if(companyId) {
            employeeDetailsJson["employerId"] = companyId;
          }
          if(internalId) {
            employeeDetailsJson["internalId"] = internalId;
          }

          if(integrationUid) {
            employeeDetailsJson["integrationId"] = integrationUid;
          }

          if(branch) {
              employeeDetailsJson["branch"] = branch;
          }
        if(jobTitle) {
            employeeDetailsJson["jobTitle"] = jobTitle;
          }
          if(jobDepartment) {
            employeeDetailsJson["jobDepartment"] = jobDepartment;
          }
          if(bankId) {
            bankDetailsJson["bankId"] = bankId;
          }
          if(branchId) {
            bankDetailsJson["branchId"] = branchId;
          }
          if(accountId) {
            bankDetailsJson["accountId"] = accountId;
          }
          if(bankRecipientName) {
            bankDetailsJson["bankRecipientName"] = bankRecipientName;
          }
          if(cityId){
            employeeDetailsJson["cityId"] = cityId
          }
          if(employeeTransferLimit){
              employeeDetailsJson["transferLimit"] = Number(employeeTransferLimit);
          }
          else{
              employeeDetailsJson["transferLimit"] = employeeTransferLimit;
          }

        if(endDate){
            employeeDetailsJson["endDate"] = endDate;
        }
        if(notes){
            employeeDetailsJson["notes"] = notes;
        }

        if(salaryChanged) {
              if(hourlyGrossRate) {
                  salaryDetailsJson["hourlyGrossRate"] = hourlyGrossRate;
              }
              if(monthlyGrossRate) {
                  salaryDetailsJson["monthlyGrossRate"] = monthlyGrossRate;
              }
              if(availableToTransferFactor) {
                    salaryDetailsJson["availableToTransferFactor"] = availableToTransferFactor;
              }
          }
          if(Object.keys(employeeDetailsJson).length > 0) {
            employeeJson["details"] = employeeDetailsJson;
          }
          if(Object.keys(bankDetailsJson).length > 0) {
            employeeJson["details"]["bankAccountDetails"] = bankDetailsJson;
          }
          if(Object.keys(salaryDetailsJson).length > 0) {
            employeeJson["details"]["salaryDetails"] = salaryDetailsJson;
          }
          EmployeesApi.update(employeeJson).then(res => {
              callBack()
              onClose()

              toast({
                  title: `השינויים נשמרו בהצלחה`,
                  position: 'top',
                  status: 'success',
                  isClosable: false,
              })

          })
            .catch(function (error) {
              if (error.response) {
              }
              setError("שגיאה")
                toast({
                    title: `שגיאה בשמירת הנתונים`,
                    position: 'top',
                    status: 'error',
                    isClosable: false,
                })

            });
    }

    const blurStyles = loading === true ? {
        'transition': 'filter 400ms',
        'WebkitFilter': 'blur(15px)',
        'filter': 'blur(15px)'
    } : {} ;

    return (
        <Modal isOpen={isOpen}
         onClose={onClose}
         isCentered>
        <ModalOverlay />
        <ModalContent maxW="1000px">
          <ModalHeader>
              עריכת פרטי עובד
              { loading &&  <Spinner marginRight={'15px'} size='md' /> }
          </ModalHeader>
          <ModalBody width={'100%'}>

              <h4 style={{
                fontSize: "1.2em",
                color: "red",
                textAlign: "center",
                fontWeight: 400,
                transition: ".2s all"}}>
              {error}
            </h4>

              {missingInformation.length > 0 &&
                  <Flex flexDirection={'column'} marginBottom={'20px'}>
                      <Text fontWeight={'bold'} fontSize={'18px'} textColor={'orange'}>חסרים פרטים</Text>
                      {'\n'}
                      {missingInformation.map((value) => (
                          <h4 key={value} style={{
                              fontSize: "1em",
                              textAlign: "right",
                              fontWeight: '600',
                              transition: ".2s all"}}>
                              - {prettifyMissingInformationStatus(value)}
                          </h4>
                      ))}

                  </Flex>
              }

              <SimpleGrid
                  columns={{sm: 2}}
                  gap='30px'
                  mb='0px'
              style={blurStyles}>

                  <Flex
                  flexDirection={'column'}>
                      <FormLabel fontWeight='bold'>פרטים אישיים</FormLabel>

                 <SimpleGrid
                    columns={{sm: 2}}
                    gap='10px'
                    mb='0px'>
                    <FormControl mt={4}>
                    <Input defaultValue={firstName} onChange={(event) => {
                        setFirstName(event.target.value);
                        }} />
                    </FormControl>

                     <FormControl mt={4}>
                        <Input defaultValue={lastName} placeholder='שם משפחה' onChange={(event) => {
                        setLastName(event.target.value);
                        }} />
                    </FormControl>

                    <FormControl mt={4}>
                    <Input defaultValue={govId} placeholder='תעודת זהות' onChange={(event) => {
                        setGovId(event.target.value);
                        }} />
                    </FormControl>

                    <FormControl mt={4}>
                        <Input defaultValue={phoneNumber} placeholder='טלפון' onChange={(event) => {
                        setPhoneNumber(event.target.value);
                        }} />

                    </FormControl>

                    <FormControl mt={4}>
                        <Input defaultValue={dob} type='date' placeholder='תאריך לידה' onChange={(event) => {
                        setDob(event.target.value);
                        }} />
                    </FormControl>

                    <Select value={gender} pr='11px' placeholder='בחר מגדר' mt={4} name="gender" onChange={(event) => { setGender(event.target.value); }}  >
                        <option value='male'>גבר</option>
                        <option value='female'>אישה</option>
                        <option value='other'>אחר</option>
                    </Select>



                     <FormControl mt={4}>
                        <AutoCompleteSelector state={cityId} stateSetter={setCityId} data={citiesData} />
                     </FormControl>

                     <Select defaultValue={status} key={status} pr='11px'  placeholder='בחר סטטוס' mt={4} name="status" onChange={(event) => {
                         if(event.target.value == 400) {
                             const currentDate = new Date();
                             setEndDate(currentDate)
                         }
                         setStatus(event.target.value); }}  >
                         <option value='100'>לא פעיל</option>
                         <option value='200'>פעיל</option>
                         <option value='300'>חסום</option>
                         <option value='400'>עזב</option>

                     </Select>
                     <Select pr='10px' value={roles} placeholder='בחר הרשאה' mt={4} name="roles" onChange={(event) => { setRoles(event.target.value); }}>
                         <option value='employerUserAdmin'>מעסיק</option>
                         <option value='employee'>עובד</option>
                     </Select>
                 </SimpleGrid>

                      <br/>
                      <Textarea
                          defaultValue={notes !== "" ? notes : undefined}
                          placeholder='הוסף הערה'
                          onChange={(event) => {
                              setNotes(event.target.value);
                          }}
                          rows={5}
                          cols={100}
                      />
                <br/>



            <FormLabel fontWeight='bold'>פרטי מעסיק</FormLabel>

                <SimpleGrid
                        columns={{  lg: 2 }}
                        gap='15px'
                        mb='0px'>

                <FormControl mt={4}>
                  <Input defaultValue={internalId} placeholder='מס עובד' onChange={(event) => {
                            setInternalId(event.target.value);
                            }}/>
                </FormControl>

                    <FormControl mt={4}>
                        <Input defaultValue={integrationUid} placeholder='מזהה נוכחות' onChange={(event) => {
                            setIntegrationUid(event.target.value);
                        }}/>
                    </FormControl>

                    <FormControl mt={4}>
                  <Input defaultValue={jobDepartment} placeholder='מחלקה' onChange={(event) => {
                            setJobDepartment(event.target.value);
                            }}/>
                </FormControl>

                <FormControl mt={4}>
                  <Input defaultValue={jobTitle} placeholder='תפקיד' onChange={(event) => {
                            setJobTitle(event.target.value);
                            }}/>
                </FormControl>

                <FormControl mt={4}>
                    <Input defaultValue={branch} placeholder='סניף' onChange={(event) => {
                        setBranch(event.target.value);
                    }}/>
                </FormControl>

                </SimpleGrid>

            <br/>

            <FormLabel fontWeight='bold'>פרטי חשבון בנק</FormLabel>

               <SimpleGrid
                    columns={{  lg: 3 }}
                    gap='15px'
                    mb='0px'>


                   <Select value={bankId} key={bankId} placeholder={"בחר בנק"} pr='10px'  mt={4} onChange={(event) => {
                            setBankId(event.target.value);
                            }}>
                        <option value='12'>הפועלים</option>
                        <option value='9'>בנק הדואר</option>
                        <option value='14'>אוצר החייל</option>
                        <option value='11'>דיסקונט</option>
                        <option value='10'>לאומי</option>
                        <option value='20'>מזרחי טפחות</option>
                        <option value='18'>וואן זירו</option>
                        <option value='31'>הבינלאומי</option>
                        <option value='13'>אגוד</option>
                        <option value='4'>בנק יהב</option>
                        <option value='54'>ירושלים</option>
                        <option value='46'>מסד</option>
                        <option value='17'>מרכנתיל דיסקונט</option>
                        <option value='34'>ערבי ישראלי</option>
                        <option value='26'>יובנק</option>
                    </Select>



                    <FormControl mt={4}>
                        <Input defaultValue={branchId} placeholder='סניף' onChange={(event) => {
                            setBranchId(event.target.value);
                            }} />
                    </FormControl>

                    <FormControl mt={4}>
                        <Input defaultValue={accountId} placeholder='חשבון' onChange={(event) => {
                            setAccountId(event.target.value);
                            }}/>

                    </FormControl>
                </SimpleGrid>

                      <Input mt={'10px'} defaultValue={bankRecipientName} placeholder='מוטב להעברה' onChange={(event) => {
                          setBankRecipientName(event.target.value);
                      }}/>

                  </Flex>

                  <Flex

                      backgroundColor={'transparent'}
                      flexDirection={'column'}>

                      <FormLabel fontWeight='bold'>פרטי שכר</FormLabel>

                      <SimpleGrid
                          columns={{sm: 2}}
                          gap='10px'
                          mb='0px'>

                          <FormControl mt={4}>
                              <Text>יחס צבירה</Text>

                          </FormControl>

                          <FormControl mt={2}>
                              <Input defaultValue={availableToTransferFactor} onChange={(event) => {
                                  setSalaryChanged(true)
                                  setAvailableToTransferFactor(event.target.value);
                              }} />
                          </FormControl>

                          <FormControl mt={4}>
                              <Text>שכר שעתי ברוטו</Text>

                          </FormControl>

                          <FormControl mt={2}>
                              <Input defaultValue={hourlyGrossRate} onChange={(event) => {
                                  setSalaryChanged(true)
                                  setHourlyGrossRate(event.target.value);
                              }} />
                          </FormControl>

                          <FormControl mt={2}>
                              <Text>שכר חודשי ברוטו</Text>
                          </FormControl>

                          <FormControl mt={2}>
                              <Input defaultValue={monthlyGrossRate} onChange={(event) => {
                                  setSalaryChanged(true)
                                  setMonthlyGrossRate(event.target.value);
                              }} />

                          </FormControl>

                          <FormControl mt={2}>
                              <Text>שכר צבור</Text>
                          </FormControl>

                          <FormControl mt={2}>
                              <Input readOnly={true} defaultValue={earnedPay} />

                          </FormControl>

                      </SimpleGrid>

                      <Flex marginTop={'10px'} alignItems={'center'} justifyContent={'space-between'}>
                          <FormLabel fontWeight='bold' mt={"15px"}>נוכחות החודש</FormLabel>
                          <Button marginLeft={'15px'} height={'35px'} backgroundColor={'green.200'} onClick={downloadEmployeeShiftsReport} >הורדה</Button>
                      </Flex>

                      <SimpleGrid
                          columns={{sm: 2}}
                          gap='10px'
                          mb='0px'>
                          <FormControl mt={4}>
                              <Text>מספר משמרות</Text>

                          </FormControl>

                          <FormControl mt={4}>
                              <Input readOnly={true} defaultValue={shiftsCount} />
                          </FormControl>

                          <FormControl mt={4}>
                              <Text>סה״כ שעות עבודה</Text>
                          </FormControl>

                          <FormControl mt={4}>
                              <Input readOnly={true} defaultValue={totalWorkingHours} />

                          </FormControl>
                      </SimpleGrid>


                      <Flex marginTop={'10px'} alignItems={'center'} justifyContent={'space-between'}>
                          <FormLabel fontWeight='bold' mt={"15px"}>העברות החודש</FormLabel>
                          <Button marginLeft={'15px'} height={'35px'} backgroundColor={'green.200'} onClick={downloadTransfersReport}>הורדה</Button>
                      </Flex>

                      <SimpleGrid
                          columns={{sm: 2}}
                          gap='10px'
                          mb='0px'>
                          <FormControl mt={4}>
                              <Text>סה״כ העברות</Text>

                          </FormControl>

                          <FormControl mt={4}>
                              <Input readOnly={true} defaultValue={totalTransferred} />
                          </FormControl>

                          <FormControl mt={4}>
                              <Text>יתרה להעברה</Text>
                          </FormControl>

                          <FormControl mt={4}>
                              <Input readOnly={true} defaultValue={availableToTransfer} />
                          </FormControl>

                          <FormControl mt={4}>
                              <Text>מסגרת חודשית</Text>
                          </FormControl>
                          <FormControl mt={4}>
                              <Input defaultValue={employeeTransferLimit} onInput={(event) => {
                                  setEmployeeTransferLimit(event.target.value === "" ? null : event.target.value);
                              }} />
                          </FormControl>
                      </SimpleGrid>

                  </Flex>


              </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button marginLeft={'15px'} onClick={updateEmployee}>שמור</Button>
            <Button onClick={onClose}>סגור</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}
