/* eslint-disable */
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Flex,
    Icon,
    IconButton,
    Select,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    useColorModeValue,
    useDisclosure
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React, {useEffect, useMemo, useState} from "react";
import {usePagination, useSortBy, useTable,} from "react-table";
import {
    TagActions,
    TagDob,
    TagEmployerId,
    TagEmployerName,
    TagFirstName,
    TagId,
    TagJob,
    TagLastName,
    TagNone,
    TagPhoneNumber,
    TagStatus,
    usersColumns
} from "views/admin/users/data/usersData";
import {citiesB} from "../../../../data/citiesData";
import EmployeesApi from "../../../../api/employees"
import AddNewUserModal from "./AddNewUserModal";
import EditUserModal from "./EditUserModal";
import {ReactSpreadsheetImport} from "react-spreadsheet-import";
import {Users} from "../../../../api/user";
import {generateEmployeesFile} from "../../../../functions/generateEmployeesFile";
import {MdOutlineError} from "react-icons/md";
import AddNewEmployeesFileModal from "../../../company/employees/components/AddNewEmployeesFileModal";
import {ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";
import {translations} from "./translations";
import {fields} from "./Tablefields";
import SelectedFiltersView from "./SelectedFiltersView"
import UserFilterModal from "./UserFilterModal";
import {getCompanies} from "../../../../functions/companiesCache";

const DEFAULT_PAGE = 0;
export default function UsersTable() {

    const [selectedFilters, setSelectedFilters] = useState([]);
    const [isFilterModalOpen, setFilterModalOpen] = useState(false);
    const columns = useMemo(() => usersColumns, [usersColumns]);
    const [data, setData] = React.useState([])
    const [selectedUser, setSelectedUser] = React.useState(null)
    const [usersFull, setUsersFull] = React.useState([])
    const [pageCount, setPageCount] = React.useState(0);
    const [queryFilter, setQueryFilter] = React.useState({});



    const {
        getTableProps,
        getTableBodyProps,
        pageOptions,
        gotoPage,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,

            initialState: { pageIndex: DEFAULT_PAGE, pageSize: 10 },
            pageCount,
            manualPagination: true,
            autoResetPage: false
        },
        useSortBy,
        usePagination
    )


    const [companiesIdCache, setCompaniesIdCache] = useState({});
    const [isCacheLoaded, setIsCacheLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            const cache = await getCompanies();
            setCompaniesIdCache(cache);
            setIsCacheLoaded(true);  // Set the flag to true once the cache is loaded
        })();
    }, []);

    // call refreshEmployees only when the cache has been loaded and other states change
    useEffect(() => {
        if (isCacheLoaded) {  // Ensure cache is loaded before calling refreshEmployees
            refreshEmployees(queryFilter);
        }
    }, [isCacheLoaded, pageSize, pageIndex, queryFilter]);


    const handleFilterApply = (filters) => {
        setSelectedFilters(Object.entries(filters).filter(([_, value]) => value));
        setQueryFilter(filters);
        gotoPage(DEFAULT_PAGE);
    };

    const handleRemoveFilter = (filterKey) => {
        // setSelectedFilters({...prevState, pageIndex : 0});
        setSelectedFilters((currentFilters) => {
            const updatedFilters = currentFilters.filter(([key, _]) => key !== filterKey);

            // Create a new query based on the updated filters
            const newQuery = updatedFilters.reduce((query, [key, value]) => {
                query[key] = value;
                return query;
            }, {});

            setQueryFilter(newQuery);
            gotoPage(DEFAULT_PAGE);

            return updatedFilters;
        });
    };

    const refreshEmployees = async (query = {}) => {
        try {
            // setQueryFilter(query);
            query.page = pageIndex + 1;
            query.resultsPerPage = pageSize;
            const result = await EmployeesApi.findEmployees(query);
            const employees = result.users;
            const totalPages = result.totalPages;

            if (!Array.isArray(employees)){
                throw new Error('bad response');
            }

            for (let employee of employees) {
                employee.fullName = employee.fullName || `${employee.firstName} ${employee.lastName}`;
                employee.missingInformationStatus = !!employee.informationStatus?.length;
                employee.companyName = companiesIdCache[employee.companyId.toString()];
            }

            setData(employees);
            setUsersFull(employees);
            setPageCount(totalPages);
        } catch(err){
            console.error(err);
        }
    }



    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    const {isOpen, onOpen, onClose} = useDisclosure()

    const {
        isOpen: isFilterUsersOpen,
        onOpen: onFilterUsersOpen,
        onClose: onFilterUsersClose
    } = useDisclosure()

    const {
        isOpen: isNewEmployeeOpen,
        onOpen: onNewEmployeeOpen,
        onClose: onNewEmployeeClose
    } = useDisclosure()

    const {
        isOpen: isEditEmployeeOpen,
        onOpen: onEditEmployeeOpen,
        onClose: onEditEmployeeClose
    } = useDisclosure()

    const {
        isOpen: isDeleteUserOpen,
        onOpen: onDeleteUserOpen,
        onClose: onDeleteUserClose
    } = useDisclosure()

    const {
        isOpen: isNewEmployeesFileOpen,
        onOpen: onNewEmployeesFileOpen,
        onClose: onNewEmployeesFileClose
    } = useDisclosure()

    const cancelRef = React.useRef()

    const handleEditClick = (cell) => {
        let index = cell.row.index
        setSelectedUser(usersFull[index])
        onEditEmployeeOpen()
    }
    const handleFilterButtonClick = () => {
        onFilterUsersOpen()
    }

    const handleDeleteClick = (cell) => {
        let index = cell.row.index
        setSelectedUser(usersFull[index])
        onDeleteUserOpen()
    }

    const handleDeleteConfirm = () => {
        EmployeesApi.delete(selectedUser.id).then(async res => {
            await refreshEmployees(queryFilter);
            setSelectedUser(null)
            onDeleteUserClose()
        })
            .catch((error) => {
                if (error.response) {
                }
                setError("שגיאה")
            });

    }


    const citiesData = useMemo(() => citiesB, [citiesB]);

    const onSubmit = (data) => {
        // build json array of employees from react-spreadsheet-import
        const validData = data["validData"];

        let employees = [];
        validData.forEach(element => {
            let employee = {};
            if (element["employer_id"]) {
                employee["employerId"] = Number(element["employer_id"])
            }
            if (element["first_name"]) {
                employee["firstName"] = element["first_name"]
            }
            if (element["last_name"]) {
                employee["lastName"] = element["last_name"]
            }
            if (element["phone_number"]) {
                employee["phoneNumber"] = element["phone_number"]
            }
            employee["details"] = {}
            if (element["id"]) {
                employee["details"]["govId"] = element["id"]
            }

            if (element["job_title"]) {
                employee["details"]["jobTitle"] = element["job_title"];
            }

            if (element["passport_id"]) {
                employee["details"]["passportId"] = element["passport_id"]
            }
            if (element["employee_id"]) {
                employee["details"]["internalId"] = element["employee_id"]
            }
            if (element["integration_id"]) {
                employee["details"]["integrationId"] = element["integration_id"]
            }

            if (element["gender"]) {
                if (element["gender"] === "ז") {
                    employee["details"]["gender"] = "male"
                } else if (element["gender"] === "נ") {
                    employee["details"]["gender"] = "female"
                }
            }

            if (element["start_date"]) {
                employee["details"]["startDate"] = element["start_date"]
            }

            if (element["city_name"]) {
                const cityName = element["city_name"];
                const selectedCity = citiesData.find(i => i.name === cityName);
                if (selectedCity != null && selectedCity.id != null) {
                    employee["details"]["cityId"] = selectedCity.id
                }
            }

            if (element["dob_date"]) {
                employee["details"]["dob"] = element["dob_date"]
            }

            employee["details"]["salaryDetails"] = {}

            if (element["salary_per_hour"]) {
                employee["details"]["salaryDetails"]["hourlyGrossRate"] = element["salary_per_hour"]
            }

            if (element["salary_per_month"]) {
                employee["details"]["salaryDetails"]["monthlyGrossRate"] = element["salary_per_month"]
            }


            employee["details"]["bankAccountDetails"] = {}
            if (element["bank_id"]) {
                employee["details"]["bankAccountDetails"]["bankId"] = element["bank_id"]
            }
            if (element["bank_branch_id"]) {
                employee["details"]["bankAccountDetails"]["branchId"] = element["bank_branch_id"]
            }
            if (element["bank_account_id"]) {
                employee["details"]["bankAccountDetails"]["accountId"] = element["bank_account_id"]
            }
            if (element['bank_account_details']) {
                const bankAccountDetails = element['bank_account_details']
                const [bankId, branchId, accountId] = bankAccountDetails.split('/');
                if (bankId) {
                    employee["details"]["bankAccountDetails"]["bankId"] = bankId
                }
                if (branchId) {
                    employee["details"]["bankAccountDetails"]["branchId"] = branchId
                }
                if (accountId) {
                    employee["details"]["bankAccountDetails"]["accountId"] = accountId
                }
            }
            employees.push(employee)
        });


        EmployeesApi.addEmployees(employees).then(async res => {
            await refreshEmployees();
        }).catch((error) => {
            if (error.response) {
            }
        });
    }


    const handleExportButton = (data) => {
        generateEmployeesFile(usersFull)
    }
    const modalsCallBack = ()=>{
        refreshEmployees(queryFilter)
    }

    return (
        <>
            <Card
                direction='column'
                w='100%'
                px='0px'
                overflowX={{sm: "scroll", lg: "hidden"}}>
                <Flex px='25px' mb='20px' align='center'>
                    <Button
                        backgroundColor='rgb(0, 133, 230)'
                        textColor='white'
                        marginRight='30px'
                        onClick={onNewEmployeeOpen}>הוספת עובד</Button>

                    <Button
                        marginRight='30px'
                        backgroundColor='rgb(0, 133, 230)'
                        textColor='white'
                        onClick={onNewEmployeesFileOpen}>טעינת קובץ עובדים</Button>

                    <AddNewEmployeesFileModal isOpen={isNewEmployeesFileOpen} onClose={onNewEmployeesFileClose}/>

                    <AddNewUserModal callBack={modalsCallBack} isOpen={isNewEmployeeOpen}
                                     onClose={onNewEmployeeClose}/>
                    <EditUserModal employee={selectedUser} callBack={modalsCallBack} isOpen={isEditEmployeeOpen}
                                   onClose={onEditEmployeeClose}/>

                    <Flex px='25px' mb='20px' align='center'>
                        <UserFilterModal
                            isOpen={isFilterModalOpen}
                            onClose={() => setFilterModalOpen(false)}
                            includeCompanyDropdown={true}
                            includeStatusDropdown={true}
                            onFilterApply={handleFilterApply}
                        />
                    </Flex>
                    <AlertDialog
                        isOpen={isDeleteUserOpen}
                        onClose={onDeleteUserClose} isCentered>
                        <AlertDialogOverlay>
                            <AlertDialogContent>
                                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                    מחיקת עובד
                                </AlertDialogHeader>

                                <AlertDialogBody>
                                    האם ברצונך למחוק את {selectedUser ? selectedUser.fullName : ""}?
                                </AlertDialogBody>

                                <AlertDialogFooter>
                                    <Button ref={cancelRef} onClick={onDeleteUserClose}>
                                        ביטול
                                    </Button>
                                    <Button colorScheme='red' onClick={handleDeleteConfirm} ml={3}>
                                        מחיקה
                                    </Button>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialogOverlay>
                    </AlertDialog>

                    <Button mr={'50px'} backgroundColor='#83e76f' onClick={onOpen} p="8px" borderRadius="8px">
                        יבוא קובץ עובדים
                    </Button>

                    <Button mr={'50px'} backgroundColor='brand' onClick={handleExportButton} p="8px" borderRadius="8px">
                        ייצוא קובץ עובדים
                    </Button>

                    <ReactSpreadsheetImport isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} fields={fields}
                                            translations={translations}/>
                </Flex>

                <Flex mr={'50px'} justify='space-between' mb='20px' align='center'>
                    <Button backgroundColor='#0085E6' textColor='white' onClick={() => setFilterModalOpen(true)}>
                        חיפוש
                    </Button>                </Flex>




                <SelectedFiltersView
                    selectedFilters={selectedFilters}
                    handleRemoveFilter={handleRemoveFilter}
                    includeCompanyName={true}
                />
                    <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
                    <Thead>
                        {headerGroups.map((headerGroup, index) => (
                            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <Th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        pe='10px'
                                        key={index}
                                        borderColor={borderColor}>
                                        <Flex
                                            justify='space-between'
                                            align='center'
                                            fontSize={{sm: "10px", lg: "12px"}}
                                            color='gray.400'>
                                            {column.render("Header")}
                                        </Flex>
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <Tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, index) => {
                                        let data = "";
                                        if (cell.column.tag === TagNone) {
                                            return null;
                                        }
                                        if (cell.column.tag === TagEmployerId ||
                                            cell.column.tag === TagId ||
                                            cell.column.tag === TagLastName ||
                                            cell.column.tag === TagFirstName ||
                                            cell.column.tag === TagJob ||
                                            cell.column.tag === TagPhoneNumber ||
                                            cell.column.tag === TagEmployerName) {
                                            data = (
                                                <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                    {cell.value}
                                                </Text>
                                            );
                                        } else if (cell.column.tag === TagStatus) {
                                            data = (
                                                <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                    {Users.statusText(cell.value)}
                                                </Text>
                                            );
                                        } else if (cell.column.tag === TagDob) {
                                            data = (
                                                <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                    {cell.value}
                                                </Text>
                                            );
                                        } else if (cell.column.tag === TagActions) {
                                            data = (
                                                <Flex align='center'>
                                                    <Button onClick={() => handleEditClick(cell)} marginLeft='20px'
                                                            textColor='black' backgroundColor='green.200'
                                                            height='35px'>עריכה</Button>
                                                    <Button onClick={() => handleDeleteClick(cell)} textColor='black'
                                                            backgroundColor='red.200' height='35px'>מחיקה</Button>

                                                    {cell.value &&
                                                        <Flex align='center' marginRight={'15px'}>
                                                            <Icon
                                                                w='24px'
                                                                h='24px'
                                                                me='5px'
                                                                color={"orange.200"}
                                                                as={MdOutlineError}/>
                                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                                חסרים פרטים
                                                            </Text>
                                                        </Flex>
                                                    }
                                                </Flex>
                                            );
                                        }
                                        return (
                                            <Td
                                                {...cell.getCellProps()}
                                                key={index}
                                                fontSize={{sm: "14px"}}
                                                minW={{sm: "150px", md: "200px", lg: "auto"}}
                                                borderColor='transparent'>
                                                {data}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </Card>
            <Flex justifyContent="space-between" m={30} alignItems="center">
                <Flex alignItems="center">

                    <Text flexShrink="0">
                        עמוד{" "}
                        <Text fontWeight="bold" as="span">
                            {pageIndex + 1}
                        </Text>{" "}
                        מתוך{" "}
                        <Text fontWeight="bold" as="span">
                            {pageOptions.length}
                        </Text>
                    </Text>

                    <Select
                        backgroundColor={'white'}
                        mr={'20px'}
                        w={32}
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                        textAlign="left"
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                הצג {pageSize}
                            </option>
                        ))}
                    </Select>
                </Flex>
                <Flex>
                    <Tooltip label="עמוד קודם">
                        <IconButton
                            ml={3}
                            onClick={previousPage}
                            isDisabled={!canPreviousPage}
                            icon={<ChevronRightIcon h={6} w={6}/>}
                        />
                    </Tooltip>
                    <Tooltip label="עמוד הבא">
                        <IconButton
                            onClick={nextPage}
                            isDisabled={!canNextPage}
                            icon={<ChevronLeftIcon h={6} w={6}/>}
                        />
                    </Tooltip>
                </Flex>
            </Flex>
        </>
    );
}
