import { Box } from "@chakra-ui/react";
import MessageTable from "./components/MessageTable";

export default function Messaging() {
    // Chakra Color Mode
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <MessageTable />
        </Box>
    );
}