import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    FormControl,
    FormLabel,
    Input,
    ModalFooter,
    useDisclosure,
    useModalContext, Text, Box,Image,
} from "@chakra-ui/react";
  import CompaniesApi from "../../../../api/companies"
  import { useEffect, useState } from "react";
import {useDropzone} from "react-dropzone";

export default function EditCompanyModal(props) {

    let { isOpen, onClose, company, callBack, ...rest } = props;
    const [error, setError] = useState(undefined);

    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [bankId, setBankId] = useState("");
    const [branchId, setBranchId] = useState("");
    const [accountId, setAccountId] = useState("");
    const [transferLimit, setTransferLimit] = useState("");
    const [companyLogo, setCompanyLogo] = useState(null);


    useEffect(() => {
        if (isOpen) {
            if (company) {

                setName(company.name || "");
                setAddress(company.address || "");
                setBankId(company.bankAccountDetails?.bankId || "");
                setAccountId(company.bankAccountDetails?.accountId || "");


                setTransferLimit(company.employeesMonthlyTransferLimit);
                setCompanyLogo(company.logo || null);
            }

        } else {
            setName("");
            setAddress("");
            setBankId("");
            setBranchId("");
            setAccountId("");
            setTransferLimit("");
            setCompanyLogo(null);
        }
    }, [isOpen]);

    const onDrop = (acceptedFiles) => {
        // Assuming only the first file is of interest
        const file = acceptedFiles[0];
        if (file) {
            // Create a preview URL for display
            setCompanyLogo(Object.assign(file, {
                preview: URL.createObjectURL(file)
            }));
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png', // Specify allowed file types
        maxFiles: 1, // Limit the number of files
    });

    const updateCompany = () => {
        // if(!name) {
        //     setError("אנא הכנס שם חברה")
        // }
        // if(!address) {
        //     setError("אנא הכנס כתובת")
        // }

          setError(undefined)
          let companyJson = {
            "id": company.id
          }
          if(name) {
            companyJson["name"] = name;
          }
          if(address) {
            companyJson["address"] = address;
          }

          if(transferLimit){
              companyJson["transferLimit"] = Number(transferLimit);
          }else{
              companyJson["transferLimit"] = transferLimit;
          }

          // if one of them changed
          if(bankId || branchId || accountId) {
            // copy existing bank account details and update changed properties
            let bankAccountDetails = {}
            if(company.bankAccountDetails) {
              bankAccountDetails = company.bankAccountDetails;
            }
              if(bankId) {
                bankAccountDetails["bankId"] = bankId;
              }
              if(branchId) {
                bankAccountDetails["branchId"] = branchId;
              }
              if(accountId) {
                bankAccountDetails["accountId"] = accountId;
              }
            companyJson["bankAccountDetails"] = bankAccountDetails;
          }
        if(companyLogo && companyLogo instanceof File) {
            companyJson["companyLogo"] = companyLogo;
        }

          CompaniesApi.update(companyJson).then(res => {
              callBack()
              onClose()
            })
            .catch(function (error) {
              if (error.response) {
              }
              console.log("error", error)
              setError("שגיאה")
            });

    }
    useEffect(() => () => {
        if (companyLogo && companyLogo.preview) {
            URL.revokeObjectURL(companyLogo.preview);
        }
    }, [companyLogo]);



    return (
        <Modal isOpen={isOpen}
         onClose={onClose}
         isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>עריכת חברה</ModalHeader>
          <ModalBody>
          <h4 style={{
                fontSize: "1.2em",
                color: "red",
                textAlign: "center",
                fontWeight: 400,
                transition: ".2s all"}}>
              {error}
            </h4>
          <FormLabel fontWeight='bold' fontSize='xl'>פרטי חברה</FormLabel>
          <FormControl mt={4}>
              <Input defaultValue={company && company.name ? company.name : ""} onChange={(event) => {
                setName(event.target.value);
                }} />
            </FormControl>
            <FormControl mt={4}>
              <Input defaultValue={company && company.address ? company.address : ""} placeholder='כתובת' onChange={(event) => {
                setAddress(event.target.value);
                }} />
            </FormControl>
            <br/>
            <FormLabel fontWeight='bold' fontSize='xl'>פרטי חשבון בנק</FormLabel>
            <FormControl mt={4}>
              <Input defaultValue={company && company.bankAccountDetails &&  company.bankAccountDetails.bankId ? company.bankAccountDetails.bankId : ""} placeholder='מספר בנק' onChange={(event) => {
                setBankId(event.target.value);
                }} />
            </FormControl>
            <FormControl mt={4}>
              <Input defaultValue={company && company.bankAccountDetails &&  company.bankAccountDetails.branchId ? company.bankAccountDetails.branchId : ""} placeholder='מספר סניף' onChange={(event) => {
                setBranchId(event.target.value);
                }} />
            </FormControl>
            <FormControl mt={4}>
              <Input defaultValue={company && company.bankAccountDetails &&  company.bankAccountDetails.accountId ? company.bankAccountDetails.accountId : ""} placeholder='מספק חשבון' onChange={(event) => {
                setAccountId(event.target.value);
                }} />
            </FormControl>
              <br/>
              <FormControl mt={4}>
              <FormLabel fontWeight='bold' fontSize='xl'>מסגרת חודשית</FormLabel>
                  <Input defaultValue={transferLimit} onInput={(event) => {
                      setTransferLimit(event.target.value === "" ? null : event.target.value);
                  }} />
              </FormControl>
              <Box {...getRootProps()} p={5} border="2px dashed gray" borderRadius="md">
                  <input {...getInputProps()} />
                  <Text>גרור לוגו לכאן או לחץ ובחר קובץ נבחר</Text>
                  {companyLogo && companyLogo.preview && (
                      <Box mt={3}>
                          <Image src={companyLogo.preview} alt="Preview" maxH="100px" />
                      </Box>
                  )}
              </Box>


          </ModalBody>
          <ModalFooter>
            <Button onClick={updateCompany}>שמור</Button>
            <Button onClick={onClose}>סגור</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}
