import {API} from "./env";
import {axiosInstance} from "./httpClient";

export default class IntegrationsApi {
    static uploadTaFile = (file, providerId, companyId) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let formData = new FormData();
        formData.append("file", file)
        formData.append("providerId", providerId)
        formData.append("companyId", companyId)
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + accessToken
            }
        };
        return axiosInstance.post(`${API}/integrations/ta/upload/`, formData, config);
    }

    static getIntegrationsResults = () => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.get(`${API}/integrations/results`, config);
    };

    static getIntegrationsErrors = (integrationResultId, companyId) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        if(integrationResultId) {
            return axiosInstance.get(`${API}/integrations/errors?integrationResultId=`+integrationResultId, config);
        } else if(companyId) {
            return axiosInstance.get(`${API}/integrations/errors?companyId=`+companyId, config);
        } else {
            return axiosInstance.get(`${API}/integrations/errors`, config);
        }
    };

    static triggerIntegrationAction = (integrationActionId) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        if(integrationActionId) {
            return axiosInstance.get(`${API}/integrations/actions?integrationActionId=`+integrationActionId, config);
        }
    };

    static triggerAttendanceIntegrationActionByCompanyId = (companyId) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        if(companyId) {
            return axiosInstance.get(`${API}/integrations/actions?companyId=`+companyId, config);
        }
    };

    static setIntegrationErrorState = (errorId, state) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
            errorId: String(errorId),
            state: state
        };
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/integrations/errors`, JSON.stringify(body), config);
    }

    static downloadIntegrationFile = (integrationActionResultId) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        const config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        if(integrationActionResultId) {
            return axiosInstance.get(`${API}/integrations/actions/download?integrationActionResultId=`+integrationActionResultId, config);
        }
    }
}
