import React, {useCallback} from 'react';
import { Box, Text, VStack, List, ListItem } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";

const FileDropzone = ({ onFileLoaded }) => {
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            onFileLoaded(file);
        })
    }, [])

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1,
    });

    return (
        <Box borderWidth="1px" borderRadius="md" padding="6" borderColor="gray.300" background="gray.50">
            <Box {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <Text align="center" color="gray.500">Drag and drop some files here, or click to select files</Text>
            </Box>
            <VStack align="start" spacing={2} marginTop={4}>
                <Text fontWeight="bold">Files:</Text>
                <List spacing={1}>
                    {acceptedFiles.map((file, index) => (
                        <ListItem key={index}>{file.name}</ListItem>
                    ))}
                </List>
            </VStack>
        </Box>
    );
}

export default FileDropzone;