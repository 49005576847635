// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import RegistrationsTable from "./components/RegistrationsTable";

export default function DataTables() {
  // Chakra Color Mode

    return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 2, md: 3 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <RegistrationsTable />
      </SimpleGrid>
    </Box>
  );
}
