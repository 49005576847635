import {
    Button, Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
} from "@chakra-ui/react";
import React, {useEffect} from "react";
import ReportsApi from "../../../../api/reports";

export const DocumentTypeTransferFeeInvoice = 1;
export const DocumentTypeDrishatTashlum = 2;
export const DocumentTypeReceipt = 3;
export const DocumentTypeCompanySubscriptionFee = 4;
export const DocumentTypeMasavFile = 5;
export const DocumentTypeBankActivityReference = 6;

export default function AdminSumitDocumentsFinderModal(props) {

    let { isOpen, onClose, onSelect, report } = props;

    const [folders, setFolders] = React.useState([])
    const [selectedFolder, setSelectedFolder] = React.useState('');

    const [documents, setDocuments] = React.useState([])
    const [selectedDocument, setSelectedDocument] = React.useState('');
    const [selectedDocumentType, setSelectedDocumentType] = React.useState(DocumentTypeTransferFeeInvoice);

    useEffect(() => {
        if(isOpen) {
            setSelectedDocument('');
            setFolders([]);
            setSelectedFolder('');
            setDocuments([]);
            setSelectedDocumentType(DocumentTypeTransferFeeInvoice);
            ReportsApi.getFolders().then(res => {
                setSelectedFolder(res.data[0].ID);
                setFolders(res.data)
            }).catch(e => {
                console.log(e)
            });
        }
    }, [isOpen]);

    useEffect(() => {
        if(isOpen)
        {
            ReportsApi.getFolderItems(selectedFolder).then(res => {
                if(selectedFolder == 169548551) {
                    setSelectedDocumentType(DocumentTypeDrishatTashlum)
                } else if(selectedFolder == 169548559) {
                    setSelectedDocumentType(DocumentTypeTransferFeeInvoice)
                } else if(selectedFolder == 169548554) {
                    setSelectedDocumentType(DocumentTypeReceipt)
                } else if(selectedFolder == 169548528) {
                    setSelectedDocumentType(DocumentTypeBankActivityReference)
                } else if(selectedFolder == 169549010) {
                    setSelectedDocumentType(DocumentTypeMasavFile)
                }
                setSelectedDocument(res.data[0]?.ID || '');
                setDocuments(res.data)
            }).catch(e => {
                console.log(e)
            });
        }
    }, [selectedFolder]);

    const AccountingDefinitionEnumToString = (enumValue) => {
        switch (enumValue) {
            case 28:
                return  "דרישת תשלום";
            case 11:
                return "קבלה";
            case 7:
                return "חשבונית מס";
        }
    }

    const prettifyDocumentName = (document) => {
        try {
            if(document['Billing_File'] != undefined) {
                return "קובץ מס״ב " + "₪" + document.Billing_Total[0];
            }
            if(document['Customers_FullName'] != undefined) {
                return document.Customers_FullName[0];
            }
            if(document.Accounting_Number[0]) {
                return AccountingDefinitionEnumToString(document.Accounting_DefinitionEnum[0]) + " - " + document.Accounting_Number[0];
            } else {
                return "טיוטא"
            }
        } catch (error) {
        }
    }
    const handleSelectDocumentTypeChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedFolder(selectedValue)
    };

    const handleSelectDocumentChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedDocument(selectedValue)
    };

    const handleSelect = () => {
        onSelect(selectedDocument, selectedDocumentType);
    };

    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               isCentered
               closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>מסמכים ב-Sumit</ModalHeader>
                <ModalBody>

                    <SimpleGrid
                        marginTop='30px'
                        columns={{sm: 2}}
                        gap='20px'
                        mb='0px'>

                        <select value={selectedFolder} onChange={handleSelectDocumentTypeChange}>
                            <option value='' disabled hidden>סוג מסמך:</option>
                            {folders && folders.map((folder) => (
                                <option key={folder.ID} value={folder.ID}>
                                    {folder.Name}
                                </option>
                            ))}
                        </select>

                        {selectedDocumentType == DocumentTypeBankActivityReference ?
                            <Input placeholder='מספר תנועה' onChange={(event) => {
                                setSelectedDocument(event.target.value);
                            }} />
                            :
                            <select value={selectedDocument} onChange={handleSelectDocumentChange}>
                                <option value='' disabled hidden>בחר מסמך:</option>
                                {documents && documents.map((document) => (
                                    <option key={document.ID} value={document.ID}>
                                        {prettifyDocumentName(document)}
                                    </option>
                                ))}
                            </select>
                        }

                    </SimpleGrid>

                    <br/>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleSelect}>בחר</Button>
                    <Button onClick={onClose}>סגור</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
