// Chakra imports
import {Box, Flex, Icon, Text, Image, Heading, useColorModeValue} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";

// Import the image
import Logo from '../../assets/img/auth/Logo.png'; // Adjust the path if necessary
import classes from './style.module.css';

function AuthIllustration(props) {
    const { children, illustrationBackground } = props;
    // Chakra color mode
    document.documentElement.dir = "rtl";
    const textColor = useColorModeValue("navy.700", "white");

    return (
        <Flex position='relative' h='max-content'>
            <Flex
                h={{
                    sm: "initial",
                    md: "unset",
                    lg: "100vh",
                    xl: "97vh",
                }}
                w='100%'
                maxW={{ md: "66%", lg: "1313px" }}
                mx='auto'
                pt={{ sm: "50px", md: "0px" }}
                px={{ lg: "30px", xl: "0px" }}
                ps={{ xl: "70px" }}
                justifyContent='start'
                direction='column'>



                {children}
                <Box
                    // backgroundColor='blue.400'
                    display={{ base: "none", lg: "block" }}
                    h='100%'
                    minH='100vh'
                    w={{ lg: "50vw", "2xl": "44vw" }}
                    position='absolute'
                    left='0px'>
                    <Flex
                        borderBottom="1px solid #12653D4D"
                        className={classes.container}
                        justify='center'
                        align='end'
                        w='100%'
                        h='100%'
                        bgSize='cover'
                        bgPosition='50%'
                        position='absolute'
                        borderBottomRightRadius={{ lg: "120px", xl: "200px" }}>

                        <Flex alignSelf="center" justifySelf="center" justifyContent="center" alignItems="center" direction="column" mb="164px">
                            <Image src={Logo} alt="Logo"
                                   w='44%'
                                   h='8%'
                            />

                            <Text
                                className={classes.text}
                                color='#252F35' fontSize={{base:'48px', xl:'64px'}} lineHeight='64px'
                                marginTop='32px'>
                                רווחה פיננסית אמיתית.
                            </Text>
                        </Flex>

                    </Flex>
                </Box>
            </Flex>
        </Flex>
    );
}

AuthIllustration.propTypes = {
    illustrationBackground: PropTypes.string,
    image: PropTypes.any,
};

export default AuthIllustration;
