import {Box} from "@chakra-ui/react";
import React from "react";
import AdminReportsTable from "./components/AdminReportsTable";

export default function AdminReports() {
    // Chakra Color Mode
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <AdminReportsTable />
        </Box>
    );
}