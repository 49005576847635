import React from 'react';
import Select from 'react-select';


export const AutoCompleteSelector = ({ state, stateSetter, data }) => {

    const cityOptions = data.map(city => ({
        value: city.id,
        label: city.name,
    }));

    const handleSelect = selectedOption => {
        if (selectedOption) {
            stateSetter(selectedOption.value);
        }
    };
    const customStyles = {
        control: (base, state) => ({
            ...base,
            direction: 'rtl',
        }),
        menu: base => ({
            ...base,
            direction: 'rtl',
        }),
    };

    return (
        <Select
            mt={4}
            options={cityOptions}
            onChange={handleSelect}
            isSearchable
            // placeholder="בחר או הקלד עיר מגורים"
            placeholder={(state != null) ? data.find(i => i.id === state).name : "בחר עיר" }
            styles={customStyles}
        />
    );
};
