import React, {useEffect, useState} from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    useToast,
} from '@chakra-ui/react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import FormikReactSelect from "./FormikReactSelect";
import {getCompanies} from "../../../../functions/companiesCache";

const UserFilterModal = ({ isOpen, onClose, onFilterApply, includeCompanyDropdown, includeStatusDropdown }) => {
    const initialValues = {
        firstName: '',
        lastName: '',
        phone: '',
        companyId: '',
        status: '',
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().max(50, 'Must be 50 characters or less'),
        lastName: Yup.string().max(50, 'Must be 50 characters or less'),
        phone: Yup.string().matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'Invalid phone number'),
    });

    const statusOptions = [
        { value: null, label: "הכל" },
        { value: "100", label: "לא פעיל" },
        { value: "200", label: "פעיל" },
        { value: "300", label: "חסום" },
        { value: "400", label: "עזב" },
    ];

    const [companiesIdCache, setCompaniesIdCache] = useState({});
    const [companiesOptions, setCompaniesOptions] = useState([]);

    useEffect(() => {
        if(includeCompanyDropdown) {
            (async () => {
                const cache = await getCompanies();
                setCompaniesIdCache(cache);
            })();
        }
    }, [isOpen]);

    useEffect(() => {
        setCompaniesOptions([
            { label: "הכל", value: "All" },
            ...Object.keys(companiesIdCache).map(key => ({
                label: companiesIdCache[key],
                value: key,
            }))
        ]);
    }, [companiesIdCache]);



    const toast = useToast();

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader pt="8">חיפוש עובד</ModalHeader>
                <ModalCloseButton />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        onFilterApply(values);
                        actions.setSubmitting(false);
                        onClose();
                        toast({
                            position: "top",
                            title: "סינון בוצע בהצלחה",
                            description: "בוצע סינון על פי הקריטריונים שנבחרו",
                            status: "success",
                            duration: 5000,
                            isClosable: true,
                        });
                    }}
                >
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <ModalBody pb={6}>
                                {includeCompanyDropdown && (
                                    <FormControl>
                                        <FormLabel>חברה</FormLabel>
                                        <FormikReactSelect
                                            name="companyId"
                                            options={companiesOptions}
                                            placeholder={"בחר חברה"}
                                        />
                                    </FormControl>
                                )}
                                <FormControl>
                                    <FormLabel>שם פרטי</FormLabel>
                                    <Field as={Input} name="firstName" />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>שם משפחה</FormLabel>
                                    <Field as={Input} name="lastName" />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>מספר טלפון</FormLabel>
                                    <Field as={Input} name="phoneNumber" />
                                </FormControl>
                                {includeStatusDropdown && (
                                    <FormControl>
                                        <FormLabel>סטטוס</FormLabel>
                                        <FormikReactSelect name="status" options={statusOptions} placeholder={"בחר סטטוס עובד"} />
                                    </FormControl>
                                )}
                            </ModalBody>

                            <ModalFooter display="flex" flexDirection="row">
                                <Button variant={'brand'} mr={3} type="submit">
                                    סינון
                                </Button>
                                <Button mr={3} onClick={onClose}>ביטול</Button>
                            </ModalFooter>
                        </form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default UserFilterModal;
