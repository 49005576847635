import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    SimpleGrid,
    useToast, Select, Flex,
} from "@chakra-ui/react";

import ApplicationsApi from "../../../../api/applications";
import React from "react";
export default function EditApplicationModal(props) {

    let { isOpen, onClose, application } = props;
    const toast = useToast()
    const [applicationStatus, setApplicationStatus] = React.useState("")

    const handleStatusChange = (event) => {
        const value = event.target.value;
        setApplicationStatus(value)
    }

    const handeSaveButtonClick = () => {

        ApplicationsApi.updateStatus(applicationStatus, [application]).then(res => {

            toast({
                title: `סטטוס עודכן בהצלחה`,
                position: 'top',
                status: 'success',
                isClosable: false,
            })

            onClose()

        }).catch(e => {
            toast({
                title: `שגיאה בעדכון סטטוס`,
                position: 'top',
                status: 'error',
                isClosable: false,
            })
        })
    }

    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               isCentered
               closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>פרטי בקשת העברה</ModalHeader>
                <ModalBody>

                    <SimpleGrid
                        marginTop='30px'
                        columns={{sm: 1}}
                        gap='20px'
                        mb='0px'>


                        <Select marginRight='30px' width='190px' pr='15px' placeholder='בחר סטטוס העברה' onChange={handleStatusChange}>
                            <option value='0' selected={ application && application.status === 0}>בוטל</option>
                            <option value='100' selected={application && application.status === 5}>נדחה</option>
                            <option value='300' selected={application && application.status === 15}>ממתין להעברה</option>
                            <option value='500' selected={application && application.status === 20}>הועבר</option>
                            <option value='700' selected={application && application.status === 25}>הושלם</option>
                        </Select>

                        <Flex flexDirection={'column'}>
                            {(application && application.promotion) &&
                                <div  style={{
                                    marginTop:20,
                                    overflow:"scroll",
                                    outline:"0",
                                    direction: 'ltr',
                                    border: "1px solid #FFFFFF50"}} >

                                    <h2 style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '10px'}}>Promotions</h2>

                                    {application.promotion.map((city) => (
                                        <Flex
                                            flexDirection={'column'}
                                            textAlign={'left'}
                                            marginBottom={'20px'}
                                            style={{
                                                direction: 'ltr',
                                                border: "1px solid gray"}} >
                                            {application && Object.entries(city).map(([key, value]) => (
                                                    <React.Fragment key={key}>
                                                        <strong>{key}:</strong> {value}
                                                        <br />
                                                    </React.Fragment>
                                                ))
                                            }
                                        </Flex>
                                    ))}
                                </div>
                            }

                        </Flex>

                    </SimpleGrid>

                    <br/>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={handeSaveButtonClick}>שמור</Button>
                    <Button onClick={onClose}>סגור</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
