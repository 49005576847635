import MockApi from "./mockApi";
import {API} from "./env";
import {axiosInstance} from "./httpClient";


export default class CompaniesApi {

    static getAll = () => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        var config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            };

        return axiosInstance.get(`${API}/employers`, config);
    };

    static getCompanyByGovId = (govId) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        var config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            };


        return axiosInstance.get(`${API}/companies/govInfo?govId=`+govId.toString(), config);
    };

    static createEmployer = (companyJson) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;

        const {
            name,
            address,
            govId,
            employeesMonthlyTransferLimit,
            employeesCount,
            isActive,
            companyLogo,
            companyExternalId,
            companyDisplayedName,
        } = companyJson;

        // Use FormData to accommodate the file upload
        let formData = new FormData();
        formData.append('name', name);
        formData.append('address', address);
        formData.append('govId', govId);
        formData.append('employeesMonthlyTransferLimit', employeesMonthlyTransferLimit);
        formData.append('employeesCount', employeesCount);
        formData.append('isActive', isActive);
        formData.append('companyLogo', companyLogo);
        formData.append('companyExternalId', companyExternalId);
        formData.append('companyDisplayedName', companyDisplayedName);


        let config = {
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data', // Not needed, axios sets it correctly based on formData
                'Authorization': 'Bearer ' + accessToken,
            },
        };

        return axiosInstance.post(`${API}/employers`, formData, config);
    };





    static delete = (companyId) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;

        var body = JSON.stringify({
            "id": companyId
            });

        var config = {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            data: body
            };


        return axiosInstance.delete(`${API}/employers`, config);
    };

    static update = (companyJson) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;

        let formData = new FormData();

        // Prepare non-file JSON data
        const nonFileData = {};

        Object.keys(companyJson).forEach(key => {
            formData.append('companyLogo', companyJson[key]);
        });

        var config = {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + accessToken
                // Note: 'Content-Type': 'multipart/form-data' is automatically set when using FormData
            },
        };

        // Ensure API endpoint matches your server-side route
        return axiosInstance.post(`${API}/employers/update`, formData, config);
    };


    static getReports = (aDate) => {
        if(MockApi.IsActive) {
            return MockApi.GetCompanyReports();
        }


        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
            payPeriod: aDate
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/reports/get`, JSON.stringify(body), config);
    };

    static exportPayrollData = (aDate) => {
        if(MockApi.IsActive) {
            return MockApi.ExportPayrollData();
        }

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
            date: aDate
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/employer/payroll`, JSON.stringify(body), config);
    };

    static integrationsTaReport = (aDate, aCompanyId) => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let accessToken = userInfo.token;
        let body = {
            date: aDate,
            companyId: aCompanyId
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        return axiosInstance.post(`${API}/integrations/ta/report/`, JSON.stringify(body), config);
    };
}

