/* eslint-disable */
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  useDisclosure,
  Tooltip,
  IconButton,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper, Heading,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React, {useEffect, useMemo, useState} from "react";
import {
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import {
  TagId,
  TagFirstName,
  TagLastName,
  TagJob,
  TagStatus,
  TagPhoneNumber,
  TagActions,
  employeesColumns, TagBranch,
} from "views/company/employees/data/employeesData";

import EmployeesApi from "../../../../api/employees";
import EditEmployeeModal from "./EditEmployeeModal";
import AddNewEmployeeModal from "./AddNewEmployeeModal";
import {Users} from "../../../../api/user";
import AddNewEmployeesFileModal from "./AddNewEmployeesFileModal";
import {ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";
import SelectedFiltersView from "../../../admin/users/components/SelectedFiltersView";
import MockApi from "../../../../api/mockApi";
import UserFilterModal from "../../../admin/users/components/UserFilterModal";


const DEFAULT_PAGE = 0;

export default function EmployeesTable() {

  const columns = useMemo(() => employeesColumns, [employeesColumns]);

  const [data, setData] = React.useState([])
  const [selectedUser, setSelectedUser] = React.useState(null)
  const [pageCount, setPageCount] = React.useState(0);
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [queryFilter, setQueryFilter] = React.useState({});


  const handleFilterApply = (filters) => {
    setSelectedFilters(Object.entries(filters).filter(([_, value]) => value));
    setQueryFilter(filters);
    gotoPage(DEFAULT_PAGE);
  };

  const handleRemoveFilter = (filterKey) => {
    setSelectedFilters((currentFilters) => {
      const updatedFilters = currentFilters.filter(([key, _]) => key !== filterKey);

      // Create a new query based on the updated filters
      const newQuery = updatedFilters.reduce((query, [key, value]) => {
        query[key] = value;
        return query;
      }, {});

      setQueryFilter(newQuery);
      gotoPage(DEFAULT_PAGE);

      return updatedFilters;
    });
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const {
    isOpen: isEditEmployeeOpen,
    onOpen: onEditEmployeeOpen,
    onClose: onEditEmployeeClose
  } = useDisclosure()

  const {
    isOpen: isNewEmployeesFileOpen,
    onOpen: onNewEmployeesFileOpen,
    onClose: onNewEmployeesFileClose
  } = useDisclosure()

  const {
    isOpen: isNewEmployeeOpen,
    onOpen: onNewEmployeeOpen,
    onClose: onNewEmployeeClose
  } = useDisclosure()

  const {
    getTableProps,
    getTableBodyProps,
    pageOptions,
    gotoPage,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
      {
        columns,
        data,

        initialState: { pageIndex: 0, pageSize: 10 },
        pageCount,
        manualPagination: true,
        autoResetPage: false
      },
      useSortBy,
      usePagination
  )


  useEffect(() => {
    refreshEmployees(queryFilter);
  }, [pageSize, pageIndex, queryFilter]);

  const refreshEmployees = (query = {})=> {
    query.page = pageIndex + 1;
    query.resultsPerPage = pageSize;
    EmployeesApi.findEmployees(query).then(res => {
      // setUsersFull(res)
      const totalPages = res.totalPages;
      let jsonArray = []
      res.users.forEach(element => {
        if(!element.fullName) {
          element['fullName'] = element['firstName'] + ' ' + element['lastName']
        }
        jsonArray.push(element)
      });
      setData(jsonArray);
      setPageCount(totalPages);
    })
        .catch(e => {
        })
  }

  const handleEditClick = (cell) => {
    let index = cell.row.index
    setSelectedUser(data[index])
    onEditEmployeeOpen()
  }

  return (
      <>
        <Card
            direction='column'
            w='100%'
            px='0px'
            overflowX={{ sm: "scroll", lg: "hidden" }}>
          <Flex mx={'20px'} justify='space-between' mb='20px' align='center'>

            <Button variant={'brand'} onClick={() => setFilterModalOpen(true)}>
              חיפוש
            </Button>

            <Flex align='center' justify='space-between'>

              <Button
                  mx={'20px'}
                  marginRight='30px'
                  variant={'brand'}
                  onClick={onNewEmployeeOpen}>הוספת עובד</Button>


              <Button
                  variant={'brand'}
                  onClick={onNewEmployeesFileOpen}>טעינת קובץ עובדים</Button>

            </Flex>

            <AddNewEmployeesFileModal isOpen={isNewEmployeesFileOpen} onClose={onNewEmployeesFileClose} />

            <AddNewEmployeeModal callBack={()=>refreshEmployees(queryFilter)} isOpen={isNewEmployeeOpen} onClose={onNewEmployeeClose} />

            <EditEmployeeModal employee={selectedUser} callBack={()=>refreshEmployees(queryFilter)} isOpen={isEditEmployeeOpen} onClose={onEditEmployeeClose} />

          </Flex>

          <Flex px='25px' mb='20px' align='center'>
          <UserFilterModal
              isOpen={isFilterModalOpen}
              onClose={() => setFilterModalOpen(false)}
              onFilterApply={handleFilterApply}
          />
          </Flex>


          <SelectedFiltersView
              selectedFilters={selectedFilters}
              handleRemoveFilter={handleRemoveFilter}
              includeCompanyName={false}
          />
          {/*{filterApplied && <SelectedFiltersView handleRemoveFilter={handleResetFilterClicked} style={{marginRight: '0px'}} selectedFilters={selectedFilters}> </SelectedFiltersView>}*/}

          <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                  <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                        <Th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            pe='10px'
                            key={index}
                            borderColor={borderColor}>
                          <Flex
                              justify='space-between'
                              align='center'
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color='gray.400'>
                            {column.render("Header")}
                          </Flex>
                        </Th>
                    ))}
                  </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        let data = "";
                        if(cell.column.tag === TagId) {
                          data = (
                              <Text color={textColor} fontSize='sm' fontWeight='700'>
                                {cell.value}
                              </Text>
                          );
                        }
                        else if(cell.column.tag === TagStatus) {
                          data = (
                              <Text color={textColor} fontSize='sm' fontWeight='700'>
                                {Users.statusText(cell.value)}
                              </Text>
                          );
                        }
                        else if (cell.column.tag === TagFirstName ||
                            cell.column.tag === TagLastName ||
                            cell.column.tag === TagJob ||
                            cell.column.tag === TagPhoneNumber ||
                            cell.column.tag === TagBranch) {
                          data = (
                              <Text color={textColor} fontSize='sm' fontWeight='700'>
                                { (cell.value != null && cell.value.length > 0) ? cell.value : "----" }
                              </Text>
                          );
                        }
                        else if (cell.column.tag === TagActions) {
                          data = (
                              <Flex align='center'>
                                <Button variant={'lightBrand'} onClick={() => handleEditClick(cell)} marginLeft='20px' height='35px'>עריכה</Button>
                              </Flex>
                          );
                        }
                        return (
                            <Td
                                {...cell.getCellProps()}
                                key={index}
                                fontSize={{ sm: "14px" }}
                                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                borderColor='transparent'>
                              {data}
                            </Td>
                        );
                      })}
                    </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Card>

        {!MockApi.IsActive &&
            <Flex justifyContent="space-between" m={30} alignItems="center">
              <Flex alignItems="center">

                <Text flexShrink="0">
                  עמוד{" "}
                  <Text fontWeight="bold" as="span">
                    {pageIndex + 1}
                  </Text>{" "}
                  מתוך{" "}
                  <Text fontWeight="bold" as="span">
                    {pageOptions.length}
                  </Text>
                </Text>

                <Select
                    backgroundColor={'white'}
                    mr={'20px'}
                    w={32}
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                    textAlign="left"
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        הצג {pageSize}
                      </option>
                  ))}
                </Select>
              </Flex>
              <Flex>
                <Tooltip label="עמוד קודם">
                  <IconButton
                      ml={3}
                      onClick={previousPage}
                      isDisabled={!canPreviousPage}
                      icon={<ChevronRightIcon h={6} w={6}/>}
                  />
                </Tooltip>
                <Tooltip label="עמוד הבא">
                  <IconButton
                      onClick={nextPage}
                      isDisabled={!canNextPage}
                      icon={<ChevronLeftIcon h={6} w={6}/>}
                  />
                </Tooltip>
              </Flex>
            </Flex>
        }

      </>
  );
}
