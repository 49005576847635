import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    useToast,
    Text,
    Select,
    Textarea, Input
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import FormsApi from "../../../api/forms";

export default function AddNewFormModal(props) {
    let { isOpen, onClose, onNewFormCreated } = props;
    const [formTitle, setFormTitle] = useState('');
    const [formLocation, setFormLocation] = useState('');
    const [formContent, setFormContent] = useState('');
    const [formType, setFormType] = useState('text');

    useEffect(() => {
        if(isOpen) {
            setFormTitle('');
            setFormLocation('');
            setFormContent('');
        }
    }, [isOpen]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setFormContent(reader.result.split(',')[1]); // Set base64 part of the result
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const toast = useToast();

    const createForm = () => {

        if(formTitle === '') {
            toast({
                title: 'יש למלא שם לטופס',
                position: 'top',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if(formLocation === '') {
            toast({
                title: 'יש לבחור מיקום לטופס',
                position: 'top',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        let formData = {
            title: formTitle,
            content: formContent,
            location: formLocation,
            contentType: formType,
        };

        FormsApi.create(formData).then(() => {
            toast({
                title: 'טופס נוצר בהצלחה',
                position: 'top',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            onNewFormCreated?.();
            onClose();
        }).catch(error => {
            toast({
                title: 'שגיאה ביצירת טופס',
                position: 'top',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>יצירת טופס חדש</ModalHeader>
                <ModalBody>
                    <Text fontSize="md" mb="4">שם הטופס:</Text>
                    <Input
                        placeholder="הכנס שם לטופס"
                        value={formTitle}
                        onChange={(e) => setFormTitle(e.target.value)}
                        mb="4"
                    />
                    <Select
                        placeholder="סוג טופס"
                        mt={'4'}
                        onChange={(e) => setFormType(e.target.value)}
                        sx={{
                            direction: 'rtl',
                            paddingRight: '2rem', // Adjust this value as needed to prevent overlap
                            textAlign: 'right',
                        }}
                    >
                        <option value="text">אישור נוסח</option>
                        <option value="pdf">חתימה pdf</option>
                    </Select>

                    {/* Conditionally render based on form type */}
                    {formType === 'text' ? (
                        <Textarea
                            placeholder="הכנס טקסט לטופס"
                            mt="4"
                            value={formContent}
                            onChange={(e) => setFormContent(e.target.value)}
                        />
                    ) : (
                        <Input
                            type="file"
                            accept="application/pdf"
                            onChange={handleFileChange}
                            mt="4"
                        />
                    )}

                    <Select
                        placeholder="בחר מיקום לטופס"
                        marginTop="20px"
                        onChange={(e) => setFormLocation(e.target.value)}
                        sx={{
                            direction: 'rtl',
                            paddingRight: '2rem', // Adjust this value as needed to prevent overlap
                            textAlign: 'right',
                        }}
                    >
                        <option value="registration">רישום משתמש חדש</option>
                    </Select>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={createForm}>יצירת טופס</Button>
                    <Button onClick={onClose}>סגור</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
