import {
    Box,
    Button, Input,
    Modal,
    ModalBody,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, SimpleGrid, Text,
    useToast
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import AdminSumitDocumentsFinderModal from "./AdminSumitDocumentsFinderModal";
import ReportsApi from "../../../../api/reports";

export default function AdminPaymentUpdateModal(props) {

    let { isOpen, onClose, report } = props;
    const toast = useToast()

    const [sumitDocumentModalOpen, setSumitDocumentModalOpen] = React.useState(false);
    const [selectedDocument, setSelectedDocument] = React.useState('');
    const [activityReferenceValue, setActivityReferenceValue] = useState("");

    useEffect(() => {
        if(isOpen) {
            setSelectedDocument('');
            setActivityReferenceValue('');
        }
    }, [isOpen]);

    const openSumitDocumentModal = () => {
        setSumitDocumentModalOpen(true);
    }

    const handleSumitDocumentModalClose = (selectedDocumentId) => {
        setSumitDocumentModalOpen(false);
        setSelectedDocument(selectedDocumentId);
        // ReportsApi.addDocumentToReport(reportId, selectedDocumentId, selectedDocumentType).then(res => {
        //     toast({
        //         title: "המסמך נוסף בהצלחה",
        //         position: 'top',
        //         status: 'success',
        //         isClosable: false,
        //     });
        //     setSumitDocumentModalOpen(false)
        // }).catch(e => {
        //     console.log(e)
        // });
    }

    const approveReport = () => {
        if(selectedDocument.length == 0) {
            toast({
                title: "יש לבחור קובץ מסב",
                position: 'top',
                status: 'error',
                isClosable: false,
            });
            return;
        }
        if(activityReferenceValue.length == 0) {
            toast({
                title: "יש להזין מספר תנועה בבנק",
                position: 'top',
                status: 'error',
                isClosable: false,
            });
            return;
        }
        ReportsApi.approveReport(null, selectedDocument, activityReferenceValue).then(res => {
            toast({
                title: "הדוח אושר בהצלחה",
                position: 'top',
                status: 'success',
                isClosable: false,
            });
            onClose();
        }).catch(e => {
            //console.log(e)
        });
    }

    return (
        <Modal isOpen={isOpen}
               onClose={onClose}
               isCentered
               closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent maxW="500px">
                <ModalHeader>עדכון קובץ מס״ב</ModalHeader>
                <ModalBody>

                    <AdminSumitDocumentsFinderModal onClose={() => setSumitDocumentModalOpen(false)} isOpen={sumitDocumentModalOpen} onSelect={(selectedDocumentId, selectedDocumentType) =>
                        handleSumitDocumentModalClose(selectedDocumentId)
                    }/>

                    <SimpleGrid
                        marginTop='00px'
                        columns={{sm: 2}}
                        gap='10px'
                        mb='0px'>

                        <Button backgroundColor='green.200' onClick={openSumitDocumentModal} >בחר קובץ מסב</Button>

                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Text>מזהה קובץ:</Text>

                            <Text mr={'5px'}>{selectedDocument}</Text>

                        </Box>


                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Text>מספר תנועה בבנק:</Text>
                        </Box>


                        <Input placeholder='מספר תנועה' onChange={(event) => {
                            setActivityReferenceValue(event.target.value);
                        }} />


                    </SimpleGrid>

                </ModalBody>
                <ModalFooter>
                    <Button variant={'brand'} onClick={approveReport}>עדכון</Button>
                    <Button onClick={onClose}>סגור</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
