import React, { useState } from 'react';
import { Textarea, VStack, Select } from "@chakra-ui/react";

const PLACEHOLDERS = [
    "firstName",
    "lastName",
    "fullName",
    "companyName",
    "phoneNumber",
    "dateOfBirth",
    "jobTitle",
    "monthlyEarnings",
    "lastShiftEarnings",
    "monthlyWorkingHours",
    "availableToTransfer"
];

const PlaceholderInput = ({ onMessageBodyChange }) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        const { value } = event.target;
        setInputValue(value);
        onMessageBodyChange(value);
    }

    const handlePlaceholderSelect = (event) => {
        const placeholder = event.target.value;
        if (placeholder) {
            setInputValue(`${inputValue} {${placeholder}} `);
            onMessageBodyChange(`${inputValue} {${placeholder}} `);
        }
    }

    return (
        <div>
            <Textarea value={inputValue} onChange={handleInputChange} />
            <br/>
            <br/>
            <VStack spacing={2} alignItems="stretch">
                <Select pr='12px' placeholder="Select placeholder" onChange={handlePlaceholderSelect}>
                    {PLACEHOLDERS.map((placeholder) => (
                        <option key={placeholder} value={placeholder}>
                            {placeholder}
                        </option>
                    ))}
                </Select>
            </VStack>
        </div>
    );
}

export default PlaceholderInput;

