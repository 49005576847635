import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    useToast,
    Select,
    Text,
    FormControl,
    FormLabel,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import FormsApi from "../../../api/forms";
import {exportFormReportPdfZipFile, exportFormsReportFile} from "../../../functions/generateReports";

export default function EditFormModal(props) {
    const { isOpen, onClose, onFormUpdate, form } = props;
    const [formStatus, setFormStatus] = useState('');

    useEffect(() => {
        if (isOpen) {
            setFormStatus(form.status || '');
        }
    }, [isOpen, form?.status]);

    const toast = useToast();

    const updateForm = () => {
        let formData = {
            id: form?.id,
            status: formStatus,
        };

        FormsApi.update(formData).then(() => {
            toast({
                title: 'טופס עודכן בהצלחה',
                position: 'top',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            onFormUpdate?.();
            onClose();
        }).catch(error => {
            toast({
                title: 'שגיאה בעדכון טופס',
                position: 'top',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        });
    };

    const handleDownloadFormReport = () => {
        FormsApi.downloadFormReport(form?.id).then((response) => {
            // write response array to xlsx file
            if(response) {
                const formContentType = response.data['formContentType'];
                const items = response.data.items;
                if(items.length === 0) {
                    toast({
                        title: 'לא נמצאו פריטים להורדה',
                        position: 'top',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    return;
                }
                if(formContentType === 'text') {
                    exportFormsReportFile(items);
                } else if(formContentType === 'pdf') {
                    // create a zip file contain a multiple pdf files
                    // iterate over the items and create a pdf file for each item,
                    // the pdf content is base64 string found on items.signedContent
                    // and the file name is items.userFullName + items.formId + '.pdf'
                    exportFormReportPdfZipFile(items);
                }
            }
        }).catch(error => {
           console.error(error);
        });
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="slideInBottom">
            <ModalOverlay />
            <ModalContent borderRadius="lg">
                <ModalHeader>פרטי טופס</ModalHeader>
                <ModalBody>
                    <FormControl mb="4">
                        <FormLabel>שם הטופס:</FormLabel>
                        <Text fontSize="lg">{form?.title}</Text>
                    </FormControl>

                    <FormControl mb="4">
                        <FormLabel>עדכון סטטוס:</FormLabel>
                        <Select
                            placeholder="סטטוס"
                            value={formStatus}
                            onChange={(e) => setFormStatus(e.target.value)}
                            size="lg"
                            sx={{
                                direction: 'rtl',
                                paddingRight: '2rem',
                                textAlign: 'right',
                            }}
                        >
                            <option value="1">פעיל</option>
                            <option value="0">לא פעיל</option>
                        </Select>
                    </FormControl>

                    <Button backgroundColor='green.200' width="full" mt="4" onClick={handleDownloadFormReport}>הורד דו״ח צפייה</Button>

                </ModalBody>
                <ModalFooter>
                    <Button backgroundColor='green.100' mr="3" onClick={updateForm}>שמירה</Button>
                    <Button variant="ghost" onClick={onClose}>סגור</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
